import * as crypto from 'crypto';
import { encryptMessage } from 'services/util';
import http, { API_URL } from '../services/http';

export const postCardQuery = async (args: { userId: string; values: any; token: string }) => {
  let { userId, values, token } = args;
  const iv = crypto.randomBytes(16).toString('hex').slice(0, 16);

  values = { ...values, number: encryptMessage(values.number, iv) };
  values = { ...values, cvc: encryptMessage(String(values.cvc), iv) };
  values = { ...values, randomBytes: iv };

  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  // console.log(values);

  return http({
    baseURL: API_URL,
    method: 'POST',
    url: `/cards`,
    data: values,
  });
};

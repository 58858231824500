import { useAuth0 } from "@auth0/auth0-react";
import { fetchUser } from "actions/users";
import { createContext, useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 * Hook to fetch user data and store them to redux store
 */
const useFetchUser = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const fetchUserDispatch = useCallback(async () => {
    user?.sub && getAccessTokenSilently().then((token) => fetchUser(dispatch)(user?.sub, token));
  }, [dispatch, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    fetchUserDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fetchUserDispatch;
};

const FetchUserCtx = createContext(() => {});

export const FetchUserProvider = ({ children }: any) => {
  const refetch = useFetchUser();

  return <FetchUserCtx.Provider value={refetch}>{children}</FetchUserCtx.Provider>;
};
export const useFetchUserCtx = () => useContext(FetchUserCtx);

export default useFetchUser;

import { useAuth0 } from "@auth0/auth0-react";
import Footer from "components/Footer";
import AppNavbar from "components/Navbar";
import Navmenu from "components/NavMenu/Navmenu";
import "../PrivacyPolicy/policy.scss";

import Sidebar from "components/Sidebar/Sidebar";
import Sidebarhome from "components/SidebarHome/Sidebarhome";
import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Terms: React.FC = () => {
  let [scrollRefs, setScrollRefs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollRefs((refs) =>
      Array(22)
        .fill(null)
        .map((_, i) => refs[i] || createRef()),
    );
  }, []);
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = (num: number) => scrollToRef(scrollRefs[num]);
  const { user, isAuthenticated } = useAuth0();
  return (
    <>
      <div className="policy-page">
        {sidebarhome && <Sidebarhome />}
        {!isAuthenticated ? <Navmenu /> : <AppNavbar />}
        { !isAuthenticated && sidebar && <Sidebar />}
        <div className="policy">
          <h3>PRIA TERMS OF SERVICE</h3>
          <div className="md-text-bold">Last Updated: January 4, 2022</div>
          &nbsp; &nbsp;
          <p style={{ textAlign: "justify" }}>
            The following terms of service are terms of a legal agreement (the “Terms of Use”)
            between you (“you”, “your”, or “user”) and Pria, Inc., doing business as Pria, its
            subsidiaries, affiliates, agents and assigns (“Pria”, “we”, “us”, or “our”) that sets
            forth the terms and conditions for your use of all of Pria’s websites and all mobile
            applications (collectively, the “Site”) and/or the products and services offered,
            operated or made available by Pria (collectively, the “Services”). This Site and
            Services are being provided to you expressly subject to this Agreement. By accessing,
            browsing and/or using the Site or the Services, you acknowledge that you have read,
            understood, and agree to be bound by the terms of this Agreement and to comply with all
            applicable laws and regulations. The terms and conditions of this Agreement governs your
            use of the Site and the Services.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>
              THIS AGREEMENT ALSO INCLUDES A BINDING ARBITRATION PROVISION THAT CONTAINS A CLASS
              ACTION WAIVER. PLEASE REFER TO SECTION 14 BELOW FOR MORE INFORMATION.
            </strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            Supplemental terms and conditions or documents that may be posted on the Site from time
            to time are hereby expressly incorporated herein by reference. We reserve the right, in
            our sole discretion, to make changes or modifications to these Terms of Use at any time
            and for any reason. We will alert you about any changes by updating the “Last updated”
            date of these Terms of Use, and you waive any right to receive specific notice of each
            such change. It is your responsibility to periodically review these Terms of Use to stay
            informed of updates. You will be subject to, and will be deemed to have been made aware
            of and to have accepted, the changes in any revised Terms of Use by your continued use
            of the Site after the date such revised Terms of Use are posted.
          </p>
          <h5>TABLE OF CONTENTS</h5>
          <ol>
            <li onClick={() => executeScroll(0)}>DISTRIBUTION LIMITED</li>
            <li onClick={() => executeScroll(1)}>AGE RESTRICTION</li>
            <li onClick={() => executeScroll(2)}>
              INTELLECTUAL PROPERTY RIGHTS AND LIMITED LICENSE
            </li>
            <li onClick={() => executeScroll(3)}>USER REPRESENTATIONS</li>
            <li onClick={() => executeScroll(4)}>USER REGISTRATION</li>
            <li onClick={() => executeScroll(5)}>PROHIBITED ACTIVITIES</li>
            <li onClick={() => executeScroll(6)}>SUBMISSIONS</li>
            <li onClick={() => executeScroll(7)}>THIRD-PARTY WEBSITED AND CONTENT</li>
            <li onClick={() => executeScroll(8)}>SITE MANAGEMENT</li>
            <li onClick={() => executeScroll(9)}>PRIVACY POLICY</li>
            <li onClick={() => executeScroll(10)}>TERMS AND TERMINATION</li>
            <li onClick={() => executeScroll(11)}>MODIFICATIONS AND INTERRUPTIONS</li>
            <li onClick={() => executeScroll(12)}>GOVERNING LAW</li>
            <li onClick={() => executeScroll(13)}>MANDATORY ARBITRATION AND DISPUTE RESOLUTION</li>
            <li onClick={() => executeScroll(14)}>CORRECTIONS</li>
            <li onClick={() => executeScroll(15)}>DISCLAIMER</li>
            <li onClick={() => executeScroll(16)}>LIMITATIONS AND LIABILITY</li>
            <li onClick={() => executeScroll(17)}>INDEMNIFICATION</li>
            <li onClick={() => executeScroll(18)}>
              ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </li>
            <li onClick={() => executeScroll(19)}>CALIFORNIAL USERS AND RESIDENTS NOTICE</li>
            <li onClick={() => executeScroll(20)}>MISCELLANEOUS</li>
            <li onClick={() => executeScroll(21)}>CONTACT US</li>
          </ol>
          <ul>
            <li ref={scrollRefs[0]}>
              <strong>Distribution Limited</strong>{" "}
              <p style={{ textAlign: "justify" }}>
                The information provided on the Site is not intended for distribution to or use by
                any person or entity in any jurisdiction where such distribution or use would be
                contrary to law or regulation or which would subject us to any registration
                requirement within such jurisdiction. Accordingly, those persons who choose to
                access the Site from other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent local laws are
                applicable. The Services are not available outside of the United States.
              </p>{" "}
            </li>
            <li ref={scrollRefs[1]}>
              {" "}
              <strong>Age Restriction</strong>{" "}
              <p style={{ textAlign: "justify" }}>
                The Site and the Services are intended for users who are at least 18 years of age.
              </p>{" "}
            </li>
            <li ref={scrollRefs[2]}>
              <strong>Intellectual Property Rights and Limited License</strong>
              <p style={{ textAlign: "justify" }}>
                Unless otherwise indicated, the Site is our proprietary property and all source
                code, databases, functionality, software, website designs, audio, video, text,
                photographs, and graphics on the Site (collectively, the “Content”) and the
                trademarks, service marks, and logos contained therein (the “Marks”) are owned or
                controlled by us or licensed to us, and are protected by copyright and trademark
                laws and various other intellectual property rights and unfair competition laws of
                the United States, foreign jurisdictions, and international conventions unless
                otherwise noted. The Content and the Marks are provided on the Site “AS IS” for your
                information and personal use only. Except as expressly provided in these Terms of
                Use, no part of the Site and no Content or Marks may be copied, reproduced,
                aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                purpose whatsoever, without our express prior written permission. Provided that you
                are eligible to use the Site, you are granted a limited license to access and use
                the Site and to download or print a copy of any portion of the Content to which you
                have properly gained access solely for your personal, non-commercial use. We reserve
                all rights not expressly granted to you in and to the Site, the Content and the
                Marks.
              </p>
            </li>
            <li ref={scrollRefs[3]}>
              <strong>User Respresentations</strong>
              <p style={{ textAlign: "justify" }}>
                By using the Site, you represent and warrant that: (1) all registration information
                you submit will be true, accurate, current, and complete; (2) you will maintain the
                accuracy of such information and promptly update such registration information as
                necessary; (3) you have the legal capacity and you agree to comply with these Terms
                of Use; (4) you are not under the age of 18; (5) you are not a minor in the
                jurisdiction in which you reside, or if a minor, you have received parental
                permission to use the Site; (6) you will not access the Site through automated or
                non-human means, whether through a bot, script or otherwise; (7) you will not use
                the Site for any illegal or unauthorized purpose; and (8) your use of the Site will
                not violate any applicable law or regulation.
              </p>

              <p style={{ textAlign: "justify" }}>
                If you provide any information that is untrue, inaccurate, not current, or
                incomplete, we have the right to suspend or terminate your account and refuse any
                and all current or future use of the Site (or any portion thereof). You may not use
                the Site for any illegal or unauthorized purpose nor may you violate any laws.
              </p>

              <p style={{ textAlign: "justify" }}>
                We are a service provider and make no representations as to the safety,
                effectiveness, adequacy, accuracy, availability, prices, ratings, reviews, or
                legality of any of the information contained on the Site. You understand and agree
                that the content of the Site does not contain or constitute representations to be
                reasonably relied upon, and you agree to hold us harmless from any errors,
                omissions, or misrepresentations contained within the Site’s content. We do not
                endorse or recommend any third party products or services and the Site is provided
                for informational and advertising purposes only
              </p>
            </li>
            <li ref={scrollRefs[4]}>
              <strong>User Registreation</strong>
              <p style={{ textAlign: "justify" }}>
                You are required to register with the Site and establish an account (&quot;Pria
                Account&ldquo;) with us in order to access the Services. You agree to keep your Pria
                Account password confidential and will be responsible for all use of your Pria
                Account information and password. We will never ask you for your password; do not
                share your password with anyone. We reserve the right to remove, reclaim, or change
                a username you select if we determine, in our sole discretion, that such username is
                inappropriate, obscene, or otherwise objectionable.
              </p>
            </li>
            <li ref={scrollRefs[5]}>
              <strong>Prohibited Activities</strong>
              <p style={{ textAlign: "justify" }}>
                You may not access or use the Site for any purpose other than those for which we
                make the Site available. The Site may not be used in connection with any commercial
                endeavors except those that are specifically endorsed or approved by us in writing.
                As a user of the Site, you agree not to:
              </p>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  a. Systematically retrieve data or other content from the Site to create or
                  compile, directly or indirectly, a collection, compilation, database, or directory
                  without written permission from us.
                </li>
                <li style={{ textAlign: "justify" }}>
                  b. Make any unauthorized use of the Site, including collecting usernames and/or
                  email addresses of users by electronic or other means for the purpose of sending
                  unsolicited email, or creating user accounts by automated means or under false
                  pretenses.
                </li>
                <li style={{ textAlign: "justify" }}>
                  c. Use the Site to advertise or offer to sell goods and services.
                </li>
                <li style={{ textAlign: "justify" }}>
                  d. Circumvent, disable, or otherwise interfere with security-related features of
                  the Site, including features that prevent or restrict the use or copying of any
                  Content or enforce limitations on the use of the Site and/or the Content contained
                  therein.
                </li>
                <li style={{ textAlign: "justify" }}>
                  e. Engage in unauthorized framing of or linking to the Site.
                </li>
                <li style={{ textAlign: "justify" }}>
                  f. Trick, defraud, or mislead us and other users, especially in any attempt to
                  learn sensitive account information such as user passwords
                </li>
                <li style={{ textAlign: "justify" }}>
                  g. Make improper use of our support services or submit false reports of abuse or
                  misconduct.
                </li>
                <li style={{ textAlign: "justify" }}>
                  h. Engage in any automated use of the system, such as using scripts to send
                  comments or messages, or using any data mining, robots, or similar data gathering
                  and extraction tools.
                </li>
                <li style={{ textAlign: "justify" }}>
                  i. Interfere with, disrupt, or create an undue burden on the Site or the networks
                  or services connected to the Site.
                </li>
                <li style={{ textAlign: "justify" }}>
                  j. Attempt to impersonate another user or person or use the username of another
                  user.
                </li>
                <li style={{ textAlign: "justify" }}>
                  k. Sell or otherwise transfer your profile, Pria Account, or password.
                </li>
                <li style={{ textAlign: "justify" }}>
                  l. Use any information obtained from the Site in order to harass, abuse, or harm
                  another person.
                </li>
                <li style={{ textAlign: "justify" }}>
                  m. Use any aspect of the Site or Service as part of any effort to compete with us
                  or otherwise use the Site and/or the Content for any revenue-generating endeavor
                  or commercial enterprise
                </li>
                <li style={{ textAlign: "justify" }}>
                  n. Decipher, decompile, disassemble, or reverse engineer any of the software
                  comprising or in any way making up a part of the Site.
                </li>
                <li style={{ textAlign: "justify" }}>
                  o. Attempt to bypass any measures of the Site designed to prevent or restrict
                  access to the Site, or any portion of the Site.
                </li>
                <li style={{ textAlign: "justify" }}>
                  p. Harass, annoy, intimidate, or threaten any of our employees or agents.
                </li>
                <li style={{ textAlign: "justify" }}>
                  q. Copy or adapt the Site’s software, including but not limited to Flash, PHP,
                  HTML, JavaScript, or other code.
                </li>
                <li style={{ textAlign: "justify" }}>
                  r. Delete the copyright or other proprietary rights notice from any Content/
                </li>
                <li style={{ textAlign: "justify" }}>
                  s. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan
                  horses, or other material, including excessive use of capital letters and spamming
                  (continuous posting of repetitive text), that interferes with any party’s
                  uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts,
                  alters, or interferes with the use, features, functions, operation, or maintenance
                  of the Site.
                </li>
                <li style={{ textAlign: "justify" }}>
                  t. Upload or transmit (or attempt to upload or to transmit) any material that acts
                  as a passive or active information collection or transmission mechanism, including
                  without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web
                  bugs, cookies, or other similar devices (sometimes referred to as “spyware” or
                  “passive collection mechanisms” or “pcms”).
                </li>
                <li style={{ textAlign: "justify" }}>
                  u. Except as may be the result of standard search engine or Internet browser
                  usage, use, launch, develop, or distribute any automated system, including without
                  limitation, any spider, robot, cheat utility, scraper, or offline reader that
                  accesses the Site, or Use the Site in a manner inconsistent with any applicable
                  laws or regulations, or using or launching any unauthorized script or other
                  software.
                </li>
                <li style={{ textAlign: "justify" }}>
                  v. Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.
                </li>
              </ul>
            </li>
            <li ref={scrollRefs[6]}>
              <strong>Submissions</strong>
              <p style={{ textAlign: "justify" }}>
                You acknowledge and agree that any questions, comments, suggestions, ideas,
                feedback, or other information regarding the Site (&ldquo;Submissions&rdquo;)
                provided by you to us are non-confidential and shall become our sole property. We
                shall own exclusive rights, including all intellectual property rights, and shall be
                entitled to the unrestricted use and dissemination of these Submissions for any
                lawful purpose, commercial or otherwise, without acknowledgment or compensation to
                you. You hereby waive all moral rights to any such Submissions, and you hereby
                warrant that any such Submissions are original with you or that you have the right
                to submit such Submissions. You agree there shall be no recourse against us for any
                alleged or actual infringement or misappropriation of any proprietary right in your
                Submissions.
              </p>
            </li>
            <li ref={scrollRefs[7]}>
              <strong>Third-Party Websites and Content</strong>
              <p style={{ textAlign: "justify" }}>
                The Site may contain (or you may be sent via the Site) links to other websites
                (&ldquo;Third-Party Websites&rdquo;) as well as articles, photographs, text,
                graphics, pictures, designs, music, sound, video, information, applications,
                software, and other content or items belonging to or originating from third parties
                (&ldquo;Third-Party Content&rdquo;). Such Third-Party Websites and Third-Party
                Content are not investigated, monitored, or checked for accuracy, appropriateness,
                or completeness by us, and we are not responsible for any Third Party Websites
                accessed through the Site or any Third-Party Content posted on, available through,
                or installed from the Site, including the content, accuracy, offensiveness,
                opinions, reliability, privacy practices, or other policies of or contained in the
                Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or
                permitting the use or installation of any Third-Party Websites or any Third-Party
                Content does not imply approval or endorsement thereof by us. If you decide to leave
                the Site and access the Third-Party Websites or to use or install any Third-Party
                Content, you do so at your own risk, and you should be aware these Terms of Use no
                longer govern. You should review the applicable terms and policies, including
                privacy and data gathering practices, of any website to which you navigate from the
                Site or relating to any applications you use or install from the Site. Any purchases
                you make through Third-Party Websites will be through other websites and from other
                companies, and we take no responsibility whatsoever in relation to such purchases
                which are exclusively between you and the applicable third party. You agree and
                acknowledge that we do not endorse the products or services offered on Third-Party
                Websites and you shall hold us harmless from any harm caused by your purchase of
                such products or services. Additionally, you shall hold us harmless from any losses
                sustained by you or harm caused to you relating to or resulting in any way from any
                Third-Party Content or any contact with Third-Party Websites.
              </p>
            </li>
            <li ref={scrollRefs[8]}>
              <strong>Site Management</strong>
              <p style={{ textAlign: "justify" }}>
                We reserve the right, but not the obligation, to: (1) monitor the Site for
                violations of these Terms of Use; (2) take appropriate legal action against anyone
                who, in our sole discretion, violates the law or these Terms of Use, including
                without limitation, reporting such user to law enforcement authorities; (3) in our
                sole discretion and without limitation, refuse, restrict access to, limit the
                availability of, or disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion and without
                limitation, notice, or liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way burdensome to our
                systems; and (5) otherwise manage the Site in a manner designed to protect our
                rights and property and to facilitate the proper functioning of the Site.
              </p>
            </li>
            <li ref={scrollRefs[9]}>
              <strong>Privacy Policy</strong>
              <p style={{ textAlign: "justify" }}>
                We care about data privacy and security. By using the Site, you agree to be bound by
                our Privacy Policy available here, which is incorporated into these Terms of Use.
                Please be advised the Site is hosted in the United States. If you access the Site
                from the European Union, Asia, or any other region of the world with laws or other
                requirements governing personal data collection, use, or disclosure that differ from
                applicable laws in the United States, then through your continued use of the Site,
                you are transferring your data to the United States, and you expressly consent to
                have your data transferred to and processed in the United States. Further, we do not
                knowingly accept, request, or solicit information from children or knowingly market
                to children. Therefore, in accordance with the U.S. Children’s Online Privacy
                Protection Act, if we receive actual knowledge that anyone under the age of 18 has
                provided personal information to us without the requisite and verifiable parental
                consent, we will delete that information from the Site as quickly as is reasonably
                practical.
              </p>
            </li>
            <li ref={scrollRefs[10]}>
              <strong>Terms and Termination</strong>
              <p style={{ textAlign: "justify" }}>
                These Terms of Use shall remain in full force and effect while you use the Site.
                WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO,
                IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF
                THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR
                FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR
                ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
                IN OUR SOLE DISCRETION.
              </p>
              <p style={{ textAlign: "justify" }}>
                If we terminate or suspend your account for any reason, you are prohibited from
                registering and creating a new account under your name, a fake or borrowed name, or
                the name of any third party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we reserve the right to take
                appropriate legal action, including without limitation pursuing civil, criminal, and
                injunctive redress.
              </p>
            </li>
            <li ref={scrollRefs[11]}>
              <strong>Modifications and Interruptions</strong>{" "}
              <p style={{ textAlign: "justify" }}>
                We reserve the right to change, modify, or remove the contents of the Site at any
                time or for any reason at our sole discretion without notice. However, we have no
                obligation to update any information on our Site. We will not be liable to you or
                any third party for any modification, price change, suspension, or discontinuance of
                the Site.
              </p>
              <p style={{ textAlign: "justify" }}>
                We cannot guarantee the Site will be available at all times. We may experience
                hardware, software, or other problems or need to perform maintenance related to the
                Site, resulting in interruptions, delays, or errors. We reserve the right to change,
                revise, update, suspend, discontinue, or otherwise modify the Site at any time or
                for any reason without notice to you. You agree that we have no liability whatsoever
                for any loss, damage, or inconvenience caused by your inability to access or use the
                Site during any downtime or discontinuance of the Site. Nothing in these Terms of
                Use will be construed to obligate us to maintain and support the Site or to supply
                any corrections, updates, or releases in connection therewith.
              </p>
            </li>
            <li ref={scrollRefs[12]}>
              <strong>Governing Law</strong>
              <p style={{ textAlign: "justify" }}>
                These Terms of Use and your use of the Site is governed by and construed in
                accordance with the laws of the State of California applicable to agreements made
                and to be entirely performed within the State of California, without regard to its
                conflict of law principles.
              </p>
            </li>
            <li ref={scrollRefs[13]}>
              <strong>Mandatory Arbitration &amp; Dispute Resolution</strong>
              <p style={{ textAlign: "justify" }}>
                If you have a dispute or other concern, please contact us immediately. We will do
                our best to resolve it. If the Parties are unable to resolve a Dispute through
                informal negotiations, the Dispute (except those Disputes expressly excluded below)
                will be finally and exclusively resolved by binding arbitration on an individual
                basis. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE
                IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted
                under the Commercial Arbitration Rules of the American Arbitration Association
                (&ldquo;AAA&rdquo;) and, where appropriate, the AAA&apos;s Supplementary Procedures
                for Consumer Related Disputes (&ldquo;AAA Consumer Rules&rdquo;), both of which are
                available at the AAA website www.adr.org. Your arbitration fees and your share of
                arbitrator compensation shall be governed by the AAA Consumer Rules and, where
                appropriate, limited by the AAA Consumer Rules. If such costs are determined by the
                arbitrator to be excessive, we will pay all arbitration fees and expenses. The
                arbitration may be conducted in person, through the submission of documents, by
                phone, or online. The arbitrator will make a decision in writing, but need not
                provide a statement of reasons unless requested by either Party. The arbitrator must
                follow applicable law, and any award may be challenged if the arbitrator fails to do
                so. Except where otherwise required by the applicable AAA rules or applicable law,
                the arbitration will take place in the federal judicial district of your residence
                or telephonically. Except as otherwise provided herein, the Parties may litigate in
                court to compel arbitration, stay proceedings pending arbitration, or to confirm,
                modify, vacate, or enter judgment on the award entered by the arbitrator.
              </p>
              <p style={{ textAlign: "justify" }}>
                NO ARBITRATION SHALL PROCEED ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS
                (INCLUDING AS PRIVATE ATTORNEY GENERAL ON BEHALF OF OTHERS), EVEN IF THE CLAIM OR
                CLAIMS THAT ARE THE SUBJECT OF THE ARBITRATION HAD PREVIOUSLY BEEN ASSERTED (OR
                COULD HAVE BEEN ASSERTED) IN A COURT AS CLASS REPRESENTATIVE, OR COLLECTIVE ACTIONS
                IN A COURT. Unless consented to in writing by all parties to the arbitration, no
                party to the arbitration may join, consolidate, or otherwise bring claims for or on
                behalf of two or more individuals or unrelated corporate entities in the same
                arbitration unless those persons are parties to a single transaction. Unless
                consented to in writing by all parties to the arbitration, an award in arbitration
                shall determine the rights and obligations of the named parties only, and only with
                respect to the claims in arbitration, and shall not (a) determine the rights,
                obligations, or interests of anyone other than a named party, or resolve any Claim
                of anyone other than a named party; nor (b) make an award for the benefit of, or
                against, anyone other than a named party.
              </p>
              <p style={{ textAlign: "justify" }}>
                If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
                shall be commenced or prosecuted in the state and federal courts located in Los
                Angeles County, California, and the Parties hereby consent to, and waive all
                defenses of lack of personal jurisdiction, and forum non conveniens with respect to
                venue and jurisdiction in such state and federal courts. Application of the United
                Nations Convention on Contracts for the International Sale of Goods and the Uniform
                Computer Information Transaction Act (UCITA) are excluded from these Terms of Use.
              </p>
              <p style={{ textAlign: "justify" }}>
                In no event shall any Dispute brought by either Party related in any way to the Site
                be commenced more than one (1) years after the cause of action arose. If this
                provision is found to be illegal or unenforceable, then neither Party will elect to
                arbitrate any Dispute falling within that portion of this provision found to be
                illegal or unenforceable and such Dispute shall be decided by a court of competent
                jurisdiction within the courts listed for jurisdiction above, and the Parties agree
                to submit to the personal jurisdiction of that court.
              </p>
              <p style={{ textAlign: "justify" }}>
                Restrictions. The Parties agree that any arbitration shall be limited to the Dispute
                between the Parties individually. To the full extent permitted by law, (a) no
                arbitration shall be joined with any other proceeding; (b) there is no right or
                authority for any Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and (c) there is no right or authority for any Dispute to
                be brought in a purported representative capacity on behalf of the general public or
                any other persons.
              </p>

              <p style={{ textAlign: "justify" }}>
                Exceptiosn to Arbitration. The Parties agree that the following Disputes are not
                subject to the above provisions concerning binding arbitration: (a) any individual
                Disputes seeking to enforce or protect, or concerning the validity of, any of the
                intellectual property rights of a Party; (b) any individual Dispute related to, or
                arising from, allegations of theft, piracy, invasion of privacy, or unauthorized
                use; and (c) any individual claim for injunctive relief. If this provision is found
                to be illegal or unenforceable, then neither Party will elect to arbitrate any
                Dispute falling within that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of competent jurisdiction
                within the courts listed for jurisdiction above, and the Parties agree to submit to
                the personal jurisdiction of that court.{" "}
              </p>
            </li>
            <li ref={scrollRefs[14]}>
              <strong>Corrections</strong>
              <p style={{ textAlign: "justify" }}>
                There may be information on the Site that contains typographical errors,
                inaccuracies, or omissions, including descriptions, pricing, availability, and
                various other information. We reserve the right to correct any errors, inaccuracies,
                or omissions and to change or update the information on the Site at any time,
                without prior notice.
              </p>
            </li>
            <li ref={scrollRefs[15]}>
              <strong>Disclaimer</strong>
              <p style={{ textAlign: "justify" }}>
                THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF
                THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
                BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
                OFFERINGS AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                COMPLETENESS OF THE SITE&apos;S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE
                SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES,
                OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
                ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
                UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
                CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
                AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
                DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
                APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY
                TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT
                AND EXERCISE CAUTION WHERE APPROPRIATE.
              </p>
            </li>
            <li ref={scrollRefs[16]}>
              <strong>Limitations and Liability</strong>
              <p style={{ textAlign: "justify" }}>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
                THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES.
              </p>
            </li>
            <li ref={scrollRefs[17]}>
              <strong>Indemnification</strong>
              <p style={{ textAlign: "justify" }}>
                You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                affiliates, and all of our respective officers, agents, partners, and employees,
                from and against any loss, damage, liability, claim, or demand, including reasonable
                attorneys&apos; fees and expenses, made by any third party due to or arising out of:
                (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your
                representations and warranties set forth in these Terms of Use; (4) your violation
                of the rights of a third party, including but not limited to intellectual property
                rights; or (5) any overt harmful act toward any other user of the Site with whom you
                connected via the Site. Notwithstanding the foregoing, we reserve the right, at your
                expense, to assume the exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your expense, with our
                defense of such claims. We will use reasonable efforts to notify you of any such
                claim, action, or proceeding which is subject to this indemnification upon becoming
                aware of it.
              </p>
            </li>
            <li ref={scrollRefs[18]}>
              <strong>Electronic Communications, Transactions, and Signatures</strong>
              <p style={{ textAlign: "justify" }}>
                Visiting the Site, sending us emails, and completing online forms constitute
                electronic communications. You consent to receive electronic communications, and you
                agree that all agreements, notices, disclosures, and other communications we provide
                to you electronically, via email and on the Site, satisfy any legal requirement that
                such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
                THE SITE. You hereby waive any rights or requirements under any statutes,
                regulations, rules, ordinances, or other laws in any jurisdiction which require an
                original signature or delivery or retention of non-electronic records, or to
                payments or the granting of credits by any means other than electronic means.
              </p>
            </li>
            <li ref={scrollRefs[19]}>
              <strong>California Users and Residents Notice</strong>
              <p style={{ textAlign: "justify" }}>
                If any complaint with us is not satisfactorily resolved, you can contact the
                Complaint Assistance Unit of the Division of Consumer Services of the California
                Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112,
                Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
              </p>
            </li>
            <li ref={scrollRefs[20]}>
              <strong>Miscellaneous</strong>
              <p style={{ textAlign: "justify" }}>
                These Terms of Use and any policies or operating rules posted by us on the Site
                constitute the entire agreement and understanding between you and us. Our failure to
                exercise or enforce any right or provision of these Terms of Use shall not operate
                as a waiver of such right or provision. These Terms of Use operate to the fullest
                extent permissible by law. We may assign any or all of our rights and obligations to
                others at any time. We shall not be responsible or liable for any loss, damage,
                delay, or failure to act caused by any cause beyond our reasonable control. If any
                provision or part of a provision of these Terms of Use is determined to be unlawful,
                void, or unenforceable, that provision or part of the provision is deemed severable
                from these Terms of Use and does not affect the validity and enforceability of any
                remaining provisions. There is no joint venture, partnership, employment or agency
                relationship created between you and us as a result of these Terms of Use. You agree
                that these Terms of Use will not be construed against us by virtue of having drafted
                them. You hereby waive any and all defenses you may have based on the electronic
                form of these Terms of Use and the lack of signing by the parties hereto to execute
                these Terms of Use.
              </p>
            </li>
            <li ref={scrollRefs[21]}>
              <strong>Contact Us</strong>
              <p style={{ textAlign: "justify" }}>
                In order to resolve a complaint regarding the Site or to receive further information
                regarding use of the Site, please contact us at:
              </p>

              <p style={{ textAlign: "justify" }}>
                <strong>
                  Pria, Inc. d/b/a Pria 5957 Barton Ave., Suite 106 Los Angeles, CA 90038 Phone:
                  713-208-8431 hey@Pria.app
                </strong>
              </p>
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;

import { useAuth0 } from "@auth0/auth0-react";
import AppButton from "common/AppButton";
import { SecurityIcon, UserIcon } from "common/AppIcons";
import AppInput from "common/AppInput";
import AppSwitch from "common/AppSwitch";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";

import { getMFAInfoAPI, postDisableMFAAPI, postEnableMFAAPI } from "services/mfa";
import { changePasswordAPI } from "services/users";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import * as Yup from "yup";
import "./Account.scss";

interface SecurityProps {
  reference: any;
}
const SecurityInfo: React.FC<any> = ({ reference }: SecurityProps) => {
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [mfaLoading, setMFALoading] = useState(true);
  const [mfaLink, setMFALink] = useState<any>("");
  const [isMFAEnabled, setMFAEnabled] = useState(false);
  const [mfaInfo, setMfaInfo] = useState<any>("");
  const [mfaType, setMfaType] = useState<any>(null);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
      password_confirm: Yup.string().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
          return this.parent.password === value;
        },
      ),
    }),
    onSubmit: async (values: any) => {
      const token = await getAccessTokenSilently();
      setPasswordLoading(true);
      try {
        if (user && user?.sub) {
          changePasswordAPI(user.sub, values.password, token)
            .then((response: any) => {
              if (response && response.data && checkResponseStatus(response.data.httpCode)) {
                handleSuccess();
                setPasswordLoading(false);
                dispatch({ type: "LOG_OUT" });
                logout({ returnTo: window.location.origin });
              }
            })
            .catch((err: any) => {
              handleError(err.response.data.message);
              setPasswordLoading(false);
            });
        }
      } catch (error) {
        handleError();
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      try {
        if (user && user?.sub) {
          let response = await getMFAInfoAPI(user?.sub, token);
          if (response && response.data && checkResponseStatus(response.data.httpCode)) {
            let mfaInfo = response.data.data[0];

            if (mfaInfo?.status === "confirmed") {
              // if (mfaInfo?.status === "confirmed" || mfaInfo?.status === "pending") {
              // console.log(",fa",mfaInfo.type);
              setMFAEnabled(true);
              setMfaType(mfaInfo.type);
            }
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mfaLink.length > 0) {
      window.open(mfaLink, "_blank");
    }
  }, [mfaLink]);

  const enableMFAAuth = () => {
    setMFAEnabled(true);
    (async () => {
      const token = await getAccessTokenSilently();
      try {
        setMFALoading(true);
        if (user && user?.sub) {
          let response = await postEnableMFAAPI(user?.sub, token);
          if (response && response.data && checkResponseStatus(response.data.httpCode)) {
            setMFALink(response.data.data);
            handleSuccess("MFA Enabled");
            setMFAEnabled(true);
          } else {
            handleError("Could not enable MFA");
            setMFAEnabled(false);
          }
        }
      } catch (err: any) {
        handleError(err);
      } finally {
        setMFALoading(false);
      }
    })();
  };

  const disableMFAAuth = () => {
    setMFAEnabled(false);
    (async () => {
      const token = await getAccessTokenSilently();
      try {
        setMFALoading(true);
        if (user && user?.sub) {
          let response = await postDisableMFAAPI(user?.sub, token);
          if (response && response.data && checkResponseStatus(response.data.httpCode)) {
            setMFALink(response.data.data);
            handleSuccess("MFA Disabled");
          } else {
            handleError("Could not disable MFA");
            setMFAEnabled(true);
          }
        }
      } catch (err: any) {
        handleError(err);
      } finally {
        setMFALoading(false);
      }
    })();
  };

  return (
    <>
      <div ref={reference}></div>
      {!(user?.sub?.includes("facebook") || user?.sub?.includes("google")) && (
        <h5>
          <SecurityIcon />
          Security
        </h5>
      )}

      <FormikProvider value={formik}>
        <Form
          noValidate
          validated={
            formik.touched.password &&
            formik.touched.password_confirm &&
            !formik.errors.password &&
            !formik.errors.password_confirm
          }
          onSubmit={formik.handleSubmit}
        >
          {/* <Form.Group as={Row}>
          <Form.Label column sm="4" htmlFor="fullnme">
            Current Password
          </Form.Label>
          <Col sm="8">
            <AppInput placeholder="***********" name="current_password" type="password" />
          </Col>
        </Form.Group> */}
          {!(user?.sub?.includes("facebook") || user?.sub?.includes("google")) && (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  New Password
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.password ? true : false}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <div className="invalid-feedback-text">{formik.errors.password}</div>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} style={{ marginBottom: "24px" }}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  Confirm new password
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="password"
                    name="password_confirm"
                    value={formik.values.password_confirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.password_confirm ? true : false}
                  />
                  {formik.errors.password_confirm && formik.touched.password_confirm && (
                    <div className="invalid-feedback-text">{formik.errors.password_confirm}</div>
                  )}
                </Col>
              </Form.Group>

              <div className="action-box" style={{ marginBottom: 40 }}>
                <AppButton
                  type="submit"
                  className="primary"
                  isLoading={passwordLoading}
                  disabled={passwordLoading}
                >
                  Save Changes
                </AppButton>
                &nbsp; &nbsp;
                <AppButton
                  className="primary outlined"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    formik.resetForm();
                  }}
                >
                  Cancel
                </AppButton>
              </div>
            </>
          )}
          <h6>Two-factor Authentication</h6>
          <p>
            To keep your account secure, we recommend that you turn on two-factor authentication
            using either or all of the following methods:
          </p>
          <AppSwitch
            checked={isMFAEnabled && mfaType === "authenticator"}
            name="auth"
            id="customSwitch1"
            onChange={(e: any) => {
              e.stopPropagation();
              isMFAEnabled ? disableMFAAuth() : enableMFAAuth();
            }}
            label="Authenticator App"
            subLabel="[when off] Turn on to use authenticator app like Google Authenticator and Authy to generate verification code when you log in.[when on] Use the verification code generated by your authenticator app when logging in."
          />
          <AppSwitch
            checked={user?.email_verified && isMFAEnabled ? true : false}
            name="email"
            label="Email"
            id="customSwitch2"
            subLabel={`[when off] Turn on to recieve verification code on your email address when you log in.[when on] A verification code will sent to ${user?.email}  when you log in.`}
            onChange={(e: any) => {
              e.stopPropagation();
              isMFAEnabled ? disableMFAAuth() : enableMFAAuth();
            }}
            //  disabled
          />
          <AppSwitch
            checked={isMFAEnabled && mfaType === "sms"}
            name="sms"
            id="customSwitch3"
            label="SMS"
            subLabel="[when off] Turn on to recieve verification code on your mobile device when you log in. Carrier charges may apply.[when on] A verification code will be sent to your mobile device when you log in. Carrier charges may apply."
            onChange={(e: any) => {
              e.stopPropagation();
              isMFAEnabled ? disableMFAAuth() : enableMFAAuth();
            }}
          />
          {/* {isMFAEnabled ? (
            <AppButton className="primary" onClick={disableMFAAuth}>
              Disable Multi factor Authentication
            </AppButton>
          ) : (
            <AppButton className="primary" onClick={enableMFAAuth}>
              Enable Multi factor Authentication
            </AppButton>
          )} */}
        </Form>
      </FormikProvider>
    </>
  );
};

export default SecurityInfo;

import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchProducts,
  fetchSubscribedProduct,
  selectProductAction,
  selectProductResetAction,
} from "actions";
import checkIcon from "assets/img/check-icon.png";
import uncheckIcon from "assets/img/uncheck-icon.png";
import AppButton from "common/AppButton";
import * as routes from "constants/routes";
import useFetchUser from "hooks/useFetchUser";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { subscribeProductAPI } from "services/product";
import { RootState } from "store";
import { IProduct } from "types/IProduct";
import { handleError } from "utils/handleError";
import { handleSuccess } from "utils/handleSuccess";
import "./subscription.scss";

const SubscriptionPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.products);
  const selectedProduct: IProduct = useSelector(
    (state: RootState) => state.products.selectedProduct,
  );

  const subscribedProduct: any = useSelector(
    (state: RootState) => state.products.subscribedProduct,
  );

  useFetchUser();

  const subInfo = useSelector((state: RootState) => state.user.user?.subscription);
  const fundingCardId = subInfo?.card?.id;

  const { user, getAccessTokenSilently } = useAuth0();

  const history = useHistory();
  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        user && user.sub && dispatch(fetchProducts(user.sub, token));
        if (user && user?.sub) dispatch(fetchSubscribedProduct(user?.sub, token));
      })
      .catch((err) => handleError());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSubscription = async () => {
    try {
      if (!user?.sub) return;

      const res = await subscribeProductAPI(
        subInfo?.id,
        Number(selectedProduct.id),
        Number(fundingCardId),
        true,
      );

      if (res.status !== 200) throw new Error("Something went wrong");

      handleSuccess("Updated subscription");
      setTimeout(() => {
        history.goBack();
      }, 1500);

      // history.push(routes.CHECKOUT);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="subs-page">
      <ToastContainer />
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="d-flex flex-column align-items-center w-100">
          <h4>Select a subscription</h4>

          <div className="form">
            {products?.map((prod: IProduct, index: number) => (
              <Card
                key={index}
                className={selectedProduct?.id === prod.id ? "active" : ""}
                onClick={() => {
                  dispatch(selectProductAction(prod));
                }}
              >
                <div className="col-xs-2 align-items-center">
                  <img
                    src={selectedProduct?.id === prod.id ? checkIcon : uncheckIcon}
                    alt="check"
                  />
                </div>

                <div className="col-sm-10 label">
                  <h6 style={{ textTransform: "capitalize" }}>{prod.name}</h6>
                  <div className="lg-text-bold mt-2">{`$${prod?.price / prod?.intervalCount}/${
                    prod?.interval
                  }`}</div>
                  <div className="sm-text-reg mt-4 ">
                    {`$${prod?.price} billed every ${
                      prod?.intervalCount === 1 ? "" : prod?.intervalCount
                    } ${prod?.interval}`}
                  </div>
                </div>

                {/* <Form.Check
                  inline
                  name="group1"
                  type="radio"
                  id={prod?.id}
                  onChange={() => dispatch(selectProductAction(prod))}
                  checked={
                    (!isEmpty(subscribedProduct) && subscribedProduct?.id === prod?.id) ||
                    selectedProduct?.id === prod?.id
                  }
                  disabled={!isEmpty(subscribedProduct) && subscribedProduct?.status === 'active'}
                />
                <Form.Check.Label>
                  <Row>{prod.nickname}</Row>
                  <Row>
                    {`$${prod?.unitAmount / 100}/${
                      prod?.intervalCount === 1 ? '' : prod?.intervalCount
                    }${prod?.interval}`}{' '}
                  </Row>
                </Form.Check.Label> */}
              </Card>
            ))}
          </div>

          <AppButton className="primary" onClick={() => handleChangeSubscription()}>
            Continue to Payment
          </AppButton>
          <AppButton
            className="secondary borderless mt-4"
            style={{ textDecoration: "underline", backgroundColor: "inherit" }}
            onClick={() => {
              dispatch(selectProductResetAction());
              history.push(routes.ACCOUNT);
            }}
          >
            Go Back
          </AppButton>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SubscriptionPage;

import { fetchProductsAPI, fetchSubscribedProductAPI, subscribeProductAPI } from "services/product";
import { fetchUserAPI } from "services/users";
import { IAction } from "types";
import {
  FETCH_PRODUCT_ERROR,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_RESET,
  FETCH_PRODUCT_SUCCESS,
  FETCH_SUBSCRIBED_ERROR,
  FETCH_SUBSCRIBED_REQUEST,
  FETCH_SUBSCRIBED_RESET,
  FETCH_SUBSCRIBED_SUCCESS,
  IProduct,
  SELECT_PRODUCT,
  SELECT_PRODUCT_RESET,
  SUBSCRIBE_PRODUCT_ERROR,
  SUBSCRIBE_PRODUCT_REQUEST,
  SUBSCRIBE_PRODUCT_RESET,
  SUBSCRIBE_PRODUCT_SUCCESS,
} from "types/IProduct";
import { handleError } from "utils/handleError";

export interface IProductAction extends IAction {
  type: string;
  payload?: any;
}

export const fetchProductsRequestAction = (): IProductAction => ({
  type: FETCH_PRODUCT_REQUEST,
});

export const fetchProductSuccessAction = (products: Array<IProduct>): IProductAction => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: products,
});

export const fetchProductErrorAction = (error: string): IProductAction => ({
  type: FETCH_PRODUCT_ERROR,
  payload: error,
});

export const fetchProductResetAction = (): IProductAction => ({
  type: FETCH_PRODUCT_RESET,
});

export const subscribeProductsRequestAction = (): IProductAction => ({
  type: SUBSCRIBE_PRODUCT_REQUEST,
});

export const subscribeProductSuccessAction = (data: any): IProductAction => ({
  type: SUBSCRIBE_PRODUCT_SUCCESS,
  payload: data,
});

export const subscribeProductErrorAction = (error: string): IProductAction => ({
  type: SUBSCRIBE_PRODUCT_ERROR,
  payload: error,
});

export const subscribeProductResetAction = (): IProductAction => ({
  type: SUBSCRIBE_PRODUCT_RESET,
});

export const selectProductAction = (product: IProduct): IProductAction => ({
  type: SELECT_PRODUCT,
  payload: product,
});

export const selectProductResetAction = (): IProductAction => ({
  type: SELECT_PRODUCT_RESET,
});

export const fetchSubscribedRequestAction = (): IProductAction => ({
  type: FETCH_SUBSCRIBED_REQUEST,
});

export const fetchSubscribedSuccessAction = (subscribedProduct: IProduct): IProductAction => ({
  type: FETCH_SUBSCRIBED_SUCCESS,
  payload: subscribedProduct,
});

export const fetchSubscribedErrorAction = (error: string): IProductAction => ({
  type: FETCH_SUBSCRIBED_ERROR,
  payload: error,
});

export const fetchSubscribedResetAction = (): IProductAction => ({
  type: FETCH_SUBSCRIBED_RESET,
});

export const fetchProducts = (userId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchProductsRequestAction);
    fetchProductsAPI(userId, token)
      .then((response) => {
        const products = response.data.data;
        dispatch(fetchProductSuccessAction(products));
      })
      .catch((error) => {
        const errorMsg = error?.message;
        dispatch(fetchProductErrorAction(errorMsg));
      })
      .finally(() => {
        dispatch(fetchProductResetAction);
      });
  };
};

export const subscribeProduct = (
  userSubId: string,
  planId: number,
  cardId: number,
  onFulfilled?: (...args: any) => void,
) => {
  return (dispatch: any) => {
    dispatch(subscribeProductsRequestAction());
    subscribeProductAPI(userSubId, planId, cardId)
      .then((response) => {
        const responseCode = response.data?.httpCode;
        const data = response.data?.data;
        if (responseCode >= 200 && responseCode <= 205) {
          dispatch(subscribeProductSuccessAction(data));
          //  console.log("message",data)

          // fetchSubscribedProduct(userId, token);
          // dispatch(fetchProducts(userId));
        } else {
          dispatch(subscribeProductErrorAction(response.data?.message));
        }
        onFulfilled?.();
      })
      .catch((err) => {
        const errorMsg = err?.message;
        dispatch(subscribeProductErrorAction(errorMsg));
        onFulfilled?.();
      })
      .finally(() => {
        dispatch(subscribeProductResetAction());
        onFulfilled?.();
      });
  };
};

export const fetchSubscribedProduct = (userId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(fetchSubscribedRequestAction());
    fetchUserAPI(userId, token)
      .then((response) => {
        const product = response?.data?.data?.subscription?.plan;
        dispatch(fetchSubscribedSuccessAction(product));
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        dispatch(fetchSubscribedErrorAction(errorMsg));
        handleError(errorMsg);
      })
      .finally(() => {
        dispatch(fetchSubscribedResetAction);
      });
  };
};

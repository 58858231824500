import { useAuth0 } from "@auth0/auth0-react";
import AppButton from "common/AppButton";
import { CreditCardIcon, FundingIcon } from "common/AppIcons";
import CardInput from "common/CardInput";
import CheckoutPage from "components/CheckoutPage/CheckoutPage";
import { CardBrandReverse } from "constants/cards";
import * as routes from "constants/routes";
import useFetchUserCards from "hooks/useFetchCards";
import { useFetchUserCtx } from "hooks/useFetchUser";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { subscribeProductRemoveAPI } from "services/product";
import { RootState } from "store";
import { ICard } from "types";
import { IProduct } from "types/IProduct";
import { handleSuccess } from "utils/handleSuccess";
import "./Account.scss";
import { NavLink, useHistory } from "react-router-dom";

interface fundingInfoProps {
  reference1: any;
}

const FundingInfo: React.FC<any> = ({ reference1 }: fundingInfoProps) => {
  const history = useHistory();
  const refetchUser = useFetchUserCtx();
  const subscribedProduct = useSelector((state: RootState) => state.products.subscribedProduct);
  const subInfo = useSelector((state: RootState) => state.user.user.subscription);
  console.log("subInfo", subInfo?.plan);
  const selectedProduct: IProduct = useSelector(
    (state: RootState) => state.products.selectedProduct,
  );
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const toggle = useSelector((state: RootState) => state.cardToggle.isOn);
  // console.log("toggle", toggle);
  // useEffect(() => {
  //   dispatch(toggleOff());
  // }, [dispatch]);

  const cards = useSelector((state: RootState) => state.cards.cards);
  const userInfo = useSelector((state: RootState) => state.user.user);

  const fundingCardId = userInfo?.subscription.card?.id;

  const cardRemoveSuccess = useSelector((state: RootState) => state.cards.cardRemoveSuccess);

  const refetch = useFetchUserCards();

  let fundingCard = cards.find((card: ICard) => card.id === fundingCardId);
  // let fundingCard = cards.find((card: ICard) => card.isDefault === true);

  const [addCardModal, setAddCardModal] = useState(false);
  const [removeCardModal, setRemoveCardModal] = useState(false);
  const [changedFunding, setChangedFunding] = useState(false);

  const cardPostSuccess = useSelector((state: RootState) => state.cards.cardPostSuccess);

  useEffect(() => {
    if (cardPostSuccess) setAddCardModal(false);
  }, [cardPostSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const ChangeFundingCardModal = () => {
    return (
      <Modal show={changedFunding} animation={false} border size="xl">
        <Modal.Body style={{ padding: "12px 18px" }}>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
            onClick={() => {
              setChangedFunding(false);
            }}
          >
            X
          </div>
          <div>
            <CheckoutPage hide setChangedFunding={setChangedFunding} />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderAddCardModal = () => {
    return (
      <Modal show={addCardModal} onHide={() => setAddCardModal(false)} animation={false}>
        <Modal.Body style={{ padding: "12px 18px" }}>
          <div className="d-flex flex-row justify-content-between">
            <h5>Add your Credit Card</h5>
            <AppButton className="borderless primary" onClick={() => setAddCardModal(false)}>
              <span className="iconify" data-icon="uil-multiply" data-inline="false"></span>
            </AppButton>
          </div>

          <CardInput editMode={false} isDefault={true} />
        </Modal.Body>
      </Modal>
    );
  };

  // const handleRemoveCard = async () => {
  //   const token = await getAccessTokenSilently();
  //   user && user?.sub && dispatch(removeCard(user?.sub, fundingCard?.id, token));
  //   setRemoveCardModal(false);
  // };
  const handleRemoveCard = async () => {
    try {
      if (!user?.sub) return;

      const res = await subscribeProductRemoveAPI(
        subInfo?.id,
        Number(selectedProduct.id),
        null,
        true,
      );

      if (res.status !== 200) throw new Error("Something went wrong");

      handleSuccess("Updated subscription");
      refetchUser();
      setRemoveCardModal(false);

      // history.push(routes.CHECKOUT);
    } catch (error) {
      console.log(error);
    }
  };

  const RemoveCardModal = () => {
    return (
      <Modal show={removeCardModal} animation={false}>
        <Modal.Body style={{ padding: "12px 18px" }}>
          <div className="nrm-text-reg" style={{ textAlign: "center", padding: "16px 0px" }}>
            Are you sure you want to remove this card?
          </div>
          <div className="form-row justify-content-center">
            <AppButton
              size="sm"
              className="secondary m-1"
              //onClick={() =>  console.log('pause')}

              onClick={handleRemoveCard}
            >
              Confirm
            </AppButton>
            <AppButton size="sm" className="primary m-1" onClick={() => setRemoveCardModal(false)}>
              Cancel
            </AppButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {renderAddCardModal()}
      <RemoveCardModal />
      <ChangeFundingCardModal />
      <div className="funding-info" ref={reference1}>
        <h5>
          <FundingIcon />
          Payment Method
        </h5>
        {subInfo?.plan ? (
          <div>
            {!fundingCard ? (
              <AppButton className="secondary borderless" onClick={() => setChangedFunding(true)}>
                <div className="sm-text-bold">+ Add payment method</div>{" "}
              </AppButton>
            ) : (
              <AppButton
                className="secondary borderless"
                onClick={() => {
                  setChangedFunding(true);
                }}
              >
                <div className="sm-text-bold">Change Card</div>{" "}
              </AppButton>
            )}
          </div>
        ) : (
          <AppButton className="secondary borderless">
            <div
              className="sm-text-bold"
              onClick={() => {
                history.push(routes.SUBSCRIPTION);
              }}
            >
              Select Plan First
            </div>{" "}
          </AppButton>
        )}
      </div>
      <Card className="appCard">
        {fundingCard ? (
          <>
            <div className="subCardInnerAlign">
              <div className="d-flex flex-row">
                <CreditCardIcon style={{ height: "40px", width: "30px", color: "#828282" }} />
                <div
                  className="md-text-reg"
                  style={{ textTransform: "capitalize", alignSelf: "center" }}
                >
                  {`${CardBrandReverse?.[fundingCard?.brand || ""]} ****${fundingCard?.lastFour}`}
                </div>
              </div>

              <button className="remove-card" onClick={() => setRemoveCardModal(true)}>
                <FaTrashAlt className="remove-card" />
              </button>
            </div>
          </>
        ) : (
          <div className="invalid-feedback-text">Please enter a funding card to subscribe</div>
        )}
      </Card>
    </>
  );
};

export default FundingInfo;

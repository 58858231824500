import React from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from 'constants/routes';
import './footer.scss';
import { useAuth0 } from '@auth0/auth0-react';

interface FooterProps {
  onClickLoginContact: Function;
}

const Footer: React.FC<any> = ({ onClickLoginContact }: FooterProps) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <div id="footer">
        <div className="brand">
          <h4 onClick={() => history.push(routes.HOME)} style={{ cursor: 'pointer' }}>
            PRIA
          </h4>
        </div>

        <ul className="sitemap">
          <li onClick={() => history.push(routes.ABOUT)}>
            <h6 style={{ fontWeight: 600, fontSize: '18px' }}>About</h6>
          </li>
          {/* <li onClick={() => history.push(routes.FEEDBACK)}>
            <h6 style={{fontWeight:600, fontSize:'18px'}}>Review</h6>
          </li> */}
          {!isAuthenticated ? (
            <li>
              <h6
                style={{ fontWeight: 600, fontSize: '18px' }}
                onClick={() => history.push(routes.CONTACT)}
              >
                Contact
              </h6>
            </li>
          ) : (
            <li>
              <h6
                style={{ fontWeight: 600, fontSize: '18px' }}
                onClick={() => history.push(routes.FEEDBACK)}
              >
                Review
              </h6>
            </li>
          )}
          <li onClick={() => history.push(routes.FAQ)}>
            <h6 style={{ fontWeight: 600, fontSize: '18px' }}>FAQ</h6>
          </li>
        </ul>
      </div>

      <div className="subfooter">
        <div className="btm-mgr">
          <div className="sm-text-reg">
            © 2021 Pria. All rights reserved. |{' '}
            <span
              className="sm-text-reg"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(routes.POLICY)}
            >
              Privacy Policy |
            </span>
            <span
              className="sm-text-reg"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(routes.TERMS)}
            >
              Terms of Use
            </span>
          </div>
        </div>
        {/* <div className="additional-links">
          <div
           
          >
            Privacy
          </div>
          <div
            className="sm-text-reg"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(routes.TERMS)}
          >
            Terms of Use
          </div>
        </div> */}
        {!isAuthenticated ? (
          <div className=" sm-text-reg">
            Account holders must be at least 18 years old and a citizen of the United States or a
            permanent resident. Results are not guaranteed. Improvement in your credit score is
            dependent on your specific situation and financial behavior. Pria Charge & Pay payments
            do not include cost of Subscription Service. This product will not remove negative
            credit history from your credit report. Please refer to our{' '}
            <u onClick={() => history.push(routes.TERMS)}> Terms of Services</u> for additional
            details.{' '}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Footer;

import { useAuth0 } from "@auth0/auth0-react";
import { fetchProducts, fetchSubscribedProduct, selectProductAction } from "actions";
import { toggleWillDoLaterAction } from "actions/utils";
import checkIcon from "assets/img/check-icon.png";
import uncheckIcon from "assets/img/uncheck-icon.png";
import AppButton from "common/AppButton";
import "components/OnBoarding/OnBoarding0.scss";
import * as routes from "constants/routes";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RootState } from "store";
import { IProduct } from "types/IProduct";
import { handleError } from "utils/handleError";
import "./OnBoarding.scss";

const OnBoarding4: React.FC<any> = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const products = useSelector((state: any) => state.products.products);

  const selectedProduct = useSelector((state: any) => state.products.selectedProduct);

  const { user, getAccessTokenSilently } = useAuth0();
  const userInfo = useSelector((state: RootState) => state.user.user);

  const history = useHistory();
  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        user && user.sub && dispatch(fetchProducts(user.sub, token));
      })
      .catch((err) => handleError());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //dispatch select product action if products exist, as a parameter pass the last element of the array, why the last element of an array, because we're assuming costliest product will be in the last element of the array
  useEffect(() => {
    const productLength = products?.length - 1;
    if (products?.[productLength]) {
      dispatch(selectProductAction(products?.[productLength]));
    }
  }, [dispatch, products]);

  return (
    <div className="subs-page onboarding4">
      <div className="container" style={{ maxWidth: "100%" }}>
        <div className="d-flex flex-column align-items-center w-100">
          <h4>Select a subscription</h4>
          <div className="form-container">
            <div className="form">
              {products?.map((prod: IProduct, index: number) => (
                <Card
                  key={`Subscriptions_${index}`}
                  className={""}
                  onClick={() => {
                    dispatch(selectProductAction(prod));
                  }}
                >
                  <div className="col-xs-2 align-items-center">
                    <img
                      src={selectedProduct.id === prod.id ? checkIcon : uncheckIcon}
                      alt="check"
                    />
                  </div>

                  <div className="col-sm-10 label">
                    <h6 style={{ textTransform: "capitalize" }}>{prod.name}</h6>
                    <div className="lg-text-bold mt-2">
                      {`$${prod?.price / prod?.intervalCount}/${prod?.interval}`}
                    </div>
                    <div className="sm-text-reg mt-4 ">
                      {`$${prod?.price} billed every ${
                        prod?.intervalCount === 1 ? "" : prod?.intervalCount
                      } ${prod?.interval}`}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
          <div className="onboarding4__btn">
            <AppButton onClick={() => setActiveStep(1)} className="primary outlined mr-1">
              Go Back
            </AppButton>
            <AppButton
              type={userInfo.onboardingIndex < activeStep ? "submit" : "button"}
              className="primary"
              onClick={() => setActiveStep(3)}
              disabled={isEmpty(selectedProduct)}
            >
              Continue
            </AppButton>
          </div>
          <div className="onboarding-text-2">
            <AppButton
              className="borderless primary"
              onClick={() => {
                dispatch(toggleWillDoLaterAction());
                history.push(routes.DASHBOARD);
              }}
            >
              I will do this later
            </AppButton>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default OnBoarding4;

import { useAuth0 } from "@auth0/auth0-react";
import frame2 from "assets/img/frame2.svg";

import { logoutloader } from "actions/logoutloader";
import arrow from "assets/img/arrow.png";
import blackcard from "assets/img/Black Card no Logo.png";
import pria_small1 from "assets/img/cardarch.png";
import ChargePay from "assets/img/ChargePay.png";
import ChooseSubscription from "assets/img/ChooseSubscription.svg";
import CreateAccount from "assets/img/CreateAccount.png";
import meternneedle from "assets/img/CreditMeteredit1.png";
import RegisterCard from "assets/img/RegisterCard.svg";
import priatemp from "assets/img/three-phone-background-image.png";
import AppButton from "common/AppButton";
import Footer from "components/Footer";
import NavMenu from "components/NavMenu/Navmenu";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch } from "react-redux";

import "./Home.scss";

import fi1 from "assets/img/feature-icon-1.png";
import fi2 from "assets/img/feature-icon-2.png";
import fi3 from "assets/img/feature-icon-3.png";
import fi4 from "assets/img/feature-icon-4.png";
import fico from "assets/img/fico1.png";
import Spinners from "components/Spinners/Spinners";
import Vidplayer from "components/Vidplayer/Vidplayer";
import useSetPermissions from "hooks/usePermissions";
import Lottie from "lottie-web";
import "./Carousel.scss";

const Home: React.FC = () => {
  const { loginWithRedirect, user, isAuthenticated, error, logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
  }, []);
  const [carousalMode, setCarousalMode] = useState<boolean>(
    window.innerWidth <= 500 ? true : false,
  );
  const handleResponsive = () => {
    if (window.innerWidth <= 500) {
      setCarousalMode(true);
    } else {
      setCarousalMode(false);
    }
  };

  const containerr = useRef(null);
  const containerrmob = useRef(null);

  useEffect(() => {
    Lottie.loadAnimation({
      container: containerr.current as any,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("./data.json"),
    });
  }, []);

  useEffect(() => {
    Lottie.loadAnimation({
      container: containerrmob.current as any,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("./data.json"),
    });
  }, []);

  const isLoading = useSetPermissions();

  if (isLoading && isAuthenticated) {
    return <Spinners />;
  }

  if (error) {
    return (
      <div className="error-page">
        <h3 className="error-page1">
          Oops...seems like you don&apos;t have an account. {error.message}
        </h3>

        <AppButton
          className="primary outlined"
          style={{ width: "264px" }}
          onClick={() => {
            dispatch({ type: "LOG_OUT" });
            dispatch(logoutloader());
            logout({ returnTo: window.location.origin });
          }}
        >
          Go back to Pria home
        </AppButton>
      </div>
    );
  }

  return (
    <div className="page">
      <div className="hero-section">
        <NavMenu />
        <div className="main">
          <div className="main-container">
            <div className="content">
              {/* <img className="hero-img-mobile" src={hero} alt="ocean" /> */}
              <div className="containerrmob" ref={containerrmob}></div>
              <h1 className="primary">Better Credit, Made Simple</h1>
              <p style={{ letterSpacing: 0.6, lineHeight: 1.5 }}>
                {/* Introducing the first application to automate<br></br> the process of building and
                protecting your<br></br> credit score. */}
                Minimize your credit card use. Automate the
                <br /> process of building and protecting
                <br /> your credit score.
              </p>
              <div className="btn-homepg">
                <AppButton
                  className="primary"
                  style={{ width: "264px", height: "55px" }}
                  onClick={() =>
                    loginWithRedirect({
                      screen_hint: "signup",
                    })
                  }
                >
                  Get Started
                </AppButton>
              </div>
              <img
                className="sponsors-mobile"
                src={frame2}
                alt="ocean"
                style={{ width: "228px" }}
              />
              {/* <div className='sponsors'></div> */}
              <img className="sponsors" src={frame2} alt="ocean" />
            </div>
            {/* <img className="hero-img" src={hero} alt="ocean" /> */}
            <div className="containerr" ref={containerr}></div>
          </div>
        </div>
      </div>
      <section className="top-banner">
        PRIA IS A PREPAID SUBSCRIPTION SERVICE DESIGNED TO BUILD AND PROTECT YOUR CREDIT SCORE.
      </section>
      <section className="top-banner1">ONE PAYMENT. BETTER CREDIT. ZERO DEBT.</section>
      {carousalMode ? (
        <section className="top-section">
          <div className="ftr-group">
            <div className="ftr1">
              <img src={blackcard} alt="ocean" className="black-cardimg" />
              <div className="card-txtmarg1"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                How Pria Works
              </div>
              <div className="card-txtmarg4"></div>
              <div className="md-text-reg " style={{ lineHeight: 1.5, color: " #081149" }}>
                {/* <div className='txt-align'> */}
                Pria places a small $3 subscription on your existing credit card which the app
                charges and pays off monthly. We call this feature Charge & Pay.
                {/* </div> */}
              </div>
              <div className="activity-block"></div>
            </div>
            <div className="ftr2">
              <div className="fico1">
                <img src={fico} alt="ocean" className="fico-cardimg" />
              </div>
              <div className="card-txtmarg2"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                Automation
              </div>
              <div className="card-txtmarg4">
                <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                  Charge & Pay automates your Credit Utilization and Payment History, eliminating
                  the risk of missed payments and the need for active credit card use.
                </div>
                <div className="activity-block1"></div>
              </div>
            </div>
            <div className="ftr">
              <div className="credit-score">
                <img src={meternneedle} alt="ocean" className="score-cardimg" />
              </div>
              <div className="card-txtmarg3"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                Impact on Credit Score
              </div>
              <div className="card-txtmarg4"></div>
              <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                With Pria, users can expect to see an <br /> improvement in their credit score in as{" "}
                <br /> little as 30 days.
              </div>
              <div className="activity-block"></div>
            </div>
          </div>
        </section>
      ) : (
        <section className="top-section">
          <div className="ftr-group">
            <div className="ftr1">
              <img src={blackcard} alt="ocean" className="black-cardimg" />
              <div className="card-txtmarg1"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                How Pria Works
              </div>
              <div className="card-txtmarg4"></div>
              <div className="md-text-reg " style={{ lineHeight: 1.5, color: " #081149" }}>
                {/* <div className='txt-align'> */}
                {/* Pria creates a small automated subscription on your
                <br /> existing credit card which the app charges and pays
                <br /> off monthly. We call this feature Charge & Pay. */}
                Pria places a small $3 subscription on your existing
                <br /> credit card which the app charges and pays off
                <br /> monthly. We call this feature Charge & Pay.
                {/* </div> */}
              </div>
              <div className="activity-block"></div>
            </div>
            <div className="ftr2">
              <div className="fico1">
                <img src={fico} alt="ocean" className="fico-cardimg" />
              </div>
              <div className="card-txtmarg2"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                Automation
              </div>
              <div className="card-txtmarg4">
                <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                  Charge & Pay automates your Credit Utilization
                  <br /> and Payment History, eliminating the risk of missed
                  <br /> payments and the need for active credit card use.
                </div>
                <div className="activity-block1"></div>
              </div>
            </div>
            <div className="ftr">
              <div className="credit-score">
                <img src={meternneedle} alt="ocean" className="score-cardimg" />
              </div>
              <div className="card-txtmarg3"></div>
              <div className="lg-text-bold" style={{ letterSpacing: 1, color: "#081149" }}>
                Impact on Credit Score
              </div>
              <div className="card-txtmarg4"></div>
              <div className="md-text-reg" style={{ lineHeight: 1.5, color: " #081149" }}>
                With Pria, users can expect to see an <br /> improvement in their credit score in as{" "}
                <br /> little as 30 days.
              </div>
              <div className="activity-block"></div>
            </div>
          </div>
        </section>
      )}
      <section className="mid-mar">
        <div className="meet-pria">
          <h4 className="small-txt " style={{ letterSpacing: 1, color: "#081149" }}>
            Meet Pria
          </h4>
          {/* <img className="meet-pria__img" src={MeetPriaImg} alt="meet pria" /> */}
          <Vidplayer />
          <div className="meet-pria__text2">
            Register 3 Credit Cards per Subscription| $36 Semi-Annually | Visa and Master Card Only
          </div>
          {/* <div className='meet-pria__img'>
      <div>
        <img className='meet-pria__1' src={meet_pria} alt="meetpria"/>
      </div>
       
      <div>
        <img className='meet-pria__2' src={master_card} alt=""/>
        <img className='meet-pria__3' src={purple_card} alt=""/>
      </div>

      </div> */}
        </div>
      </section>
      {carousalMode ? (
        <>
          <div className="ben-page">
            <div className="ben-tile">
              <h4 className="small-txt " style={{ letterSpacing: 1, color: "#081149" }}>
                Benefits
              </h4>
            </div>
          </div>
          <Carousel controls={false} indicators={true}>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi1} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Automate Building<br></br> Your Credit Score
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Pria builds your
                    <br /> credit score even if
                    <br /> you are not actively
                    <br /> using your credit card.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi2} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Avoid Credit<br></br> Card Debt
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    As a Prepaid Service,
                    <br /> Pria eliminates the risk <br /> of missed payments.
                    <br /> Better Credit, zero debt.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi3} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Protect Your<br></br> Credit History
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Length of Credit History is
                    <br /> 15% of your Credit Score. Pria
                    <br /> prevents credit cards from
                    <br /> being cancelled for non-use.
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <img className="ftr-imageMobile" style={{ width: 125 }} src={fi4} alt="ocean" />
                  <div
                    className="lg-text-bold"
                    style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                  >
                    Make One Payment,<br></br> Not Several
                  </div>
                  <div className="md-text-reg" style={{ color: "#081149" }}>
                    Pria does not require <br /> monthly payments.
                    <br /> Pria operates on a <br />
                    single payment.
                  </div>
                </div>
              </section>
            </Carousel.Item>
          </Carousel>
          <div className="spacebtn">
            <div className="quote">
              <p>At Pria we believe using a Credit Card should be an option, not an Obligation. </p>
              <p>We hope you enjoy! </p>
            </div>
          </div>
          <section className="d-flex flex-column align-items-center getting-startedmar">
            <h4 className="small-txt">Getting Started</h4>
          </section>
          <Carousel controls={false} indicators={true}>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 150, height: 150 }} src={CreateAccount} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5>Create an account</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 150, marginBottom: 60 }} src={RegisterCard} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5>Register your card</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 150, height: 180 }} src={ChooseSubscription} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5 style={{ textAlign: "left" }}>Choose a subscription</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
              <section className="d-flex flex-column align-items-center">
                <div className="ftr">
                  <div className="illustrations">
                    <img style={{ maxWidth: 180, marginBottom: 64 }} src={ChargePay} alt="" />
                    <img id="arrow" src={arrow} alt="arrow" />
                    <h5>Charge & Pay</h5>
                  </div>
                </div>
              </section>
            </Carousel.Item>
          </Carousel>
        </>
      ) : (
        <>
          {/* return ( */}
          <section>
            <div className="ben-page">
              <div className="ben-tile font-hai" style={{ letterSpacing: 1 }}>
                <h4>Benefits</h4>
              </div>
            </div>
          </section>
          <section>
            <div className="ftr-group">
              <div className="ftr">
                <img className="ftr-image" src={fi1} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Automate Building<br></br> Your Credit Score
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Pria builds your
                  <br /> credit score even if
                  <br /> you are not actively
                  <br /> using your credit card.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi2} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Avoid Credit<br></br> Card Debt
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  As a Prepaid Service,
                  <br /> Pria eliminates the risk <br /> of missed payments.
                  <br /> Better Credit, zero debt.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi3} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Protect Your<br></br> Credit History
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Length of Credit History is
                  <br /> 15% of your Credit Score. Pria
                  <br /> prevents credit cards from
                  <br /> being cancelled for non-use.
                </div>
              </div>
              <div className="ftr">
                <img className="ftr-image" src={fi4} alt="ocean" />
                <div
                  className="lg-text-bold"
                  style={{ marginBottom: 32, fontWeight: 400, color: "#081149" }}
                >
                  Make One Payment,<br></br> Not Several
                </div>
                <div className="md-text-reg" style={{ color: "#081149" }}>
                  Pria does not require <br /> monthly payments.
                  <br /> Pria operates on a <br />
                  single payment.
                </div>
              </div>
            </div>
          </section>
          <section style={{ display: "inline-block" }}>
            <div className="spacebtn">
              <div className="quote">
                <p>
                  At Pria we believe using a Credit Card should be an option, not an Obligation. We
                  hope you enjoy!
                </p>
              </div>
            </div>
          </section>
          <section className="d-flex flex-column align-items-center">
            <h3>Getting Started</h3>
            <div className="ftr-group">
              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 150, height: 150 }} src={CreateAccount} alt="" />
                  <h5>Create an account</h5>
                </div>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 150, marginBottom: 60 }} src={RegisterCard} alt="" />
                  <h5>Register your card</h5>
                </div>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 150, height: 180 }} src={ChooseSubscription} alt="" />
                  <h5 style={{ textAlign: "left" }}>Choose a subscription</h5>
                </div>
              </div>

              <img id="arrow" src={arrow} alt="arrow" />

              <div className="ftr">
                <div className="illustrations">
                  <img style={{ maxWidth: 180, marginBottom: 64 }} src={ChargePay} alt="" />
                  <h5>Charge & Pay</h5>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* <Carousel controls={true} indicators={false}>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>How does Pria work?</h3>
                <div className="lg-text-reg mt-4">
                  Pria works by creating a small automated expense that is charged and paid off by
                  the app at the end of each month. We call this feature Charge&Pay.
                </div>
                <div className="carimg mt-4">
                  <img src={how} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>Why does Pria work?</h3>
                <div className="lg-text-reg mt-4">
                  The Pria Charge&Pay feature works by addressing each component of a card holders
                  credit score.
                </div>
                <div className="carimg mt-4">
                  <img src={fico} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="carousel-img" src={priahalftemp} />
          <Carousel.Caption>
            <div className="carousel-content">
              <div className="carousel-text">
                <h3>Who is Pria for?</h3>
                <div className="lg-text-reg mt-4">
                  Pria is for anyone who wants to build their credit score with minimal risk, or
                  preserve their credit history with minimal effort.
                </div>
                <div className="nrm-text-reg mt-4">
                  <p> - Perfect for young adults managing their first credit card.</p>- Perfect for
                  mature adults with old or seldom used credit cards.
                </div>
                <div className="carimg mt-4">
                  <img src={creditscore} alt="ocean" />
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
      {/* <div className="faqs">
        <h3>Frequently Asked Questions</h3>

        {FAQ.map((faq: any, key: number) => (
          <FAQComp faq={faq} index={key} key={key} faqOpen={faqOpen[key]} />
        ))}
      </div> */}
      <div className="pria-financial-text">
        PRIA <img className="pria-logo" src={pria_small1} alt="" />
        FINANCIAL.COM
      </div>
      <div className="pria-financial-text-2">
        <div className="sm-text text-center">
          REGISTER $3 CREDIT CARDS PER SUBSCRIPTION | $36 SEMI-ANNUALLY | VISA & MASTERCARD ONLY
        </div>
      </div>
      <div className="pria-financial-mobile-image" style={{ textAlign: "center" }}>
        <img src={priatemp} alt="LOGO" className="pria-temp-image" />
      </div>
      <div className="end-banner">
        <h4>Get started with Pria today </h4>
        <AppButton
          className="outlined primary"
          onClick={() =>
            loginWithRedirect({
              screen_hint: "signup",
            })
          }
        >
          <div className="md-text-bold"> Sign Up</div>
        </AppButton>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

export interface IUser {
  first_name: string;
  middle_name: string;
  last_name: string;
  phone?: string;
  phoneVerified?: boolean;
  onBoardingIndex?: number;
  address: {
    locality: string;
    country: string;
    address1?: string;
    address2?: string;
    postalCode?: string;
    administrativeArea?: string;
  };
  subscription: Record<string, any>;
}

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_RESET = "FETCH_USER_RESET";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const UPDATE_USER_TYPE = "UPDATE_USER_TYPE";
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION";

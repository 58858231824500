import * as urls from 'constants/urls';
import { IUser } from 'types/IUser';
import * as crypto from 'crypto';
import http from './http';
export const fetchUserAPI = (userId: string, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.get(`${urls.USER}/${userId}`);
};

export const updateUserAPI = (userId: string, data: IUser, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.put(`${urls.USER}/${userId}`, data);
};

export const changePasswordAPI = (userId: string, password: any, token: string) => {
  const iv = crypto.randomBytes(16).toString('hex').slice(0, 16);

  let cipher = crypto.createCipheriv(
    'aes-256-cbc',
    process.env.REACT_APP_CRYPTO_SECRET_KEY || '',
    iv,
  );
  let encrypted = cipher.update(password, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return http.put(`${urls.USER}/change-password`, {
    password: encrypted,
    // userId: userId,
    randomBytes: iv,
  });
};

export const verifyEmailAPI = (userId: string, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.post(`${urls.USER}/verify-email/`);
};

export const postNotificationsAPI = (userId: string, token: string, request: any) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.post(`${urls.USER}/notification`, request);
};

export const getNotificationsAPI = (userId: string, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.get(`${urls.USER}/notification/${userId}`);
};

export const deleteAccountAPI = (userId: string, token: string, code?: any) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return code ? http.delete(`${urls.USER}/?code=${code}`) : http.delete(`${urls.USER}/`);
};

import { getAllLogs } from "../../../api/admin";
import DynamicTable from "components/DynamicTable";
import { FilterOptions, useFilter } from "components/DynamicTable/FilterOptions";
import { FilterList } from "components/DynamicTable/TableFilter";
import useGetAccessToken from "hooks/useGetAccessToken";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

interface Ilogs {
  timestamp: string | number;
  logStreamName: string;
  message: string;
}

const Logs = () => {
  const { filterOptions, setLimit, setFilter } = useFilter();
  // const [nextToken, setNextToken] = useState();

  // const { data: logsData, isLoading } = useQuery(
  //   ["alllogs", filterOptions],
  //   () => getAllLogs(filterOptions),
  //   {
  //     onSuccess: (data: any) => {
  //       // console.log("success fetch");
  //     },
  //   },
  // );

  const [logsData, setLogData] = useState<any[]>([]);
  const token = useGetAccessToken();

  const { isLoading, mutate: mGetAllLogs } = useMutation(
    (filterOptions: FilterOptions) => getAllLogs(token || "", filterOptions),
    {
      onSuccess: (logsData: any) => {
        const res = logsData?.data;
        setFilter({
          nextToken: res.nextToken,
        });

        setLogData((s) => s.concat(res?.events));
      },
    },
  );

  useEffect(() => {
    mGetAllLogs(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mGetAllLogs]);

  const logsTable = useMemo<Ilogs[]>(() => {
    try {
      return logsData?.map((singleData: any, index: any) => ({
        index: index,
        timestamp: new Date(singleData?.timestamp).toLocaleDateString(),
        logStreamName: singleData?.logStreamName,
        message: singleData?.message,
      }));
    } catch (err) {
      return [];
    }
  }, [logsData]);

  // console.log(logsData);

  // useEffect(() => {
  //   setLimit(10);
  // }, []);

  // if (isLoading) {
  //   return <Spinner animation="border" style={{ position: "absolute", top: "50%", left: "50%" }} />;
  // }

  const filterList: FilterList = [
    {
      type: "date",
      field: "dateRange",
    },
    {
      type: "number",
      label: "Limit",
      field: "limit",
      value: 10,
    },
  ];

  const onShowMoreClick = () => {
    if (filterOptions.filter?.nextToken) {
      mGetAllLogs(filterOptions);
    }
  };

  return (
    <>
      <DynamicTable<Ilogs>
        title="Logs"
        td={logsTable}
        th={[
          { accessor: "timestamp", h: "Time Stamp", isSortable: true },
          { accessor: "message", h: "Message", isSortable: true },
        ]}
        // filterList={filterList}
        showPagination={false}
        isLoading={isLoading}
        showMore={filterOptions?.filter?.nextToken ? "Show More" : ""}
        onShowMoreClick={onShowMoreClick}
        onFilterChange={() => mGetAllLogs(filterOptions)}
        onSearchChange={() => mGetAllLogs(filterOptions)}
      />
    </>
  );
};

export default Logs;

import { useAuth0 } from "@auth0/auth0-react";
import { subscribeProduct, subscribeProductResetAction } from "actions";
import { toggleWillDoLaterAction } from "actions/utils";
import AppButton from "common/AppButton";
import AppInput from "common/AppInput";
import AppSelect from "common/AppSelect";
import "components/OnBoarding/OnBoarding0.scss";
import * as routes from "constants/routes";
import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { setOnboardingIndexAPI } from "services/util";
import { RootState } from "store";
import { ICard } from "types";
import { IProduct } from "types/IProduct";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import { usePostalCodeState } from "utils/zipCode";
import * as Yup from "yup";
import { postCardQuery as postCard } from "../../api/onboarding3";
import "./OnBoarding5.scss";

import { CardBrand } from "constants/cards";
import useAdministrativeAreas from "hooks/useAdministrativeAreas";
import { useFetchUserCtx } from "hooks/useFetchUser";
import getCardBrand from "utils/cardType";
const selectCardOptions = [
  {
    value: "new",
    label: "New Credit/Debit card",
  },
  {
    value: "existing",
    label: "Choose existing card",
  },
];

const OnboardingCheckout: React.FC<any> = ({ activeStep, setActiveStep }) => {
  const selectedProduct: IProduct = useSelector(
    (state: RootState) => state.products.selectedProduct,
  );
  const cards = useSelector((state: RootState) => state.cards.cards);
  //let defaultCard = cards?.find((card: ICard) => card.isDefault === 'true');
  const [defaultCard, setdefaultCard] = useState(
    cards?.find((card: ICard) => card.isDefault === true) || null,
  );

  const [fundingCard, setFundingCard] = useState<ICard | null>(null);

  const subscriber = useSelector((state: RootState) => state.user.user);
  const userInfo = useSelector((state: RootState) => state.user.user);

  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [newCard, setNewCard] = useState(true);
  const [sameAddress, setSameAddress] = useState(false);

  const { mutate: addNewDefaultCard, isLoading: isLoading } = useMutation(postCard, {
    onSuccess: async ({ data }) => {
      // console.log(postCardData?.data?.data, "card daata");
      setLoading(true);

      const response = data.data;

      response?.id &&
        dispatch(
          subscribeProduct(
            userInfo.subscription.id,
            Number(selectedProduct.id),
            Number(response?.id),
          ),
        );

      setLoading(false);
    },
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      number: "",
      expirationDate: "",
      cvc: "",
      address: {
        address1: "",
        address2: "",
        locality: "",
        administrativeArea: 1,
        country: "US",
        postalCode: "",
      },
      phone: "",
      brand: "",
    },
    validationSchema: newCard
      ? Yup.object({
          number: Yup.string().required("Card Number is required"),
          expirationDate: Yup.string()
            .matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, "Invalid date format")
            .test("validity", "Invalid Date", (value: any) => {
              if (!value) {
                return false;
              }
              const month = parseInt(value.slice(0, 2), 10);
              const year = parseInt(value.slice(-2), 10) + 2000;
              const currentYear = new Date().getFullYear();
              const currentMonth = new Date().getMonth() + 1;
              if (year < currentYear || (year === currentYear && month < currentMonth)) {
                return false; // expiration date is in the past
              }
              if (month < 1 || month > 12) {
                return false; // invalid month number
              }
              return true;
            })
            .required("Expiry Date is required"),
          cvc: Yup.string().required("CVC is required"),
          address: Yup.object().shape({
            address1: Yup.string().required("Street 1 is required"),
            address2: Yup.string().nullable(true),
            locality: Yup.string().required("locality is required"),
            administrativeArea: Yup.string(),
            country: Yup.string().nullable(true),
            postalCode: Yup.string()
              .matches(/^\d{5}([-]\d{4})?$/, "Invalid postal code")
              .required("Postal Code is required"),
          }),
        })
      : "",
    onSubmit: (values: any) => {
      handleFormikSubmit(values);
    },
  });

  const handleFormikSubmit = async (values: any) => {
    const token = await getAccessTokenSilently();

    if (user?.sub) {
      const userId = user?.sub;
      if (newCard) {
        let expYear = `20${values?.expirationDate?.split("/")[1]}`;
        let expMonth = values?.expirationDate?.split("/")[0];
        const requestBody: ICard = {
          number: values.number.replace(/\s/g, ""),
          expirationMonth: parseInt(expMonth, 10),
          expirationYear: parseInt(expYear, 10),
          cvc: parseInt(values.cvc as string, 10),
          address: {
            ...values.address,
            administrativeArea: values.address.administrativeArea,
          },
          isDefault: true,
          brand: CardBrand?.[values.brand?.toLowerCase()] || "",
        };

        handleAddNewCard(userId, requestBody, token);
        if (user && user.sub) {
          let response = await setOnboardingIndexAPI(user.sub, token, activeStep + 1);
          if (response && response.data && checkResponseStatus(response.data.httpCode)) {
            refetchUser();

            handleSuccess("Onboarding Successfully Completed");
            setTimeout(() => {
              history.push(routes.DASHBOARD);
              setActiveStep(3);
            }, 1000);
          }
        }

        // if (isSuccess) {

        // }
      } else if (fundingCard) {
        fundingCard.id &&
          dispatch(
            subscribeProduct(
              userInfo.subscription.id,
              Number(selectedProduct.id),
              Number(fundingCard.id),
            ),
          );
        setLoading(true);

        try {
          const token = await getAccessTokenSilently();
          if (user && user.sub) {
            // let data = await fetchSubscribedProductAPI(user.sub, token);

            let response = await setOnboardingIndexAPI(user.sub, token, activeStep + 1);
            if (response && response.data && checkResponseStatus(response.data.httpCode)) {
              refetchUser();

              handleSuccess("Onboarding Successfully Completed");
              setTimeout(() => {
                history.push(routes.DASHBOARD);
                setActiveStep(3);
              }, 1000);
            }
          }
        } catch (err) {
          handleError("Error onboarding");
        } finally {
          dispatch(subscribeProductResetAction());
        }
      }
    }
  };

  const handleAddNewCard = (userSub: any, values: any, token: any) => {
    addNewDefaultCard({ userId: userSub, values: values, token: token });
  };

  const history = useHistory();

  const handleSameInfo = () => {
    formik.setFieldValue("address.address1", userInfo?.address?.address1);
    formik.setFieldValue("address.address2", userInfo?.address?.address2);
    formik.setFieldValue("address.locality", userInfo?.address?.locality);
    formik.setFieldValue("address.administrativeArea", userInfo?.address?.administrativeArea);
    formik.setFieldValue("address.country", userInfo?.address?.country);
    formik.setFieldValue("address.postalCode", userInfo?.address?.postalCode);
    formik.setFieldValue("phone", subscriber?.phone);
  };

  const resetSameInfo = () => {
    formik.setFieldValue("address.address1", "");
    formik.setFieldValue("address.address2", "");
    formik.setFieldValue("address.locality", "");
    formik.setFieldValue("address.administrativeArea", "");
    formik.setFieldValue("address.country", "US");
    formik.setFieldValue("address.postalCode", "");
    formik.setFieldValue("phone", "");
  };

  const refetchUser = useFetchUserCtx();

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const [states] = useAdministrativeAreas();

  const {
    handleAdministrativeAreaChange: handleStateChange,
    handlePostalCodeChange,
  } = usePostalCodeState(formik);

  return (
    <div className="checkout-page">
      <div className="container">
        <div className="title">
          <h6>Checkout</h6>
        </div>

        <div className="section">
          <div className="md-text-bold">Order Summary</div>
          <div className="card">
            <div>
              <h5 style={{ textTransform: "capitalize" }}> {selectedProduct?.name} </h5>
              <div className="nrm-text-reg">
                {`$${selectedProduct?.price / selectedProduct?.intervalCount}/${
                  selectedProduct?.interval
                }`}
              </div>
            </div>
            <div>
              <div className="align-checkout-amts">
                <h6>Amount:&ensp;</h6>
                <h6>{`$${(selectedProduct?.price).toFixed(2)}`}</h6>
              </div>
              <div className="align-checkout-amts">
                <h6>Tax:&ensp;</h6>
                <h6>{`${selectedProduct?.taxPercentage}%`}</h6>
              </div>
              <div className="align-checkout-amts">
                <h6>Total:&ensp;</h6>
                <h6>{`$${(
                  selectedProduct.price *
                  (1 + selectedProduct?.taxPercentage / 100)
                ).toFixed(2)}`}</h6>
              </div>
              <div>(Including Taxes)</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="md-text-bold">Payment Information</div>
          <div className="card">
            <Form className="col-sm-12">
              <Form.Group as={Row}>
                <Form.Label>Choose Payment method</Form.Label>
                <Col sm="12">
                  <AppSelect
                    options={selectCardOptions}
                    size="sm"
                    style={{ alignSelf: "flex-end", WebkitAppearance: "menulist" }}
                    defaultValue="new"
                    onChange={(e: any) => {
                      setNewCard(e.target.value === "new");
                      setFundingCard(null);
                      // setFundingCard((prev: ICard) => (e.target.value === 'new' ? null : prev));
                      formik.resetForm();
                    }}
                  />
                </Col>
              </Form.Group>
              {!newCard ? (
                <>
                  <Form.Group as={Col}>
                    {cards.map((card: ICard, index: number) => (
                      <div key={index} className="checkbox">
                        <Form.Check
                          id="defaultCard"
                          checked={
                            fundingCard
                              ? fundingCard?.id === card.id
                              : defaultCard
                              ? defaultCard.id === card.id
                              : false
                          }
                          onChange={(e: any) => {
                            setFundingCard(card);
                            formik.setFieldValue("number", `****${card?.lastFour}`);
                          }}
                        />
                        <Form.Check.Label>{`************${card.lastFour}`} </Form.Check.Label>
                      </div>
                    ))}
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group as={Row}>
                    <Form.Label>Name on Card</Form.Label>
                    <Col sm="12">
                      <AppInput
                        name="name"
                        placeholder="Eg. John Doe"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label>Card Number</Form.Label>
                    <Col sm="12">
                      <AppInput
                        placeholder="Your 16 digit card number"
                        name="number"
                        value={formik.values.number}
                        // onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        pattern="[0-9\s]{13,19}"
                        maxLength={19}
                        autoComplete="cc-number"
                        inputMode="numeric"
                        type="tel"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value;
                          const brand = getCardBrand(value, false);
                          formik.setFieldValue(`brand`, brand);

                          return formik.handleChange(e);
                        }}
                        onKeyDown={(e: any) => {
                          formik.setFieldValue(
                            "number",
                            `${e.target.value
                              .replace(/[^\dA-Z]/g, "")
                              .replace(/(.{4})/g, "$1 ")
                              .trim()}`,
                          );
                        }}
                        adornmentText={formik.values.brand}
                      />
                      {formik.errors.number && formik.touched.number && (
                        <div className="invalid-feedback-text">{formik.errors.number}</div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="6">
                      <Form.Label>Expiry Date</Form.Label>
                      <Col sm="12" className="p-0">
                        <AppInput
                          id="valid"
                          type="text"
                          name="expirationDate"
                          maxLength={5}
                          value={formik.values.expirationDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onKeyDown={(e: any) => {
                            if (e.key !== "Backspace" && e.key !== "/") {
                              if (e.target.value.length === 2) {
                                formik.setFieldValue(
                                  "expirationDate",
                                  `${e.target.value.replace(/(.{2})/g, "$1/").trim()}`,
                                );
                              }
                            }
                          }}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat="yyyy-MM"
                            views={['year', 'month']}
                            // label="Year and Month"
                            minDate={new Date()}
                            value={formik.values.expirationDate}
                            onChange={(value: any) => {
                              formik.setFieldValue('expirationDate', value);
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} helperText={null} />
                            )}
                          />
                        </LocalizationProvider> */}
                        {formik.errors.expirationDate && formik.touched.expirationDate && (
                          <div className="invalid-feedback-text">
                            {formik.errors.expirationDate}
                          </div>
                        )}
                      </Col>
                    </Col>
                    <Col sm="6">
                      <Form.Label>CVC</Form.Label>
                      <Col sm="12" className="p-0">
                        <AppInput
                          id="cvc"
                          name="cvc"
                          type="tel"
                          inputMode="numeric"
                          maxLength={4}
                          placeholder="_ _ _ "
                          value={formik.values.cvc}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.cvc && formik.touched.cvc && (
                          <div className="invalid-feedback-text">{formik.errors.cvc}</div>
                        )}
                      </Col>
                    </Col>
                  </Form.Group>
                  <div>
                    <Form.Check.Label className="form-row align-items-center">
                      <Form.Check
                        id="sameInfo"
                        onChange={(val: any) => {
                          val.target.checked ? handleSameInfo() : resetSameInfo();
                          setSameAddress(val.target.checked);
                        }}
                        style={{ width: "fit-content" }}
                      />
                      Use same as profile address
                    </Form.Check.Label>
                  </div>
                  {!sameAddress && (
                    <>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Street 1</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="address.address1"
                              value={formik.values.address?.address1}
                              onChange={formik.handleChange}
                              placeholder="Eg. 123 Accacia Ave."
                            />
                            {formik.errors.address?.address1 &&
                              formik.touched.address?.address1 && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.address1}
                                </div>
                              )}
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>Street 2</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="address.address2"
                              value={formik.values.address?.address2}
                              onChange={formik.handleChange}
                            />
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>City</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="locality"
                              type="text"
                              name="address.locality"
                              value={formik.values.address?.locality}
                              onChange={formik.handleChange}
                              placeholder="Eg. Manhattan"
                            />
                            {formik.errors.address?.locality &&
                              formik.touched.address?.locality && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.locality}
                                </div>
                              )}
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>State/Province</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppSelect
                              options={states}
                              size="sm"
                              style={{ WebkitAppearance: "menulist", maxWidth: "100%" }}
                              name="address.administrativeArea"
                              value={formik.values?.address?.administrativeArea}
                              onChange={handleStateChange}
                              onBlur={formik.handleBlur}
                              placeholder="Select State"
                            />
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Country</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="address.country"
                              readOnly={true}
                              value={formik.values.address?.country}
                              onChange={formik.handleChange}
                              placeholder="Select Country"
                            />
                          </Col>
                        </Col>
                        <Col sm="6">
                          <Form.Label>Zip Code</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="address.postalCode"
                              value={formik.values.address?.postalCode}
                              autoComplete="off"
                              maxLength={5}
                              onChange={handlePostalCodeChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors.address?.postalCode &&
                              formik.touched.address?.postalCode && (
                                <div className="invalid-feedback-text">
                                  {formik.errors.address?.postalCode}
                                </div>
                              )}
                          </Col>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm="6">
                          <Form.Label>Phone</Form.Label>
                          <Col sm="12" className="p-0">
                            <AppInput
                              id="address1"
                              type="text"
                              name="phone"
                              maxLength={14}
                              value={formik.values.phone}
                              onChange={(e: any) => {
                                const re = /^[0-9\b]+$/;
                                if (e.target.value === "" || re.test(e.target.value)) {
                                  formik.handleChange(e);
                                }
                              }}
                              placeholder="Eg. 1 (234) 567-890"
                            />
                          </Col>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
              <div className="sm-text-reg">
                By submitting this form, you agree to our terms of service and privacy policy.
              </div>
              <div className="onboarding5__btn">
                <AppButton
                  onClick={() => setActiveStep(2)}
                  className="primary outlined marg-btnob"
                  // isLoading={userUpdateLoading}
                  // disabled={userUpdateLoading}
                >
                  Go Back
                </AppButton>

                <AppButton
                  type={formik.isValid ? "submit" : "button"}
                  className="primary"
                  onClick={formik.handleSubmit}
                  isLoading={isLoading || loading}
                  disabled={isLoading}
                >
                  Continue
                </AppButton>
              </div>

              <div className="onboarding-text-2">
                <AppButton
                  className="borderless  primary"
                  onClick={() => {
                    dispatch(toggleWillDoLaterAction());
                    history.push(routes.DASHBOARD);
                  }}
                >
                  I will do this later
                </AppButton>
              </div>
            </Form>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default OnboardingCheckout;

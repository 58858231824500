import { FETCH_CARDS, ICardAction, POST_CARD } from 'actions';
import {
  FETCH_CARDS_ERROR,
  FETCH_CARDS_REQUEST,
  FETCH_CARDS_RESET,
  FETCH_CARDS_SUCCESS,
  POST_CARDS_ERROR,
  POST_CARDS_REQUEST,
  POST_CARDS_RESET,
  POST_CARDS_SUCCESS,
  REMOVE_CARDS_ERROR,
  REMOVE_CARDS_REQUEST,
  REMOVE_CARDS_RESET,
  REMOVE_CARDS_SUCCESS,
  UPDATE_CARDS_ERROR,
  UPDATE_CARDS_REQUEST,
  UPDATE_CARDS_RESET,
  UPDATE_CARDS_SUCCESS,
} from 'types';

type CARDS_STATE = {
  cards: Array<any>;
  loading: boolean;
  error: string;
  cardFetchSuccess: boolean;
  cardPostSuccess: boolean;
  cardRemoveSuccess: boolean;
  cardUpdateSuccess: boolean;
  createdCard: any;
};

const INITIAL_STATE: CARDS_STATE = {
  cards: [],
  loading: false,
  error: '',
  cardFetchSuccess: false,
  cardPostSuccess: false,
  cardRemoveSuccess: false,
  cardUpdateSuccess: false,
  createdCard: null,
};

export default (state: CARDS_STATE = INITIAL_STATE, action: ICardAction) => {
  switch (action.type) {
    case FETCH_CARDS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_CARDS_SUCCESS: {
      return { ...state, loading: false, cardFetchSuccess: true, cards: action.payload };
    }
    case FETCH_CARDS_ERROR: {
      return { ...state, error: action.payload };
    }
    case FETCH_CARDS_RESET: {
      return { ...state, error: '', loading: false, cardFetchSuccess: false };
    }
    case POST_CARDS_REQUEST: {
      return { ...state, loading: true };
    }
    case POST_CARDS_SUCCESS: {
      return { ...state, cardPostSuccess: true, loading: false, createCard: action.payload };
    }
    case POST_CARDS_ERROR: {
      return { ...state, cardPostSuccess: false, error: action.payload, loading: false };
    }
    case POST_CARDS_RESET: {
      return { ...state, cardPostSuccess: false, error: '', loading: false };
    }
    case REMOVE_CARDS_REQUEST: {
      return { ...state, loading: true };
    }
    case REMOVE_CARDS_SUCCESS: {
      return { ...state, loading: false, cardRemoveSuccess: true };
    }
    case REMOVE_CARDS_ERROR: {
      return { ...state, error: action.payload };
    }
    case REMOVE_CARDS_RESET: {
      return { ...state, error: '', loading: false, cardRemoveSuccess: false };
    }
    case UPDATE_CARDS_REQUEST: {
      return { ...state, loading: true };
    }
    case UPDATE_CARDS_SUCCESS: {
      return { ...state, loading: false, cardUpdateSuccess: true };
    }
    case UPDATE_CARDS_ERROR: {
      return { ...state, error: action.payload };
    }
    case UPDATE_CARDS_RESET: {
      return { ...state, error: '', loading: false, cardUpdateSuccess: false };
    }

    default:
      return state;
  }
};

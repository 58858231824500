import * as urls from "constants/urls";
import * as crypto from "crypto";
import { store } from "store";
import http from "./http";

export const fetchStatesAPI = () => {
  const token = store.getState().user.token;
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.get(`${urls.STATE}`);
};

export const encryptMessage = (secretMessage: string, randomBytes: string): string => {
  let cipher = crypto.createCipheriv(
    "aes-256-cbc",
    process.env.REACT_APP_CRYPTO_SECRET_KEY || "",
    randomBytes,
  );
  let encrypted = cipher.update(secretMessage.toString(), "utf8", "hex");
  encrypted += cipher.final("hex");
  return encrypted;
};

export const setOnboardingIndexAPI = (userId: string, token: string, index: number) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.put(`${urls.SETTING}/?index=${index}`);
};

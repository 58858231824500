import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import AppButton from "common/AppButton";
import { PauseIcon, RedoIcon } from "common/AppIcons";
import * as routes from "constants/routes";
import { useFetchUserCtx } from "hooks/useFetchUser";
import moment from "moment";
import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { FaPause, FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { pauseSubscriptionAPI } from "services/product";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import "./Account.scss";
import Switch from "@mui/material/Switch";

interface SubscriptionInfoProps {
  reference2: any;
}

const SubscriptionInfo: React.FC<any> = ({ reference2 }: SubscriptionInfoProps) => {
  const history = useHistory();
  const { user } = useAuth0();
  const [confirmPauseSubscription, setConfirmPauseSubscription] = useState(false);
  const handleToggle = () => {
    setConfirmPauseSubscription(!confirmPauseSubscription);
  };
  const userData = useSelector((state: RootState) => state.user.user);
  const subscription = userData?.subscription;
  const plan = subscription?.plan;

  const refetchUser = useFetchUserCtx();

  const pauseSubscription = async () => {
    try {
      if (user && user?.sub) {
        let response = await pauseSubscriptionAPI(
          subscription?.id,
          Number(plan.id),
          Number(subscription.card.id),
          false,
        );
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          handleSuccess("Subscription Paused");
          refetchUser();
          setConfirmPauseSubscription(false);
        } else {
          handleError("Could not pause subscription");
        }
      }
    } catch (err: any) {
      handleError(err.message);
    }
  };

  const resumeSubscription = async () => {
    try {
      if (user && user?.sub) {
        let response = await pauseSubscriptionAPI(
          subscription?.id,
          Number(plan.id),
          Number(subscription.card.id),
          true,
        );
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          handleSuccess("Subscription Resumed");
          refetchUser();
          setConfirmPauseSubscription(false);
        } else {
          handleError("Could not resume subscription");
        }
      }
    } catch (err: any) {
      handleError(err.message);
    }
  };

  const expiry = userData?.subscription?.expiresAt;
  const isSubscriptionEnabled = subscription?.isEnabled;

  return (
    <div>
      <div className="subscription-info" ref={reference2}>
        <h5>
          <RedoIcon />
          Subscription
        </h5>
        <AppButton
          className="secondary borderless"
          onClick={() => history.push(routes.SUBSCRIPTION)}
          // title="Pause subscription"
        >
          <div className="sm-text-bold">View all Plans</div>{" "}
        </AppButton>
      </div>
      <Card className="appCard">
        {plan ? (
          <div>
            <div className="subCardInnerAlign">
              <div className="md-text-reg" style={{ textTransform: "capitalize" }}>
                {plan.name} {"-"} {`$${plan.price / plan.intervalCount}/${plan.interval}`}
                <br />
                <Typography component="p" sx={{ fontSize: "0.8rem", margin: 0, mt: 1 }}>
                  {userData?.subscription?.status === "active" && "Valid until"} <br />
                  {userData?.subscription?.status === "active" &&
                    moment(expiry ? new Date(Number(expiry)) : new Date()).format("MMMM DD, YYYY")}
                </Typography>
              </div>
              <div className="subCardInnerBtn">
                <div>
                  {" "}
                  <Switch
                    checked={isSubscriptionEnabled}
                    onChange={handleToggle}
                    name="confirmPauseSubscription"
                    inputProps={{ "aria-label": "pause subscription confirmation switch" }}
                  />
                </div>

                <div>
                  Status:&nbsp;
                  {userData?.subscription?.status === "active"
                    ? "Active"
                    : userData?.subscription?.status === "inactive"
                    ? "InActive"
                    : userData?.subscription?.status === "pending_verification"
                    ? "Pending Verification"
                    : null}
                </div>
              </div>
            </div>
            <Modal show={confirmPauseSubscription} animation={false}>
              <Modal.Body style={{ padding: "12px 18px" }}>
                <div className="nrm-text-reg" style={{ textAlign: "center", padding: "16px 0px" }}>
                  Are you sure you want to {!isSubscriptionEnabled ? "enable" : "disable"} your
                  subscription?
                </div>
                <div className="form-row justify-content-center">
                  <AppButton
                    size="sm"
                    className="secondary m-1"
                    onClick={isSubscriptionEnabled ? pauseSubscription : resumeSubscription}
                  >
                    Confirm
                  </AppButton>
                  <AppButton
                    size="sm"
                    className="primary m-1"
                    onClick={() => setConfirmPauseSubscription(false)}
                  >
                    Cancel
                  </AppButton>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div className="invalid-feedback-text">Please enter your funding card to subscribe</div>
        )}
      </Card>
    </div>
  );
};

export default SubscriptionInfo;

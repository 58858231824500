export const FAQ = [
  {
    question: 'Does Pria work with every credit card?',
    answer: 'Pria is only compatible with unsecured Mastercard and Visa credit cards.',
  },
  {
    question: 'Are Pria transactions reported to the Credit Bureaus?',
    answer:
      'Yes. Pria’s Charge & Pay transaction is a registered expense that is reported to each credit bureau as part of your monthly credit card billing statement. ',
  },
  {
    question: 'Why does Pria only charge $3? Would my credit score increase if Pria charged more?',
    answer:
      'Credit Bureaus assess credit utilization based on a percentage of credit used, not dollar amounts. So charging and paying off $3 is just as effective as charging and paying off $50. Plus, by charging a smaller dollar amount Pria is not inadvertently utilizing credit the cardholder might otherwise need.',
  },
  {
    question:
      'When does Pria charge and pay off registered credit cards? How are credit card bill payments sent?',
    answer:
      'Pria charges registered credit cards on the 28th day of each month at 4pm (EST). Pria sends a credit card bill payment for each registered credit card on the 2nd day of each month at 4pm (EST).Credit card bill payments are sent directly to Visa or Mastercard on the registered card holders behalf.',
  },
  {
    question:
      'Why does Pria charge my credit card just before the end of the month, and send the payment at the beginning of the next month?',
    answer:
      'In order for an expense (such as Pria’s $3 charge) to be reported to Credit Bureaus the charge must be outstanding when the end of month billing statement is generated. Paying off a credit card balance before the billing statement is generated results in the payment not being reported to credit bureaus. Therefore, Pria delays sending a balance payment until the user’s billing statement has been generated.',
  },
  {
    question: 'Does Pria store my credit card information?',
    answer:
      'No. Credit card information is tokenized when entered, meaning Pria only has access to symbolic credit card information.',
  },
  {
    question: 'What makes Pria different from other subscriptions?',
    answer:
      'A Pria subscription improves the subscribers score monthly via Charge & Pay, but also guarantees no missed payments by allowing the subscriber to pre-pay.',
  },
  {
    question:
      'What happens if I don’t have enough available credit on my credit card for the Charge & Pay transaction to process?',
    answer:
      'Pria will only attempt to charge a registered credit card once. If the transaction is declined, Pria will not attempt to charge the registered credit card again until the following month.',
  },
];

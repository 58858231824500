import React from "react";
import "./Signup.scss";
import oceanPNG from "../../assets/img/ocean.jpg";
import AppButton from "common/AppButton";
import { useHistory } from "react-router-dom";
import * as routes from "../../constants/routes";

const Signuppage: React.FC = () => {
  const history = useHistory();
  return (
    <div className="page">
      <main>
        <div className="main-container">
          <div className="overlay">
            <div className="col pb-5">
              <h4>PRIA Logo</h4>
            </div>
            <div className="col  pb-5">
              <h4>Automate your credit card use</h4>
              <div className="nrm-text-reg">
                Build your credit score with automated credit card usage
              </div>
            </div>
            <div className="col">
              <h4>Retire old credit cards</h4>
              <div className="nrm-text-reg">
                We will help you preserve the credit and payment history of your dormant or rarely
                used credit cards
              </div>
            </div>
          </div>
          <div className="form-container">
            <form>
              <h4>Create your account</h4>
              <div className="form-group mb-4 mt-2">
                <label htmlFor="fullName">Full Name</label>
                <input className="form-control" placeholder="Enter your name" type="text" />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">Email</label>
                <input className="form-control" placeholder="Enter your email" type="email" />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="password">Password</label>
                <input
                  className="form-control"
                  placeholder="Enter your parssword"
                  type="password"
                />
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" className="form-check-input" id="terms" />
                <label htmlFor="terms">
                  {" "}
                  {`I agree to Pria's`} <a href="#">Terms of Use</a> {`and`}{" "}
                  <a href="#">Privacy Policy</a>
                </label>
              </div>
              <AppButton
                className="primary w-100 m-0"
                onClick={() => history.push(routes.DASHBOARD)}
              >
                Sign up
              </AppButton>
              <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                <div className="nrm-text-reg col">Already have an account?</div>
                <div className="col">
                  <AppButton
                    className="borderless primary col"
                    onClick={() => history.push(routes.DASHBOARD)}
                  >
                    <h6>Log In</h6>
                  </AppButton>
                </div>
              </div>
              <hr style={{ float: "left", width: "45%" }} />
              <span> &nbsp;&nbsp; OR</span>
              <hr style={{ float: "right", width: "45%" }} />
              <AppButton
                className="primary outlined w-100 m-0"
                onClick={() => history.push(routes.DASHBOARD)}
              >
                Continue with google
              </AppButton>
            </form>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
};

export default Signuppage;

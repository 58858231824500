import http from "./http";
import * as crypto from "crypto";
import * as urls from "constants/urls";
import { ICard } from "types/ICard";
import { encryptMessage } from "./util";

export const postCardAPI = (userId: any, data: ICard, token: string) => {
  const iv = crypto.randomBytes(16).toString("hex").slice(0, 16);
  data = { ...data, number: encryptMessage(data.number, iv) };
  data = { ...data, cvc: encryptMessage(String(data.cvc), iv) };
  data = { ...data, randomBytes: iv };
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.post(`${urls.CARDS}/`, data);
};

export const fetchCardsAPI = (userId: any, token?: string) => {
  // http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.get(`${urls.CARDS}/user/${userId}`);
};

export const removeCardAPI = (cardId: string, token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.delete(`${urls.CARDS}/${cardId}`);
};

export const putCardAPI = (userId: any, data: ICard, token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.put(`${urls.CARDS}/${data.cardId}`, data);
};

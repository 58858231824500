import { useAuth0 } from "@auth0/auth0-react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Switch } from "@mui/material";
import { logoutloader } from "actions/logoutloader";
import AppButton from "common/AppButton";
import AppDropdown from "common/AppDropdown";
import { BellIcon, BillIcon, RedoIcon, SecurityIcon, UserIcon } from "common/AppIcons";
import AppInput from "common/AppInput";
import * as routes from "constants/routes";
import useFetchUser from "hooks/useFetchUser";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { deleteAccountAPI } from "services/users";
import { RootState } from "store";
import { TOGGLE_OFF, TOGGLE_ON } from "types";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import "./Account.scss";
import FundingInfo from "./FundingInfo";
import NotificationSettings from "./NotificationSettings";
import PersonalInfo from "./PersonalInfo";
import SecurityInfo from "./SecurityInfo";
import SubscriptionInfo from "./SubscriptionInfo";

const AccountPage: React.FC<any> = (props) => {
  const personalRef = useRef(null);
  const securityRef = useRef(null);
  const fundingRef = useRef(null);
  const subscriptionRef = useRef(null);
  const notificationRef = useRef(null);

  const dispatch = useDispatch();

  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const toggle = useSelector((state: RootState) => state?.cardToggle?.isOn);

  const refs = useMemo(
    () => [personalRef, securityRef, fundingRef, subscriptionRef, notificationRef],
    [],
  );

  const location = useLocation();
  const history = useHistory();

  const scrollToRef = (ref: any) => {
    window.scrollTo(0, ref?.current?.offsetTop - 30);
  };
  const scrollToPane = useCallback((num: number) => scrollToRef(refs[num]), [refs]);

  const { getAccessTokenSilently, user, logout } = useAuth0();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteWithCode, setConfirmDeleteWithCode] = useState(false);
  const [deleteCode, setDeleteCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleToggle = (e: { target: { checked: any } }) => {
    console.log("e", e.target.checked);
    if (e.target.checked === false) {
      dispatch({ type: TOGGLE_OFF });
    } else {
      dispatch({ type: TOGGLE_ON });
    }
  };
  // const [allCards, setAllCards] = useState<boolean>(false);

  // const handleToggle = () => {
  //   setAllCards(!allCards);
  // };

  // // useEffect(() => {
  // //   if (allCards === false) {
  // //     dispatch({ type: TOGGLE_OFF });
  // //   } else {
  // //     dispatch({ type: TOGGLE_ON });
  // //   }

  // //   // debugger;
  // //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // // }, [allCards]);
  // const dispatchToggle = useMemo(() => {
  //   if (allCards === false) {
  //     return () => dispatch({ type: TOGGLE_OFF });
  //   } else {
  //     return () => dispatch({ type: TOGGLE_ON });
  //   }
  // }, [allCards, dispatch]);

  // useEffect(() => {
  //   dispatchToggle();
  // }, [dispatchToggle]);
  const handleRemoveAccount = async () => {
    try {
      let token = await getAccessTokenSilently();
      setLoading(true);
      if (user && user?.sub) {
        const response = await deleteAccountAPI(user.sub, token);
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          setLoading(false);
          setConfirmDeleteWithCode(true);
        }
        // console.log(response);
      }
    } catch (error) {
      handleError("Error while deleting Account");
    } finally {
      setConfirmDelete(false);
      setLoading(false);
    }
  };

  const handleRemoveAccountWithCode = async () => {
    try {
      let token = await getAccessTokenSilently();
      setLoading(true);
      if (user && user?.sub) {
        const response = await deleteAccountAPI(user.sub, token, deleteCode);
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          setLoading(false);
          handleSuccess("Account Deleted successfully");
          dispatch(logoutloader());
          dispatch({ type: "LOG_OUT" });
          logout({ returnTo: window.location.origin });
        }
        // console.log(response);
      }
    } catch (error) {
      handleError("Error while deleting Account");
    } finally {
      setConfirmDeleteWithCode(false);
      setConfirmDelete(false);
      setLoading(false);
    }
  };

  const renderConfirmDeleteModal = () => {
    return (
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)} animation={false}>
        <Modal.Body style={{ padding: "12px 18px" }}>
          <div className="nrm-text-reg" style={{ textAlign: "center", padding: "16px 0px" }}>
            Are you sure you want to delete your account?
            <label htmlFor="info">
              {loading && `A code will be sent to the phone number linked to this account`}
            </label>
          </div>

          <div className="form-row justify-content-center">
            <AppButton
              size="sm"
              className="secondary m-1"
              onClick={() => handleRemoveAccount()}
              isLoading={loading}
              disabled={loading}
            >
              Confirm
            </AppButton>
            <AppButton size="sm" className="primary m-1" onClick={() => setConfirmDelete(false)}>
              Cancel
            </AppButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderDeleteCodeModal = () => {
    return (
      <Modal
        show={confirmDeleteWithCode}
        onHide={() => setConfirmDelete(false)}
        animation={false}
        border
      >
        <Modal.Body style={{ padding: "12px 18px", alignItems: "center" }}>
          <div className="nrm-text-reg" style={{ padding: "16px 0px" }}>
            Enter the deletion code sent to you phone
          </div>
          <AppInput
            type="text"
            placeholder="Code (xxxx)"
            name="deleteCode"
            onChange={(e: any) => {
              setDeleteCode(e.target.value);
            }}
          />
          <div className="form-row justify-content-center">
            <AppButton
              size="sm"
              className="secondary justify-self-center"
              onClick={() => handleRemoveAccountWithCode()}
              isLoading={loading}
              disabled={loading}
            >
              Confirm
            </AppButton>
            <AppButton
              size="sm"
              className="primary ml-1"
              onClick={() => setConfirmDeleteWithCode(false)}
            >
              Cancel
            </AppButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const scrollToPath = useCallback(() => {
    const pathname = location.pathname;

    if (sidebar) return;

    if (pathname.includes("personalinfo")) {
      scrollToPane(0);
    } else if (pathname.includes("security")) {
      scrollToPane(1);
    } else if (pathname.includes("payment")) {
      scrollToPane(2);
    } else if (pathname.includes("subscription")) {
      scrollToPane(3);
    } else if (pathname.includes("notifications")) {
      scrollToPane(4);
    } else {
      scrollToPane(0);
    }
  }, [location.pathname, scrollToPane, sidebar]);

  useEffect(() => {
    scrollToPath();
  }, [scrollToPath]);

  useFetchUser();

  const Jumper = () => {
    return (
      <div className="jumper">
        <AppDropdown
          placeholder="Jump To"
          style={{ width: "100%" }}
          labelStyle={{ color: "#bdbdbd" }}
          menuStyle={{ margin: "0.5rem 0.5rem", width: "100%" }}
        >
          <a
            className="dropdown-item"
            key="personal_info"
            onClick={() => {
              scrollToPane(0);
              history.replace(`${routes.ACCOUNT}/personalinfo`);
            }}
            style={{ height: "2rem" }}
          >
            <UserIcon /> Personal Info
          </a>
          <a
            className="dropdown-item"
            key="sec_info"
            onClick={() => {
              scrollToPane(1);
              history.replace(`${routes.ACCOUNT}/security`);
            }}
            style={{ height: "2rem" }}
          >
            <SecurityIcon /> Security
          </a>
          <a
            className="dropdown-item"
            key="funding_info"
            onClick={() => {
              scrollToPane(2);
              history.replace(`${routes.ACCOUNT}/payment`);
            }}
            style={{ height: "2rem" }}
          >
            <BillIcon /> Funding
          </a>
          <a
            className="dropdown-item"
            key="subs_info"
            onClick={() => {
              scrollToPane(3);
              history.replace(`${routes.ACCOUNT}/subscription`);
            }}
            style={{ height: "2rem" }}
          >
            <RedoIcon />
            Subscription
          </a>
          <a
            className="dropdown-item"
            key="nots_info"
            onClick={() => {
              scrollToPane(4);
              history.replace(`${routes.ACCOUNT}/notifications`);
            }}
            style={{ height: "2rem" }}
          >
            <BellIcon />
            Notification
          </a>
        </AppDropdown>
      </div>
    );
  };

  return (
    <>
      {/* {sidebar && <Sidebar />} */}
      {renderConfirmDeleteModal()}
      {renderDeleteCodeModal()}

      <div className="account-page">
        <Jumper />
        <div className="sidemenu">
          <ul>
            <li
              onClick={() => {
                scrollToPane(0);
                history.replace(`${routes.ACCOUNT}/personalinfo`);
              }}
            >
              <UserIcon /> Personal Info
            </li>
            <li
              onClick={() => {
                scrollToPane(1);
                history.replace(`${routes.ACCOUNT}/security`);
              }}
            >
              <SecurityIcon />
              Security
            </li>
            <li
              onClick={() => {
                scrollToPane(2);

                history.replace(`${routes.ACCOUNT}/payment`);
              }}
            >
              <BillIcon /> Funding
            </li>
            <li
              onClick={() => {
                scrollToPane(3);
                history.replace(`${routes.ACCOUNT}/subscription`);
              }}
            >
              <RedoIcon />
              Subscription
            </li>
            <li
              onClick={() => {
                scrollToPane(4);
                history.replace(`${routes.ACCOUNT}/notifications`);
              }}
            >
              <BellIcon />
              Notification
            </li>
          </ul>
        </div>
        <div className="forms-container">
          <div className="form-container" ref={refs[0]}>
            <PersonalInfo reference0={personalRef} />
          </div>

          <div className="form-container" ref={refs[1]}>
            <SecurityInfo reference={securityRef} />
          </div>

          <div className="form-container" ref={refs[2]}>
            <FundingInfo reference1={fundingRef} />
          </div>

          <div className="form-container" ref={refs[3]}>
            <SubscriptionInfo reference2={subscriptionRef} />
          </div>
          <div className="form-container" style={{ marginTop: "3rem" }}>
            <Card className="appCard">
              <div className="switch-position">
                <div>Show all cards</div>
                <div>
                  <Switch
                    checked={toggle}
                    onChange={handleToggle}
                    name="Toggle"
                    inputProps={{ "aria-label": "show all cards" }}
                  />
                </div>
              </div>
            </Card>
          </div>

          <div className="form-container" ref={refs[4]}>
            <NotificationSettings reference3={notificationRef} />
          </div>

          <div className="form-container">
            {/* <UserCircleIcon style={{width:"2rem"}} /> */}
            <div className="delete-ac">
              <h5>Delete Account</h5>
            </div>
            <Box
              className="appCard md-text-reg "
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Do you want to delete your account
              <DeleteOutlineIcon style={{ color: "red" }} onClick={() => setConfirmDelete(true)} />
            </Box>

            {/* <AppButton
              id="delete-btn"
              className="tertiary outlined pr-4 pl-4"
              onClick={() => setConfirmDelete(true)}
            >
              Delete Account
            </AppButton> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AccountPage;

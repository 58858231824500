import jwtDecode from "jwt-decode";
import { fetchUserAPI, updateUserAPI } from "services/users";
import { IAction } from "types";
import {
  FETCH_USER_ERROR,
  FETCH_USER_REQUEST,
  FETCH_USER_RESET,
  FETCH_USER_SUCCESS,
  IUser,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,
} from "types/IUser";
import { handleError } from "utils/handleError";
import { checkResponseStatus } from "utils/handleSuccess";

export interface IUserAction extends IAction {
  type: string;
  payload?: any;
}

export const fetchUsersRequestAction = (): IUserAction => ({
  type: FETCH_USER_REQUEST,
});

export const fetchUserSuccessAction = (user: any): IUserAction => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserErrorAction = (error: string): IUserAction => ({
  type: FETCH_USER_ERROR,
  payload: error,
});

export const fetchUserResetAction = (): IUserAction => ({
  type: FETCH_USER_RESET,
});

export const updateUsersRequestAction = (): IUserAction => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserSuccessAction = (): IUserAction => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateUserErrorAction = (error: string): IUserAction => ({
  type: UPDATE_USER_ERROR,
  payload: error,
});
export const updateUserResetAction = (): IUserAction => ({
  type: UPDATE_USER_RESET,
});
export const fetchUser = (dispatch: any) => (userId: any, token: string) => {
  dispatch(fetchUsersRequestAction());
  fetchUserAPI(userId, token)
    .then((response) => {
      // console.log(response);

      dispatch({ type: "UPDATE_TOKEN", payload: token });

      //decode and set permissions
      const decoded = jwtDecode(token) as any;
      const roles = decoded?.["https://priafinancial.com/roles"] || [];
      const isAdmin = roles.includes("admin");
      const permissionList = decoded?.permissions || [];
      const permissions = permissionList?.reduce(function (acc: any, cur: string) {
        acc[cur] = cur;
        return acc;
      }, {});

      try {
        if (response.data && checkResponseStatus(response.status)) {
          const user = response?.data?.data;
          dispatch(fetchUserSuccessAction({ user, isAdmin, permissionList, permissions }));
        } else {
          dispatch(fetchUserErrorAction(response.data?.message));
        }
      } catch (error) {
        console.log(error);
      }
    })
    .catch((err) => {
      const errorMsg = err?.message;
      dispatch(fetchUserErrorAction(errorMsg));
    })
    .finally(() => {
      dispatch(fetchUserResetAction());
    });
};

export const updateUser = (userId: any, data: IUser, token: string) => {
  return (dispatch: any) => {
    dispatch(updateUsersRequestAction());
    updateUserAPI(userId, data, token)
      .then((response) => {
        if (response.data && checkResponseStatus(response.data.httpCode)) {
          dispatch(updateUserSuccessAction());
        } else {
          dispatch(updateUserErrorAction(response.data?.error[0]));
        }
      })
      .catch((err) => {
        const errorMsg = err?.response?.data?.message;
        console.log(err);

        handleError(errorMsg);
        dispatch(updateUserErrorAction(errorMsg));
      })
      .finally(() => {
        fetchUser(dispatch)(userId, token);
      });
  };
};

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

const useGetAccessToken = () => {
  const auth0 = useAuth0();
  const { data: token } = useQuery(["getToken", auth0], () => auth0.getAccessTokenSilently());
  return token;
};

export default useGetAccessToken;

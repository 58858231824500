export const FETCH_ACTIVITY = "/activity";
export const CARDS = "/cards";
export const PLANS = "/plans";
export const USER = "/customers";
export const TRANSACTION = "/transactions";
export const MFA = "/mfa";
export const STATE = "/administrative-areas";
export const CYBERSOURCE = "/cybersource";
export const SETTING = "/setting/onboarding-index";
export const SUBSCRIPTIONS = "/subscriptions";

import { IVerticalCardCarousel } from "actions/verticalCarousel";
import { VERTICAL_CARD_CAROUSEL } from "../types/IVerticalCardCarousel";
type VERTICAL_CAROUSEL_STATE = {
  vertical_carousel: boolean;
};

const INITIAL_STATE: VERTICAL_CAROUSEL_STATE = {
  vertical_carousel: false,
};

export default (state: VERTICAL_CAROUSEL_STATE = INITIAL_STATE, action: IVerticalCardCarousel) => {
  switch (action.type) {
    case VERTICAL_CARD_CAROUSEL: {
      // console.log(state.vertical_carousel);
      return { ...state, vertical_carousel: true };
    }
    default:
      return state;
  }
};

import { CSSObject, Pagination, Stack, Typography } from "@mui/material";
import React from "react";
import { useFilter } from "./FilterOptions";
import style from "./dTable.module.scss";

export interface TablePaginationProps {
  totalResults?: number;
  totalPages?: number;
}

const pginationContainerStyles: CSSObject = {
  justifyContent: "flex-end",
  alignItems: "center",
};

const size = [10, 25, 50];

const TablePagination: React.FC<TablePaginationProps> = (props) => {
  const { totalResults, totalPages = 1 } = props;
  const { filterOptions, setPage, setLimit } = useFilter();

  const handlePageChange = (e: unknown, page: number) => setPage(page);
  const handleLimitChange = (e: any) => {
    const value = e.target.value;
    setLimit(value);
  };

  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        sx={pginationContainerStyles}
        flexWrap={"wrap"}
        p={{ md: 2 }}
      >
        <Typography sx={{ flex: 1 }} component="span">
          {totalResults} Results
        </Typography>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>Show: </Typography>
          <fieldset className={style.pagination_size_fieldset}>
            <select
              className={style.pagination_size}
              onChange={handleLimitChange}
              value={filterOptions?.limit || 10}
            >
              {size.map((item, index) => (
                <option className={style.pagination_size_option} key={`size_${index}`} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </fieldset>
        </Stack>

        <Pagination
          page={filterOptions.page || 1}
          count={totalPages}
          siblingCount={1}
          boundaryCount={1}
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
};

export default React.memo(TablePagination);

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect({
      screen_hint: "signup",
    });
  }, [loginWithRedirect]);

  return <></>;
};

export default Login;

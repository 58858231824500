import { VERTICAL_CARD_CAROUSEL } from "types/IVerticalCardCarousel";

export interface IVerticalCardCarousel {
  type: string;
}

// export const verticalOrCarousel = (payload: boolean): IVerticalCardCarousel => ({
//   type: VERTICAL_CARD_CAROUSEL,
//   payload: payload,
// });

export const verticalOrCarousel = () => {
  return (dispatch: any) => {
    dispatch({
      type: VERTICAL_CARD_CAROUSEL,
    });
  };
};

export interface IPerCardClick {
  type: string;
}

import { PER_CARD_CLICK } from "types/IVerticalCardCarousel";

export const perCardClick = () => {
  return (dispatch: any) => {
    dispatch({
      type: PER_CARD_CLICK,
    });
  };
};

import React, { MutableRefObject } from 'react';
import './appIcon.scss';

interface IAppIconProps {
  onClick?: any;
  style?: any;
  ref?: MutableRefObject<any>;
}

export const UserCircleIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-user-circle" {...props}></span>;
};

export const LogoutIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-signout" {...props}></span>;
};

export const UserIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-user" {...props}></span>;
};

export const SecurityIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-shield" {...props}></span>;
};

export const BillIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-bill" {...props}></span>;
};

export const RedoIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-redo" {...props}></span>;
};

export const FundingIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-money-bill" {...props}></span>;
};

export const CreditCardIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-credit-card" {...props}></span>;
};

export const BellIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-bell" {...props}></span>;
};

export const PlusIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-plus" {...props}></span>;
};

export const MinusIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-minus" {...props}></span>;
};

export const BackIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return <span className="iconify" data-icon="uil-angle-left" {...props}></span>;
};
export const PayOutIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return (
    <span
      className="iconify"
      data-icon="uil-arrow-up-right"
      style={{ color: 'red' }}
      {...props}
    ></span>
  );
};

export const PayInIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return (
    <span
      className="iconify"
      data-icon="uil-arrow-down-left"
      style={{ color: 'green' }}
      {...props}
    ></span>
  );
};

export const PauseIcon: React.FC<IAppIconProps> = (props: IAppIconProps) => {
  return (
    <span
      className="iconify"
      data-icon="uil-pause"
      // title="Pause subscription"
      {...props}
    ></span>
  );
};

import { toggleWillDoLaterAction } from "actions/utils";
import AppButton from "common/AppButton";
import * as routes from "constants/routes";
import { FetchUserProvider } from "hooks/useFetchUser";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import { RootState } from "store";
import "./OnBoarding0.scss";
//import Dashboard from 'components/Dashboard';

function OnBoarding0() {
  let history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);

  return (
    <div className="onboarding-space">
      <h2 className="onboarding-text">Welcome to Pria</h2>
      <div className="onboarding-text-1">
        Before you can start we will need to set up your account.
      </div>
      <div className="onboarding-btn">
        <div>
          <AppButton
            className={" app-btn"}
            onClick={() => {
              history.push(routes.ONBOARDING1); //  onClick={() => history.push(routes.SUBSCRIPTION)}
            }}
          >
            <div className="app-btntxt">
              {userState.user.onBoardingIndex === 0 ? `Get Started` : `Continue where you left off`}
            </div>
          </AppButton>
        </div>
      </div>

      {/* <Link to='/'>
            <div className="onboarding-text-2">I will do this later</div>
          </Link> */}
      <div className="onboarding-text-2">
        <AppButton
          className="borderless primary"
          onClick={() => {
            dispatch(toggleWillDoLaterAction());
            history.push(routes.DASHBOARD);
          }}
        >
          I will do this later
        </AppButton>
      </div>

      {/* <Route path={routes.DASHBOARD} component={Dashboard} /> */}
    </div>
  );
}

export default OnBoarding0;

import axios from "axios";
import { auth0 } from "index";
import { store } from "store";

const fallback = process.env.REACT_APP_API_URL;

export const API_URL = fallback;

const headerDict = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Headers": "Content-Type",
};

const requestOptions = {
  headers: new Headers(headerDict),
};

// reactEnv === "development" && console.log(process.env.REACT_APP_ENV, process.env.NODE_ENV);

const http = axios.create({
  baseURL: API_URL,
  headers: requestOptions,
});

http.interceptors.request.use(
  async (config) => {
    const token = store.getState().user.token;

    if (!token) {
      return Promise.reject("Invalid Token");
    }

    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    const originalRequest = error.config;
    console.log(originalRequest, error.response.message, "Wabalabsdubdufb");
    return Promise.reject(error);
  },
);

http.interceptors.response.use(undefined, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;

    const token = await auth0.getTokenSilently();

    if (token) {
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
      store.dispatch({ type: "UPDATE_TOKEN", payload: token });
      return axios(originalRequest);
    } else {
      return Promise.reject(error);
    }
  }
});

export default http;

import Footer from "components/Footer";
import Navmenu from "components/NavMenu/Navmenu";
import { useAuth0 } from "@auth0/auth0-react";
import { isNull } from "lodash";
import AppNavbar from "components/Navbar";
import "./policy.scss";

import React, { createRef, useEffect, useRef, useState } from "react";
import Sidebarhome from "components/SidebarHome/Sidebarhome";
import Sidebar from "components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "store";

const PrivacyPolicy: React.FC = () => {
  let [scrollRefs, setScrollRefs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollRefs((refs) =>
      Array(22)
        .fill(null)
        .map((_, i) => refs[i] || createRef()),
    );
  }, []);
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop - 100);

  const executeScroll = (num: number) => scrollToRef(scrollRefs[num]);
  const { user, isAuthenticated } = useAuth0();
  return (
    <>
      <div className="policy-page">
        {!isAuthenticated ? <Navmenu /> : <AppNavbar />}
        { !isAuthenticated && sidebar && <Sidebar />}

        <div className="policy">
          <h3>Privacy Policy</h3>
          <div className="md-text-bold">Last Updated: January 1, 2022</div>
          &nbsp; &nbsp;
          <p style={{ textAlign: "justify" }}>
            This Privacy Policy (the “Policy”) explains how Pria Inc., (“Company,” “we,” “our,” or
            “us”) collects, stores, uses, shares and protects your Personal Information, which we
            obtain when you visit our websites or use our services (collectively, the “Services”).
            The Services are more fully described in the Terms of Use, and such description is
            incorporated therein. You accept this Policy and all of its terms whenever you access or
            use the Services.
          </p>
          <p style={{ textAlign: "justify" }}>
            Remember that your use of our Services is at all times subject to our Terms of Service,
            which incorporates this Policy. Any terms we use in this Policy without defining them
            have the definitions given to them in the Terms of Service.
          </p>
          <p style={{ textAlign: "justify" }}>
            Our Services are intended for a U.S. audience. If you visit or use one of our Services,
            your information may be transferred or processed in the United States. We currently do
            not offer products or services to non-U.S. customers.
          </p>
          <p style={{ textAlign: "justify" }}>
            For purposes of this Policy, “Personal Information” means any information that
            identifies or relates to a particular individual and also includes information you
            provide to us. We may create aggregated, de-identified or anonymized data from the
            Personal Information we collect, including by removing information that makes the data
            personally identifiable to a particular member. We may use such aggregated,
            de-identified or anonymized data and share it with third parties for our lawful business
            purposes, including to analyze, build and improve the Services and promote our business,
            provided that we will not share such data in a manner that could identify you. Such
            aggregated, de-identified or anonymized data is not considered Personal Information.
            This Policy applies to Personal Information that we gather or collect via our Services.
            This Policy does not apply to any Personal Information collected by third-party websites
            not operated by us.
          </p>
          <p style={{ textAlign: "justify" }}>
            You may also email us at{" "}
            <a href="https:/gmail.com" target="_blank" rel="noreferrer">
              supportl@priafinancial.com{" "}
            </a>
            or call us at [000-000-0000] with any privacy-related questions you have.
          </p>
          <h5>TABLE OF CONTENTS</h5>
          <ol>
            <li onClick={() => executeScroll(0)}>THE INFORMATION WE COLLECT</li>
            <li onClick={() => executeScroll(1)}>HOW WE USE YOUR INFORMATION</li>
            <li onClick={() => executeScroll(2)}>SHARING YOUR PERSONAL INFORMATION</li>
            <li onClick={() => executeScroll(3)}>THIRD-PARTY TRACKING AND ONLINE ADVERTISING</li>
            <li onClick={() => executeScroll(4)}>HOW LONG WE KEEP YOUR INFORMATION</li>
            <li onClick={() => executeScroll(5)}>LINKS TO OTHERS&apos; SITES AND SERVICES</li>
            <li onClick={() => executeScroll(6)}>SOCIAL MEDIA</li>
            <li onClick={() => executeScroll(7)}>HOW WE STORE YOUR PERSONAL INFORMATION</li>
            <li onClick={() => executeScroll(8)}>KEEING YOUR INFORMATION SAFE</li>
            <li onClick={() => executeScroll(9)}>YOUR CHOICES</li>
            <li onClick={() => executeScroll(10)}>CHANGES TO THIS PRIVACY POLICY</li>
            <li onClick={() => executeScroll(11)}>CHILDREN&apos;S PRIVACY</li>
            <li onClick={() => executeScroll(12)}>CALIFORNIA &quot;SHINE THE LIGHT&quot; LAW</li>
            <li onClick={() => executeScroll(13)}>YOUR NEVADA RIGHTS</li>
            <li onClick={() => executeScroll(13)}>CONTACT US</li>
          </ol>
          <h5 ref={scrollRefs[0]}>1. The Information We Collect:</h5>
          <p style={{ textAlign: "justify" }}>
            There are three basic categories of information we collect:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>Personal Information you provide to use;</li>
            <li style={{ textAlign: "justify" }}>Automatically collected data; and</li>
            <li style={{ textAlign: "justify" }}>Information we get from third parties</li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            Personal Information You Provide to Us. We may collect Personal Information that you
            choose to share with us. For example, when you set up an account with us or any partner,
            we need to collect important details about you, such as your name, phone number, date of
            birth, unique username, location, social security number and email address. We may also
            collect any communications between you and Company and any other information you provide
            to Company. We also collect Personal Information when you register for or inquire about
            our Services and when you use our Services. You also may submit User Content through the
            Service for us to share with credit-reporting agencies and other third parties in
            support of the Service.
          </p>
          <p style={{ textAlign: "justify" }}>
            Automatically Collected Data. We, and our third-party partners, automatically collect
            certain Personal Information when you visit our website, read our emails, access or use
            our Services or otherwise engage with us. This information is typically collected
            through a variety of tracking technologies, including cookies, web beacons, embedded
            scripts, location-identifying technologies, file information and other technology
            (collectively, “Tracking Technologies”). We, and our third-party partners, use these
            Tracking Technologies and other tools to automatically collect usage and device
            information, such as:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              Your IP address, location, browser type, Internet service provider, platform type,
              device type, operating system, date and time stamp, a unique ID that allows us to
              uniquely identify your browser, mobile device or your account, your internet service
              provider and other such information.
            </li>
            <li style={{ textAlign: "justify" }}>
              We also collect information about the way you use or access our Services, for example,
              the site from which you came and the site to which you are going when you leave our
              website, the pages you visit, the links you click, how frequently you access the
              Services, whether you open emails or click the links contained in e-mails, whether you
              access the Services from multiple devices and other actions you take on the Services.
            </li>
            <li style={{ textAlign: "justify" }}>
              Your IP address, location, browser type, Internet service provider, platform type,
              device type, operating system, date and time stamp, a unique ID that allows us to
              uniquely identify your browser, mobile device or your account, your internet service
              provider and other such information.
            </li>
            <li style={{ textAlign: "justify" }}>
              When you access our Services from a mobile device, we may collect unique
              identification numbers associated with your device or our mobile application
              (including, for example, a UDID, Unique ID for Advertisers (“IDFA”), Google AdID or
              Windows Advertising ID), mobile carrier, device type, model and manufacturer, mobile
              device operating system brand and model, phone number and, depending on your mobile
              device settings, your geographical location data, including GPS coordinates (e.g.,
              latitude and/or longitude) or similar information regarding the location of your
              mobile device, or we may be able to approximate a device’s location by analyzing other
              information, like an IP address.
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            Do-Not-Track Signals. We are unable to respond to Do Not Track signals sent by your
            browser at this time. If you would prefer not to accept cookies, most browsers will
            allow you to:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              change your browser settings to notify you when you receive a cookie, which lets you
              choose whether or not to accept it;
            </li>
            <li style={{ textAlign: "justify" }}>disable existing cookies; or</li>
            <li style={{ textAlign: "justify" }}>
              et your browser to automatically reject cookies. Depending on your mobile device and
              operating system, you may not be able to delete or block all cookies, you may not be
              able to block non-cookie technologies and the browser settings that block cookies may
              have no effect on such techniques. You may also set your email options to prevent the
              automatic downloading of images that may contain technologies that would allow us to
              know whether you have accessed your email and perform certain functions with it.
              Deleting cookies does not delete Local Storage Objects (LSOs) such as Flash objects
              and HTML5. You can learn more about Flash objects—including how to manage privacy and
              storage settings for Flash cookies—on Adobe’s website. If you choose to delete Flash
              objects from our sites, then you may not be able to access and use all or part of the
              sites or benefit from the information and services offered.
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            Information We Get from Third Parties. We may collect Personal Information about you
            from other users, our affiliates and third parties, as permitted by law. For example, we
            may collect information from third-party verification services, credit bureaus,
            financial institutions, mailing-list providers, landlords, telecommunication providers,
            merchants with which you may transact and publicly available sources and databases, for
            purposes described below. We may also collect information about you from third parties
            in connection with any identity- or account-verification process, fraud-detection
            process, account payments with third parties with whom you do business, collection
            procedures, credit-reporting purposes or as may otherwise be required or permitted by
            applicable law.
          </p>
          <h5 ref={scrollRefs[1]}>How We Use Your Information:</h5>
          <p style={{ textAlign: "justify" }}>
            To provide the Services, we may use your Personal Information to fulfill requests for
            products, Services or information, including information about potential or future
            Services, including to:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              Create and process an account for you and deliver the Services to you and manage
              individual information and accounts;
            </li>
            <li style={{ textAlign: "justify" }}>
              Respond to questions, comments and other requests;
            </li>
            <li style={{ textAlign: "justify" }}>
              Send you information, including confirmations, invoices, technical notices, product
              and service information, updates, security alerts, support and administrative messages
              and information about information submitted to us via the Services;
            </li>
            <li style={{ textAlign: "justify" }}>
              Provide access to certain areas, functionalities and features of our Services;
            </li>
            <li style={{ textAlign: "justify" }}>
              Communicate with you, such as to respond to your comments and questions, provide
              customer service or feedback, or respond to employment opportunities for which you’ve
              applied, of for any other purposes in connection with the Services;
            </li>
            <li style={{ textAlign: "justify" }}>
              Facilitate any transaction requested by you; and
            </li>
            <li style={{ textAlign: "justify" }}>
              Allow you to register for additional services offered by us or our partners.
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            We may use Personal Information for internal-business purposes, including to:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              Conduct research and analytics to understand our visitors and customers, and tailor
              our product offerings;
            </li>
            <li style={{ textAlign: "justify" }}>
              Operate, maintain, test, enhance, update, monitor, diagnose, fix and improve our
              Service;
            </li>
            <li style={{ textAlign: "justify" }}>
              Develop new products and Services or improve existing products and Services;
            </li>
            <li style={{ textAlign: "justify" }}>Ensure internal quality control;</li>
            <li style={{ textAlign: "justify" }}>
              Communicate about individual accounts and activities on our Services and systems and,
              in our discretion, changes to any our policy;
            </li>
            <li style={{ textAlign: "justify" }}>Comply with regulatory and legal requirements;</li>
            <li style={{ textAlign: "justify" }}>
              Prevent, investigate or provide notice of fraud or unlawful or criminal activity, and
              enforce and carry out contracts and agreements between us and you;
            </li>
            <li style={{ textAlign: "justify" }}>
              Protect the safety of any person, to address fraud, security or technical issues, or
              to protect our rights or property; and
            </li>
            <li style={{ textAlign: "justify" }}>
              For any other purpose for which you have given express permission or consent to us.
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            For marketing purposes, we may analyze and use Personal Information to provide you with
            materials about offers, products and services that may be of interest, including new
            content or services. We may provide you with these materials by phone, postal mail or
            email, as permitted by applicable law. Such uses include:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              To provide you with, and to tailor, content, advertisements and offers;
            </li>
            <li style={{ textAlign: "justify" }}>
              To notify you about offers, products and services that may be of interest to you;
            </li>
            <li style={{ textAlign: "justify" }}>
              To provide Services to you and our partners and affiliates;
            </li>
            <li style={{ textAlign: "justify" }}>
              For other purposes disclosed at the time you provide Personal Information;
            </li>
            <li style={{ textAlign: "justify" }}>
              Facilitate contests, promotions and rewards and provide you with news about products
              and services offered by us and our selected partners; and
            </li>
            <li style={{ textAlign: "justify" }}>Otherwise with your consent.</li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            Non-personal Information. We may also use non-personally identifiable information that
            does not personally identify you, including by aggregating and/or de-identifying
            Personal Information, in order to understand better how our visitors use the Services,
            research our visitors demographics, interests and behavior, improve the Services and
            provide visitors with customized services and information, as well as for other similar
            purposes. We may share this information with others for informational or promotional
            purposes, and we may use non-personally identifiable information in any manner permitted
            by law. When any non-personally identifiable information is combined with other
            information that identifies you, it will be treated as Personal Information in
            accordance with this Policy.
          </p>
          <h5 ref={scrollRefs[2]}>Sharing of Your Personal Information:</h5>
          <p style={{ textAlign: "justify" }}>
            We may share your Personal Information in the instances described below.
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              To verify your identity. For example, we may share information about you with
              credit-reporting agencies, financial institutions or government agencies to verify
              your identity or to otherwise establish eligibility for a product or our Services;
            </li>
            <li style={{ textAlign: "justify" }}>
              To report to third parties, including credit bureaus, all in accordance with the Terms
              of Service;
            </li>
            <li style={{ textAlign: "justify" }}>
              With vendors and service providers for: (i) provision of IT and related services; (ii)
              provision of information and Services you have requested; (iii) customer service
              activities; (iv) fraud prevention; (v) analytics services; and/or (vi) in connection
              with the provision of the Services;
            </li>
            <li style={{ textAlign: "justify" }}>
              With our business partners and affiliates and for our affiliates’ internal business
              purposes or to provide you with a product or service that you have requested. We may
              also share Personal Information with business partners with whom we may jointly offer
              products or services, or whose products or services we believe may be of interest to
              you;
            </li>
            <li style={{ textAlign: "justify" }}>
              With third parties for legal reasons. We may share information about you if we
              reasonably believe that disclosing the information is needed to: (i) comply with any
              valid legal process, governmental request, or applicable law, rule or regulation; (ii)
              investigate, remedy or enforce potential Terms of Service Violations; (iii) protect
              the rights, property and safety of us, our users or others; or (iv) detect and resolve
              any fraud or security concerns;
            </li>
            <li style={{ textAlign: "justify" }}>
              With third parties as part of a merger or acquisition. Your Personal Information may
              also be one of the transferred assets as part of a merger, acquisition or bankruptcy;
            </li>
            <li style={{ textAlign: "justify" }}>
              In the case a third party with whom you have an account or with which you have
              otherwise engaged in a transaction shares information about you with us, we may share
              limited Personal Information with such third party so that they can verify the
              information provided to us or to otherwise correct any inaccurate information reported
              to a credit-reporting agency or any similar party;
            </li>
            <li style={{ textAlign: "justify" }}>
              When you provide feedback or provide ideas, your information (e.g., your first name
              and comments) may be displayed on our websites or on our social media pages. When you
              engage with us on social media, we may tag your social media account or the social
              media account of others (e.g., to give photo credit to another user); and
            </li>
            <li style={{ textAlign: "justify" }}>
              As otherwise permitted by law, including the Gramm-Leach-Bliley Act and the Fair
              Credit Reporting Act.
            </li>
          </ul>
          <h5 ref={scrollRefs[3]}>Third-Party Tracking and Online Advertising:</h5>
          <p style={{ textAlign: "justify" }}>
            We may participate in interest-based advertising and use third-party advertising
            companies to serve you targeted advertisements based on your browsing history or other
            information. We may share or we may permit third-party services to collect information
            about your use of our website over time so that they may play or display ads on our
            website or online platform in connection with our Service, on other devices you may use
            and on other websites, apps or services. Typically, though not always, the information
            we share is provided through cookies or similar tracking technologies, which recognize
            the device you’re using and collect information, including clickstream information,
            browser type, time and date you visited the site and other information. A cookie is a
            piece of information that the computer that hosts our Services gives to your browser
            when you access the Services. Our cookies help provide additional functionality to the
            Services, including helping us analyze Services usage more accurately. For instance, the
            Company Site may set a cookie on your browser that allows you to access the Services
            without needing to remember and then enter a password more than once during a visit to
            the Company Site. We and our third-party partners use this information to make the
            advertisements you see online more relevant to your interests, as well as to provide
            advertising-related services such as reporting, attribution, analytics and market
            research. We, or our third-party partners, may link your various devices so that content
            you see on one device can result in relevant advertising on another device. We do this
            by collecting information about each device you use when you are logged in to our
            Services. We may also work with third-party partners that employ tracking technologies
            or the application of statistical modeling tools to determine if two or more devices are
            linked to a single user or household. We may share a common account identifier (such as
            an email address or user ID) with third-party advertising partners to help recognize you
            across devices. We, and our partners, can use this cross-device linkage to serve
            interest-based advertising and other personalized content to you across your devices, to
            perform analytics and to measure the performance of our advertising campaigns. We may
            use Google Analytics to recognize you and link the devices you use when you visit our
            site or Service on your browser or mobile device, log in to your account on our Service
            or otherwise engage with us. We may share a unique identifier, like a user ID or hashed
            email address, with Google to facilitate the Service. Google Analytics allows us to
            better understand how our users interact with our Service and to tailor our
            advertisements and content to you. For information on how Google Analytics collects and
            processes data, as well as how you can control information sent to Google, review
            Google’s site “How Google uses data when you use our partners’ sites or apps’’ located
            at www.google.com/policies/privacy/partners. You can learn about Google Analytics’
            currently available opt-outs, including the Google Analytics Browser Ad-On, here:
            https://tools.google.com/dlpage/gaoptout. Please review the section title ‘Your choices’
            below to understand your choices regarding third- party tracking and online advertising.
          </p>
          <h5 ref={scrollRefs[4]}>How Long We Keep Your Information:</h5>
          <p style={{ textAlign: "justify" }}>
            We will retain your information for as long as you use our Services to resolve disputes,
            establish legal defenses, conduct audits, enforce our agreements and comply with
            applicable laws or as long as we otherwise deem appropriate. We also maintain your
            Personal Information as part of our standard backup procedures.
          </p>
          <h5 ref={scrollRefs[5]}>Links to Others’ Sites and Services:</h5>
          <p style={{ textAlign: "justify" }}>
            Our Services may contain links to other websites, and other websites may reference or
            link to our Services. These websites are operated by third parties not controlled by us
            and, by linking to them or being linked from them, we do not endorse, approve or make
            any representations about third-party websites or domains. We encourage you to read the
            privacy policies of each and every website and domain with which you interact. We are
            not responsible for the privacy practices or content of such other websites or domains
            and you visit them at your own risk.
          </p>
          <h5 ref={scrollRefs[6]}>Social Media:</h5>
          <p style={{ textAlign: "justify" }}>
            Our Services may contain links and interactive features with various social media
            platforms. If you already use these platforms, their cookies may be set on your device
            when using our Services. You should be aware that Personal Information that you
            voluntarily include and transmit online in a publicly accessible social media platform,
            blog or chat room or otherwise online, or that you share in an open forum, may be viewed
            and used by others without any restrictions. We have no control over such uses of your
            information when interacting with social media platformsor forums or otherwise online
            and by using such services you assume the risk that the Personal Information provided by
            you may be viewed and used by third parties.
          </p>
          <h5 ref={scrollRefs[7]}>How We Store Your Personal Information:</h5>
          <p style={{ textAlign: "justify" }}>
            Your information collected through our Service may be stored and processed in the United
            States or any other country in which the Company or its parent, subsidiaries, affiliates
            or service providers maintain facilities or otherwise store information. If you are
            located in other regions with laws governing data collection and use that may differ
            from U.S. law, please note that we may transfer information, including personal
            information, to a country and jurisdiction that does not have the same data protection
            laws as your jurisdiction. In addition, information collected about you may be stored
            using cloud-based services. Information stored in the cloud may be maintained by
            third-party networks in locations in which such third-party networks operate or maintain
            data storage or processing facilities.
          </p>
          <h5 ref={scrollRefs[8]}>Keeping Your Information Safe:</h5>
          <p style={{ textAlign: "justify" }}>
            We care about the security of your information and may employ physical, administrative
            and technological safeguards designed to preserve the integrity and security of all
            information collected through our Services. However, no security system is impenetrable,
            and we cannot guarantee the security of our systems 100%. In the event that any
            information under our control is compromised as a result of a breach of security, we
            will take reasonable steps to investigate the situation and, where required by law,
            notify those individuals whose information may have been compromised and take other
            steps, in accordance with any applicable laws and regulations.
          </p>
          <h5 ref={scrollRefs[9]}>Your Choices:</h5>
          <p style={{ textAlign: "justify" }}>
            If you receive commercial emails from us, you may unsubscribe at any time by following
            the instructions contained within the email. You may also opt-out from receiving
            commercial email from us by sending us an email or by writing to us at the email address
            given at the end of this policy. You will continue to receive transaction-related emails
            regarding products or services you have requested. We may also send you certain
            communications regarding us, and you will not be able to opt out of those communications
            (e.g., communications regarding updates to our Privacy Policy). You have choices
            regarding our use and disclosure of your Personal Information.
          </p>
          <p style={{ textAlign: "justify" }}>
            Depending on your location,Privacy Policy Up-date and subject to applicable law, you may
            have the following rights with regard to the Personal Information we control about you:
            (i) the right to request confirmation of whether we process Personal Information
            relating to you and, if so, to request a copy of that Personal Information; (ii) the
            right to request that we rectify or update your Personal Information that is inaccurate,
            incomplete or outdated; (iii) the right to request that we erase your Personal
            Information in certain circumstances provided by law; (iv) the right to request that we
            restrict the use of your Personal Information in certain circumstances; and (v) the
            right to request that we export to another company, where technically feasible, your
            Personal Information that we hold in order to provide Services to you. Where the
            processing of your Personal Information is based on your previously given consent, you
            have the right to withdraw your consent at any time. To exercise your rights set forth
            in this paragraph, you must contact us at the email address set forth at the end of this
            Privacy Policy. We will comply with your request to the extent required by applicable
            law. We will not be able to respond to a request if we no longer hold your Personal
            Information or we are otherwise not required by law to respond to a request.
          </p>
          <p style={{ textAlign: "justify" }}>
            California law permits residents of California to request certain details about how
            their information is collected, disclosed, and sold to third parties.
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            Under the CCPA, California residents have the right to:
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              Request that a business delete any personal information about the consumer which the
              business has collected from the consumer.
            </li>
            <li style={{ textAlign: "justify" }}>
              Request that a business that collects personal information about the consumer disclose
              to the consumer, free of charge, the following:
              <ul>
                <p style={{ textAlign: "justify" }}>
                  o The categories of personal information that it has collected about that
                  consumer.
                  <br />
                  o The categories of sources from which the personal information is collected.
                  <br />
                  o The business or commercial purpose for collecting or selling personal
                  information.
                  <br />
                  o The categories of third parties with whom the business shares personal
                  information. <br />o The specific pieces of personal information it has collected
                  about that consumer.
                </p>
              </ul>
            </li>
            <li style={{ textAlign: "justify" }}>
              Request that a business that sells the consumer’s personal information, or that
              discloses it for a business purpose, disclose to the consumer, free of charge, the
              following:
              <ul>
                <p style={{ textAlign: "justify" }}>
                  o The categories of personal information that the business collected about the
                  consumer.
                  <br />
                  o The categories of personal information that the business sold about the consumer
                  and the categories of third parties to whom the personal information was sold, by
                  category or categories of personal information for each third party to whom the
                  personal information was sold.
                  <br />
                  o The categories of personal information that the business disclosed about the
                  consumer for a business purpose.
                  <br />
                </p>
              </ul>
            </li>
            <li style={{ textAlign: "justify" }}>
              Direct a business that sells personal information about the consumer to third parties
              not to sell the consumer’s personal information.
            </li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            We do not sell your personal information to third parties or disclose your personal
            information to third parties for a business purpose. To exercise any of your other
            rights above, you may contact us by calling us toll-free at 1-000-000-000] or by
            emailing us at support@priafinancial.com. We may request up to three pieces of personal
            information about you when you make a request to verify your identity. Only you, or an
            authorized agent acting on your behalf, may make a verifiable consumer request related
            to your personal information. To use an authorized agent to submit a request to us, you
            must (i) provide the authorized agent signed permission to make a request on your
            behalf; (ii) verify your identity with us; and (iii) directly confirm with us that you
            provided the authorized agent permission to submit the request.
          </p>
          <p style={{ textAlign: "justify" }}>
            We will verify your consumer request by comparing the information you provide to us
            against our records, which may include requesting that you provide us with your name,
            address, and phone number.
          </p>
          <p style={{ textAlign: "justify" }}>
            You have the right not to be discriminated against for your exercise of the privacy
            rights conferred by the CCPA. We will not discriminate against a consumer because the
            consumer exercises any of the consumer’s rights as described in this CCPA Disclosure.
            However, we reserve the right to limit these rights where permitted under applicable
            law, including where your identity cannot be reasonably verified, to the extent your
            rights adversely affect the rights and freedoms of others, or where the CCPA allows us
            an exemption, such as when we are required to comply with federal, state, or local laws.
          </p>
          <p style={{ textAlign: "justify" }}>
            Cookies and Flash Cookies. Most browsers allow you to adjust your browser settings to:
            (i) notify you when you receive a cookie, which lets you choose whether or not to accept
            it; (ii) disable existing cookies; or (iii) set your browser to automatically reject
            cookies. Blocking or deleting cookies may negatively impact your experience using the
            Services, as some features and services on our Services may not work properly. Deleting
            cookies does not delete Local Storage Objects (LSOs) such as Flash objects and HTML5. To
            manage Flash cookie settings and preferences, you must use the settings manager on
            Adobe’s website. If you choose to delete Flash objects from our Services, then you may
            not be able to access and use all or part of the Services or benefit from the
            information and services offered.
          </p>
          <p style={{ textAlign: "justify" }}>
            Interest-Based Advertising. In the U.S., you can opt out of tracking on our website or
            others for online behavioral advertising by visiting the Digital Advertising Alliance’s
            (the “DAA”) Consumer Choice page at http://www.aboutads.info/choices/ or the Network
            Advertising Initiative’s (the “NAI”) page at http://www.networkadvertising.org/choices.
            You may opt out of receiving certain targeted advertising in mobile apps through
            participating members of the DAA by installing the AppChoices mobile app, available at
            https://youradchoices.com/appchoices. Please note that opting out of receiving
            interest-based advertising through the NAI’s and DAA’s online resources will only opt
            out a user from receiving interest-based ads on that specific browser or device, but the
            user may still receive interest-based ads on his or her other devices. You must perform
            the opt-out on each browser or device you use.
          </p>
          <p style={{ textAlign: "justify" }}>
            Mobile Advertising. You may also be able to limit interest-based advertising through the
            settings on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of
            interest based ads’’ (Android). You may also be able to opt-out of some—but not
            all—interest-based ads served by mobile ad networks by visiting
            http://youradchoices.com/appchoices and downloading the mobile App Choices app. Some of
            these opt-outs may not be effective unless your browser is set to accept cookies. If You
            delete cookies, change your browser settings, switch browsers or computers, or use
            another operating system, you will need to opt-out again.
          </p>
          <h5 ref={scrollRefs[10]}>Changes to This Privacy Policy:</h5>
          <p style={{ textAlign: "justify" }}>
            We may modify or update this Policy from time to time to reflect the changes in our
            business and practices, so you should review this page periodically. When we change the
            policy in a material manner, we will let you know and update the ‘last updated’ date at
            the top of this page. If you object to any changes, you must stop using the Services and
            provide us immediate notice. Continuing to use our Services after we publish changes to
            this Privacy Policy means that you are consenting to the changes.
          </p>
          <h5 ref={scrollRefs[11]}>Children&apos;s Privacy:</h5>
          <p style={{ textAlign: "justify" }}>
            We do not knowingly collect, maintain or use personal information from children younger
            than 18 (or the age of majority) years of age, and no part of our Services are directed
            to children younger than the age of 18 (or the age of majority). If you learn that your
            child has provided us with Personal Information without your consent, please let us know
            by contacting us{" "}
            <a href="https://gmail.com" target="_blank" rel="noreferrer">
              support@priafinancial.com
            </a>
            . If we learn that we have collected any personal information from children younger than
            18 (or the age of majority), we will use commercially reasonable efforts to delete such
            information.
          </p>
          <h5 ref={scrollRefs[12]}>California &quot;Shine the Light&quot; Law:</h5>
          <p style={{ textAlign: "justify" }}>
            You are a user of the Services and a California resident, California&apos;s &quot;Shine
            the Light&quot; Law (California Civil Code §1798.83) permits you to request and obtain
            from us once a year, free of charge, information regarding our disclosure of your
            personal information (if any) to third parties for direct-marketing purposes. If this
            law applies to you and you would like to make such a request, please submit your request
            inwriting in compliance with the below section (Contact Us).
          </p>
          <h5 ref={scrollRefs[13]}>Your Nevada Rights:</h5>
          <p style={{ textAlign: "justify" }}>
            If you are a resident of Nevada, you have the right to opt out of the sale of certain
            personal data to third parties that intend to license or sell that personal data. You
            can exercise this right by contacting us at [insert email] with the subject line “Nevada
            Do Not Sell Request” and providing us with your name and the email address associated
            with your account. Please note that we do not currently sell your personal data as sales
            are defined in Nevada Revised Statutes Chapter 603A.
          </p>
          <h5 ref={scrollRefs[14]}>Contact Us:</h5>
          <p style={{ textAlign: "justify" }}>
            If you have any questions about this Policy or the Services, please contact us at{" "}
            <a href="https://gmail.com" target="_blank" rel="noreferrer">
              support@priafinancial.com
            </a>{" "}
            or [000-000-0000].
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;

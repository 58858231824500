import { useEffect, useState } from "react";
import React from "react";
import { FaStar } from "react-icons/fa";
import "./Feedback.scss";
import * as routes from "../../constants/routes";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import { handleError } from "utils/handleError";
import { useAuth0 } from "@auth0/auth0-react";
import { API_URL } from "services/http";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

toast.configure();

function Feedbacks() {
  const { isAuthenticated } = useAuth0();
  const [values, setValues] = useState({
    star: 0,
    message: "",
  });

  const [currentValue, setCurrentValue] = useState(0);
  const [disable, setDisable] = useState(true);

  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  const handleClick = (value: any) => {
    setCurrentValue(value);
    setValues({ ...values, star: value });
  };
  const handleMessageChange = (e: any) => {
    setValues({ ...values, message: e.target.value });
  };

  const handleMouseOver = (newHoverValue: any) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  useEffect(() => {
    {
      currentValue > 0 && setDisable(false);
    }
  }, [currentValue]);

  const handleSubmit = async () => {
    await axios
      .post(`${API_URL}/support/feedback`, { ...values })
      .then((res) => {
        if (res && res.data && checkResponseStatus(res.data.httpCode)) {
          toast("Feedback submitted successfully");

          setTimeout(() => {
            isAuthenticated ? history.push(routes.DASHBOARD) : history.push(routes.HOME);
          }, 1500);
        } else {
          toast("Could not submit feedback");
        }
      })
      .catch((err) => {
        toast("Something went wrong");
        console.log(JSON.stringify(err));
      });
  };

  const history = useHistory();
  return (
    <div className="contain">
      <ToastContainer />
      <div className="contain1">
        <h5 className="first-message"> Your opinion matters to us! </h5>
        <div className="contain2">
          <h5 className="second-message">How was your experience?</h5>
          <div className="stars">
            {stars.map((_, index) => {
              return (
                // eslint-disable-next-line react/react-in-jsx-scope
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                />
              );
            })}
          </div>
          <textarea
            className="textarea change"
            placeholder="Leave a message if you want"
            onChange={handleMessageChange}
            value={values.message}
          ></textarea>
          <button className="button third-message" onClick={handleSubmit} disabled={disable}>
            Rate Now
          </button>
        </div>
        <button
          className="button2"
          onClick={() => (isAuthenticated ? history.push(routes.DASHBOARD) : history.push("/"))}
        >
          Maybe later
        </button>
      </div>
    </div>
  );
}

export default Feedbacks;

/* eslint-disable react/display-name */
import { useAuth0 } from "@auth0/auth0-react";
import AccountPage from "components/AccountPage";
import CardsPage from "components/CardsPage";
import CheckoutPage from "components/CheckoutPage";
import Footer from "components/Footer";
import AppNavbar from "components/Navbar";
import { SupportModal } from "components/Navbar/AppNavbar";
import Spinners from "components/Spinners/Spinners";
import SubscriptionPage from "components/SubscriptionPage";
import useFetchUser, { FetchUserProvider } from "hooks/useFetchUser";
import useIsAdmin from "hooks/useIsAdmin";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RootState } from "store";
import "./dashboard.scss";

const Dashboard = () => {
  const [supportModalVisibility, setSupportModalVisibility] = useState(false);

  const match = useRouteMatch();
  const isAdmin = useIsAdmin();

  const { isLoading } = useAuth0();
  const userState = useSelector((state: RootState) => state.user);

  const logoutState = useSelector((state: RootState) => state.logoutloader?.logoutstate);

  const onboardingLater = useSelector((state: RootState) => state.utils.onboarding_later_state);

  if (isLoading || logoutState) {
    return <Spinners />;
  }

  if (userState.user?.onBoardingIndex < 3 && !onboardingLater && !isAdmin) {
    return <Redirect to="/onboarding" />;
  }
  if (sessionStorage.getItem("back-dashboard")) {
    console.log("back to dashbaord");
  }

  return (
    <FetchUserProvider>
      <div className="dashboard-page">
        <SupportModal
          supportModalVisibility={supportModalVisibility}
          setSupportModalVisibility={setSupportModalVisibility}
        />

        <AppNavbar setSupportModalVisibility={setSupportModalVisibility} />
        <div className="main">
          <Switch>
            <Route exact path={`${match.path}`} component={CardsPage} />
            <Route path={`${match.path}/account`} component={AccountPage} />

            <Route path={`${match.path}/account/:ref`} component={AccountPage} />
            <Route path={`${match.path}/subscription`} component={SubscriptionPage} />
            <Route path={`${match.path}/checkout`} component={CheckoutPage} />
          </Switch>
        </div>
        <Footer />
      </div>
    </FetchUserProvider>
  );
};

export default Dashboard;

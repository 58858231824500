import http from "./http";

import * as urls from "constants/urls";
import { FilterOptions } from "components/DynamicTable/FilterOptions";
import moment from "moment";

export const fetchTransactionsAPI = (
  args: FilterOptions & Record<string, any>,
  user_id: string,
  token: string,
) => {
  const filterOptions = args.filter;
  delete args.filter;

  const startDate = moment(filterOptions?.startDate)
    .subtract(30, "days")
    .utc()
    .format("YYYY-MM-DD HH:MM");
  const endDate = moment(filterOptions?.endDate).utc().add(1, "day").format("YYYY-MM-DD HH:MM");

  const params = {
    ...filterOptions,
    order: "DESC",
    sort: "createdAt",
    ...args,
    startDate,
    endDate,
    userId: user_id,
  };

  return http.get(`transactions/summary`, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchStripeTransactionsAPI = ({ userId, limit, startDate, endDate, token }: any) => {
  let reqLimit = limit ? `?offset=0&limit=${limit}` : "";
  startDate = startDate === "all" ? "1628309711000" : startDate;

  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const reqStartDate = `&gte=${Math.round(startDate / 1000)}`;
  const reqEndDate = `&lte=${Math.round(endDate / 1000)}`;
  const reqSortId = `&sort=id:desc,submitTimeUtc:desc`;
  return http.get(
    `${urls.TRANSACTION}/stripe/${userId}${reqLimit}${reqStartDate}${reqEndDate}${reqSortId}`,
  );
};

export const confirmPaymentAPI = (userId: string, paymentIntentId: string, token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.post(`${urls.TRANSACTION}/confirm-paymentintent/${paymentIntentId}`);
};

import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";

import jwt_decode from "jwt-decode";
import { useDispatch, useStore } from "react-redux";
import { UPDATE_USER_PERMISSION, UPDATE_USER_TYPE } from "types/IUser";
import { useHistory } from "react-router-dom";
import { RootState } from "store";

const useSetPermissions = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();

  const navigate = useHistory();

  const { isLoading } = useQuery(
    ["getAccesstoken", isAuthenticated],
    async () => {
      if (!isAuthenticated) throw new Error("Not authenticated");

      /* when using firefox, you might encounter  Login failed error
      this might be cause because of the cross domain cookies rules in firefox
      make sure you test it in chrome to check if its a cookie problem

      to solve this problem in firefox, you might need to upgrade your auth0 plan
      and migrate to a custom domain. check out https://community.auth0.com/t/failed-silent-auth-login-required/33165
      for more details */

      const token = await getAccessTokenSilently();

      if (!token) {
        throw new Error("Invalid token");
      }

      const decoded = jwt_decode(token) as any;
      if (!decoded) throw new Error("Something went wrong");

      return decoded;
    },
    {
      retry: false,
      onSuccess: (data) => {
        const role = data?.["https://priafinancial.com/roles"];

        if (!role) throw new Error("Not Roles");

        const rolesArr = data?.["https://priafinancial.com/roles"] || [];
        const isAdmin = rolesArr.includes("admin");

        dispatch({ type: UPDATE_USER_TYPE, payload: isAdmin });

        const permissionArr = data?.permissions;

        if (!permissionArr) return;

        let permissionMap = permissionArr?.reduce(function (acc: any, cur: string) {
          acc[cur] = cur;
          return acc;
        }, {});

        dispatch({
          type: UPDATE_USER_PERMISSION,
          payload: { permissionList: permissionArr, permissions: permissionMap },
        });

        return navigate.push(isAdmin ? "/admin" : "/dashboard");
      },
      onError: async () => {
        console.log("Not authenticated");
      },
    },
  );
  return isLoading;
};

export default useSetPermissions;

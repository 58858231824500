import { GenericFn } from "common";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const GENERIC_SUCCESS_MESSAGE = "Successful";

/**
 *
 * @param event Error Object or any event that triggered the error
 * @param message Optional text to display as error message
 */
export function handleSuccess(message?: string, onClose?: GenericFn) {
  const text = message || GENERIC_SUCCESS_MESSAGE;

  return toast.success(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    // pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose,
  });
}

export function checkResponseStatus(status: number) {
  return status >= 200 && status < 300;
}

import { createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import RootRouter from "./components/RootRouter";

import ResizeObserver from "resize-observer-polyfill";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const calculateVh = () => {
  if (!window) return;
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const observer = new ResizeObserver(resizeObserver);
  observer.observe(document.body);
};

const resizeObserver = (entries: any[]) => {
  entries.forEach((entry) => {
    document.documentElement.style.setProperty("--vw", `${entry.contentRect.width}px`);
  });
};

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    primary: {
      main: "#081149",
    },
  },
});

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener("load", calculateVh);
    window.addEventListener("resize", calculateVh);
    window.addEventListener("orientationchange", calculateVh);

    return () => {
      window.removeEventListener("resize", calculateVh);
      window.removeEventListener("load", calculateVh);
      window.removeEventListener("orientationchange", calculateVh);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <RootRouter />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;

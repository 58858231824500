import * as routes from "constants/routes";
import { BrowserRouter } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { updateUserSuccessAction } from "actions/users";
import { toggleWillDoLaterAction } from "actions/utils";
import AppButton from "common/AppButton";
import AppInput from "common/AppInput";
import AppSelect from "common/AppSelect";
import { FormikProvider, useFormik } from "formik";
import useAdministrativeAreas from "hooks/useAdministrativeAreas";
import { useFetchUserCtx } from "hooks/useFetchUser";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyEmailAPI } from "services/users";
import { setOnboardingIndexAPI } from "services/util";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import { usePostalCodeState } from "utils/zipCode";
import * as Yup from "yup";
import { updateUserQuery } from "../../api/onboarding2";
import "./OnBoarding0.scss";

//function Onbording2() {
const OnBoarding2: React.FC<any> = ({ activeStep, setActiveStep }) => {
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const customId = "custom-id-yes";

  const userInfo = useSelector((state: RootState) => state.user.user);
  const userUpdateSuccess = useSelector((state: RootState) => state.user.userUpdateSuccess);
  const userUpdateError = useSelector((state: RootState) => state.user.error);

  const refetchUser = useFetchUserCtx();

  // useEffect(() => {
  //   user && user?.sub && dispatch(fetchUser(user?.sub));
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { mutate: updateUserWithReactQuery, isLoading: isLoading } = useMutation(updateUserQuery, {
    async onSuccess(data: any) {
      if (!data) {
        handleError(data?.message || "Profile Update Failed");
      } else {
        dispatch(updateUserSuccessAction());

        const token = await getAccessTokenSilently();

        if (user && user.sub) {
          let response = await setOnboardingIndexAPI(user.sub, token, activeStep + 1);
          if (response && checkResponseStatus(response.status)) {
            handleSuccess("Updated successfully");
            refetchUser();
            setTimeout(() => {
              setActiveStep(1);
            }, 1500);
          }
        }
      }
    },
    onError(postError) {
      toast((postError as any)?.response?.data?.message, { toastId: customId });
    },
  });

  const [states, stateMap] = useAdministrativeAreas();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      //name: `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}` || '',
      firstName: userInfo?.firstName || "",
      middleName: userInfo?.middleName || "",
      lastName: userInfo?.lastName || "",
      email: user?.email || "",
      phone: userInfo?.phone || "",
      address: {
        address1: userInfo?.address?.address1 || "",
        address2: userInfo?.address?.address2 || "",
        locality: userInfo?.address?.locality || "",
        administrativeArea: userInfo?.address?.administrativeArea || states?.[0]?.value,
        country: userInfo?.address?.country || "US",
        postalCode: userInfo?.address?.postalCode || "",
      },
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Invalid Phone Number. Valid number format is 2025550104")
        .required("Phone number is required"),
      address: Yup.object().shape({
        address1: Yup.string().required("Street is required"),
        address2: Yup.string().nullable(true),
        locality: Yup.string().required("City is required"),
        administrativeArea: Yup.string().required("State is required"),
        country: Yup.string().nullable(true),
        postalCode: Yup.string()
          .matches(/^\d{5}([-]\d{4})?$/, "Invalid postal code")
          .required("Postal Code is required"),
      }),
    }),
    onSubmit: async (values: any) => {
      const token = await getAccessTokenSilently();
      delete values["email"];

      let requestValues = {
        ...values,
        token: token,
      };
      user && user.sub && handleUpdatesValues(user?.sub, requestValues);
    },
  });

  const handleUpdatesValues = (userSub: any, values: any) => {
    updateUserWithReactQuery({ userId: userSub, data: values });
    // console.log('testing for values');
  };

  const verifyEmail = async () => {
    const token = await getAccessTokenSilently();
    try {
      if (user && user?.sub && !user?.email_verified) {
        const response = await verifyEmailAPI(user?.sub, token);
        handleSuccess();
      }
    } catch (error: any) {
      // console.log(error?.message);
      handleError(error?.message);
    }
  };

  const { handleAdministrativeAreaChange, handlePostalCodeChange } = usePostalCodeState(formik);

  return (
    <div>
      <div className="onboarding-text onboarding-space spc">
        <h3 style={{ whiteSpace: "initial" }}>Complete your profile</h3>
      </div>
      {/* <div className="mainBox"> */}
      <div className="box onboarding-space2">
        <FormikProvider value={formik}>
          <Form>
            <Form.Group as={Col}>
              <Form.Label column sm="2" htmlFor="fullnme">
                Full Name
              </Form.Label>
              <Col>
                <Row>
                  <Col sm="4">
                    <AppInput
                      placeholder="First Name"
                      name="firstName"
                      value={formik.values?.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.firstName ? true : false}
                    />
                    {formik.errors.firstName && formik.touched.firstName && (
                      <div className="invalid-feedback-text">{formik.errors.firstName}</div>
                    )}
                  </Col>
                  <Col sm="4">
                    <AppInput
                      placeholder="Middle Name"
                      name="middleName"
                      value={formik.values?.middleName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                  <Col sm="4">
                    <AppInput
                      placeholder="Last Name"
                      name="lastName"
                      value={formik.values?.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.lastName ? true : false}
                    />
                    {formik.errors.lastName && formik.touched.lastName && (
                      <div className="invalid-feedback-text">{formik.errors.lastName}</div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label column sm="2" htmlFor="fullnme">
                Email
              </Form.Label>
              <Col sm="12">
                <AppInput
                  placeholder="janedoe@gmail.com"
                  name="email"
                  value={formik.values?.email}
                  disabled
                  adornmentLabel={user?.email_verified ? `Email verified` : `Verify Email`}
                  onButtonClick={verifyEmail}
                  buttonClassName={user?.email_verified ? `success` : `primary`}
                  //  style={{ borderRadius: '4px 0px 0px 4px' }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column sm="2" htmlFor="phone">
                Phone
              </Form.Label>
              <Col sm="12">
                <AppInput
                  type="text"
                  name="phone"
                  value={formik.values?.phone}
                  maxLength={10}
                  onChange={(e: any) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  adornmentText="+1"
                  isInvalid={formik.errors.phone ? true : false}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="invalid-feedback-text">{formik.errors.phone}</div>
                )}
              </Col>
            </Form.Group>

            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    Street 1
                  </Form.Label>
                  <Col sm="12">
                    <AppInput
                      type="text"
                      name="address.address1"
                      placeholder="Eg. 123 Accacia Ave."
                      value={formik.values?.address.address1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.address?.address1 && formik.touched.address?.address1 && (
                      <div className="invalid-feedback-text">{formik.errors.address?.address1}</div>
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    Street 2 (optional)
                  </Form.Label>
                  <Col sm="12">
                    <AppInput
                      type="text"
                      name="address.address2"
                      value={formik.values?.address.address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    City
                  </Form.Label>
                  <Col sm="12">
                    <AppInput
                      type="text"
                      name="address.locality"
                      value={formik.values?.address?.locality}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.errors.address?.locality && formik.touched.address?.locality && (
                      <div className="invalid-feedback-text">{formik.errors.address.locality}</div>
                    )}
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    State
                  </Form.Label>
                  <Col sm="12">
                    <AppSelect
                      options={states}
                      size="sm"
                      style={{ WebkitAppearance: "menulist", maxWidth: "100%" }}
                      name="address.administrativeArea"
                      value={formik.values?.address?.administrativeArea}
                      autoComplete="off"
                      onChange={handleAdministrativeAreaChange}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    Country
                  </Form.Label>
                  <Col sm="12">
                    <AppInput
                      type="text"
                      name="address.country"
                      value={formik.values.address?.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label column sm="4" htmlFor="fullnme">
                    Zip Code
                  </Form.Label>
                  <Col sm="12">
                    <AppInput
                      type="text"
                      name="address.postalCode"
                      value={formik.values?.address?.postalCode}
                      maxLength={5}
                      onChange={handlePostalCodeChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.address?.postalCode ? true : false}
                    />
                    {formik.errors.address?.postalCode && formik.touched.address?.postalCode && (
                      <div className="invalid-feedback-text">
                        {formik.errors.address?.postalCode}
                      </div>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <div className="onboarding2-btn">
              <AppButton
                type="submit"
                onClick={formik.handleSubmit}
                className="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Continue
              </AppButton>
              &nbsp; &nbsp;
              {/* <AppButton
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  formik.resetForm();
                }}
                className="primary outlined"
              >
                Cancel
              </AppButton>  */}
            </div>
            <BrowserRouter>
              {/* <Link to='/'>
            <div className="onboarding-text-2">I will do this later</div>
          </Link> */}
              <div className="onboarding-text-2">
                <AppButton
                  className="borderless primary"
                  onClick={() => {
                    dispatch(toggleWillDoLaterAction());
                    history.push(routes.DASHBOARD);
                  }}
                >
                  I will do this later
                </AppButton>
              </div>
            </BrowserRouter>
          </Form>
          <ToastContainer />
        </FormikProvider>
        {/* </div>*/}
      </div>
    </div>
  );
};
export default OnBoarding2;

import { useAuth0 } from "@auth0/auth0-react";
import { FAQ } from "components/FAQPage/faq";
import Footer from "components/Footer";
import AppNavbar from "components/Navbar";
import Navmenu from "components/NavMenu/Navmenu";
import Sidebar from "components/Sidebar/Sidebar";
import { useSupportModal } from "hooks/useSidebar";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import "./faq.scss";

const FAQPage: React.FC = () => {
  //in this example faqOpen array is created with the same length as faq array, since we don't want them to open by default we fill the values with false, try with true to see the difference
  const [faqOpen, setFaqOpen] = useState<any>(Array(FAQ.length).fill(false));

  const { user, isAuthenticated } = useAuth0();
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);

  const [setSupportModalVisibility, SupportComponent] = useSupportModal();

  const FAQComp = ({ faq, index, faqOpen }: any) => {
    const [isOpen, setIsOpen] = useState(faqOpen);
    return (
      <div className="faq" key={index}>
        <div className="faq-header" onClick={() => setIsOpen((prev: boolean) => !prev)}>
          {faq.question}
          <div className="action">{!isOpen ? "+" : "-"}</div>
        </div>
        <div className="faq-answer">{isOpen ? faq?.answer : ""}</div>
      </div>
    );
  };

  return (
    <>
      <div className="faq-page">
        {!isAuthenticated ? (
          <Navmenu />
        ) : (
          <AppNavbar setSupportModalVisibility={setSupportModalVisibility} />
        )}
        {!isAuthenticated && sidebar && <Sidebar />}

        <SupportComponent />

        <div className="faqs">
          <h3>Frequently Asked Questions</h3>
          <div className="faq-margin"></div>
          {FAQ.map((faq: any, key: number) => (
            <FAQComp faq={faq} index={key} key={key} faqOpen={faqOpen[key]} />
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FAQPage;

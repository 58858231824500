import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type AscDesc = "asc" | "desc" | undefined;

export interface FilterOptions {
  page?: number;
  limit?: number; //size,
  search?: string;
  sort?: string;
  order?: AscDesc;
  filter?: Record<string, any>;
}

declare const filterOptions: unknown;
declare const query: unknown;

export function useInitFilter(): [typeof filterOptions, typeof query] {
  const { search } = useLocation();
  //get GET query in url
  const query = useMemo(() => new URLSearchParams(search), [search]);

  /**
   * Function to get sort from url
   */
  const filterOptions = useMemo<FilterOptions>(() => {
    /* const getQuery = (field: keyof FilterOptions) => {
      const queryValue = query.get(field);

      if (!queryValue) {
        // purposefully lose condition so which prevents page===0
        query.delete(field);
        return;
      }
      return query.get(field) || undefined;
    } */

    let obj = Object.fromEntries(query);
    const temp: any = {};

    Object.keys(obj).forEach((key) => {
      try {
        temp[key] = Number(obj[key]);
        if (!temp[key]) throw new Error("Type mismatch");
      } catch (error) {
        if (key === "filter") {
          try {
            //convert from base64
            const filterObj = JSON.parse(fromBase64(obj[key]));
            temp[key] = filterObj;
          } catch (error) {
            console.log(error);
          }
        } else {
          temp[key] = obj[key];
        }
      }
    });
    return temp as FilterOptions;
  }, [query]);

  return [filterOptions, query];
}

type FilterKeyType = keyof FilterOptions;
/**
 * Hook to get filter options and set filter options
 * @returns
 */
export function useFilter(defaultFilter?: FilterOptions) {
  const [filterOptions, query] = useInitFilter();
  const history = useHistory();
  const oldSort = query.get("sort");

  const setField = useCallback(
    (field: FilterKeyType, value: typeof filterOptions[typeof field]) => {
      //update query string
      if (typeof value === "number" || typeof value === "string")
        //normal query string update
        value ? query.set(field, value?.toString()) : query.delete(field);
      //sort
      if (field === "sort") {
        const order = filterOptions.order;
        let ascdesc: AscDesc;

        switch (order) {
          default:
            ascdesc = "asc";
            break;
          case "asc":
            ascdesc = "desc";
            break;
          case "desc":
            ascdesc = undefined;
            break;
        }

        if (oldSort && oldSort !== query.get("sort")) {
          query.set("order", "asc");
        } else {
          ascdesc ? query.set("order", ascdesc) : query.delete("order");
        }
      }
      //push to url without redirect
      history.replace({ search: query.toString() });
    },
    [filterOptions.order, history, oldSort, query],
  );

  const filterMethods = {
    filterOptions,
    setPage: (page?: number) => setField("page", page),
    setLimit: (limit?: number) => setField("limit", limit),
    setSearch: (search?: string) => setField("search", search),
    setSort: (sort: string) => setField("sort", sort),
    setFilter: (filter: Record<string, any>) =>
      setField("filter", toBase64(JSON.stringify(filter))),
  };

  useEffect(() => {
    defaultFilter &&
      Object.entries(defaultFilter).forEach(([key, value]) => {
        setField(key as FilterKeyType, value);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return filterMethods;
}

const toBase64 = (str: string) => {
  return Buffer.from(str).toString("base64");
};

const fromBase64 = (base64str: string) => {
  return Buffer.from(base64str, "base64").toString("ascii");
};

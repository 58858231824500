import { useAuth0 } from "@auth0/auth0-react";
import { toggleSidebarHomeAction } from "actions/utils";
import hamburgerMenuIcon from "assets/img/menu_icon.png";
import AppButton from "common/AppButton";
import Sidebarhome from "components/SidebarHome/Sidebarhome";
import * as routes from "constants/routes";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { RootState } from "store";
import "./navmenu.scss";

const NavMenu: React.FC = () => {
  const [navClassName, setNavClassName] = useState("");
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const {
    loginWithRedirect,
  } = useAuth0();

  const dispatch = useDispatch();
  const [hamburgerMenu, setHamburgerMenu] = useState(window.innerWidth <= 960 ? true : false);

  const history = useHistory();

  const handleScroll = useCallback(() => {
    // let header = document.getElementById('header');
    // let sticky = header?.offsetTop || 0;
    if (window.pageYOffset > 0) {
      if (navClassName === "") {
        setNavClassName("scrolled");
      }
    } else {
      if (navClassName) {
        setNavClassName("static");
      }
    }
  }, [navClassName]);
  const handleResponsive = useCallback(() => {
    if (window.innerWidth <= 960) {
      setHamburgerMenu(true);
    } else {
      setHamburgerMenu(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResponsive);
  }, [handleResponsive, handleScroll]);
  // useEffect(() => {
  //   if (window.innerWidth <= 440) {
  //     setHamburgerMenu(true);
  //   } else {
  //     setHamburgerMenu(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  // }, [handleScroll]);
  const toggleMenu = () => {
    dispatch(toggleSidebarHomeAction());
  };

  useEffect(() => {
    document.body.style.overflow = sidebarhome ? "hidden" : "unset";
  }, [sidebarhome]);

  return (
    <header className={navClassName}>
      <Sidebarhome />
      <h4 onClick={() => history.push(routes.HOME)} style={{ cursor: "pointer" }}>
        PRIA
      </h4>
      {!hamburgerMenu ? (
        <div className="menu">
          {/* <AppButton className="outlined primary" onClick={() => history.push(routes.SIGNUP)}> */}

          <div className="nav-item md-text-reg">
            <NavLink
              to="/About"
              exact={true}
              activeClassName="active"
              className={"nav-link"}
              style={{ cursor: "pointer" }}
              //className={({isActive}) => isActive ? 'active' : ''}

              //onClick={() => history.push(routes.ABOUT)}
            >
              About
            </NavLink>
          </div>

          <div className="nav-item md-text-reg">
            <NavLink
              style={{ cursor: "pointer" }}
              to="/faq"
              exact={true}
              activeClassName="active"
              className={"nav-link"}
              //onClick={() => history.push(routes.FAQ)}
            >
              FAQ
            </NavLink>
          </div>

          <div className="nav-item md-text-reg">
            <NavLink
              style={{ cursor: "pointer" }}
              //onClick={() => history.push(routes.CONTACT)}
              to="/contact"
              exact={true}
              activeClassName="active"
              className={"nav-link"}
            >
              Contact
            </NavLink>
          </div>

          <AppButton className="outlined primary m-1" onClick={() => loginWithRedirect()}>
            <div className="nrm-text-bold">Log In</div>
          </AppButton>
          <AppButton
            className="primary m-1"
            onClick={() =>
              loginWithRedirect({
                screen_hint: "signup",
              })
            }
          >
            <div className="nrm-text-bold">Sign Up</div>
          </AppButton>
        </div>
      ) : (
        <img
          style={{ marginTop: 4, marginRight: 8 }}
          src={hamburgerMenuIcon}
          onClick={() => toggleMenu()}
        />
      )}
    </header>
  );
};

export default NavMenu;

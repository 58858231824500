import { Button } from "@mui/material";
import { getAllTransactions, verifyTransactions } from "api/admin";
import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import { CardBrandReverse } from "constants/cards";
import useGetAccessToken from "hooks/useGetAccessToken";
import moment from "moment";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import { handleError } from "utils/handleError";
import { handleSuccess } from "utils/handleSuccess";
import "./Transaction.scss";

export interface TransactionDataType {
  id: number;
  sn: number;
  transactionId?: string;
  cardDetails?: string;
  email?: string;
  txnType?: string;
  status?: string;
  amount?: number;
  isVerified?: number;
  remarks?: string;
  createdAt?: string;
  isReturned?: string;
}

const Transactions = () => {
  const { filterOptions } = useFilter({ limit: 10, order: "desc" });
  const token = useGetAccessToken();
  const { data: allTransactionsData, isLoading, refetch } = useQuery(
    ["all transactions", token, filterOptions],
    () => token && getAllTransactions(token, filterOptions),
    {
      // keepPreviousData: true,
    },
  );

  const { mutateAsync: verifyTxn } = useMutation(verifyTransactions, {
    onSuccess(data) {
      if (data) {
        handleSuccess("Transactions Verified");
        refetch();
      } else handleError("Transaction Verification Failed");
    },
  });

  const handleVerifyTxn = async (ids: string[]) => await verifyTxn(ids);

  const transactionTableData = useMemo<TransactionDataType[]>(() => {
    try {
      return allTransactionsData?.rows.map((txn: any, index: number) => {
        return {
          id: txn?.id,
          createdAt: moment(txn?.createdAt).format("YYYY-MM-DD HH:mm"),

          sn: (allTransactionsData?.currentPage - 1) * allTransactionsData?.rowsCount + index + 1,
          transactionId: txn?.transactionId,
          email: txn?.customer?.email,
          cardDetails: `${CardBrandReverse?.[txn?.card?.brand] || ""}****${
            txn?.card?.lastFour || ""
          }`,
          txnType: (
            <span
              className={`${
                txn?.transactionStatus.toLowerCase() === "success" ? "success" : "failed"
              }`}
            >
              {txn?.type}
            </span>
          ),
          isVerified: (
            <span className={`${txn?.isVerified ? "verified" : "not-verified"}`}>
              {txn?.isVerified ? "Verified" : "Not Verified"}
            </span>
          ),
          status: txn?.transactionStatus,
          isReturned: txn?.isReturned ? <span>Returned</span> : <span>Not Returned</span>,
          amount: `$${parseFloat(txn?.amount).toFixed(2)}`,
          remarks: txn?.remarks,
          // createdAt: singleData?.createdAt.replace("T", " ").replace("Z", " "),
          // payinout: singleData?.isBalanceReturned
          //   .toString()
          //   .replace("false", "Pay In")
          //   .replace("true", "pay Out"),
        };
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  }, [allTransactionsData]);

  const VerifyTxn = ({ selectedRows }: any) => {
    if (!selectedRows?.length) {
      return null;
    }

    return (
      <Button
        variant="outlined"
        onClick={() => handleVerifyTxn(selectedRows.map((x: any) => x.id))}
      >
        Verify
      </Button>
    );
  };

  return (
    <>
      <ToastContainer />
      <DynamicTable<TransactionDataType>
        title="Transactions"
        td={transactionTableData}
        th={[
          // { accessor: "sn", h: "S.N", isSortable: false },
          { accessor: "transactionId", h: "Transaction Id", isSortable: true },
          { accessor: "createdAt", h: "Created At", isSortable: true },
          { accessor: "cardDetails", h: "Card Details", isSortable: true },
          { accessor: "email", h: "Email", isSortable: true },
          { accessor: "txnType", h: "Transaction Type", isSortable: true },
          { accessor: "isReturned", h: "Returned", isSortable: true },
          { accessor: "amount", h: "Amount", isSortable: true },
          { accessor: "isVerified", h: "Verified", isSortable: true },
          { accessor: "remarks", h: "Remarks", isSortable: true },
        ]}
        totalPages={allTransactionsData?.lastPage}
        totalResults={allTransactionsData?.totalCount}
        isLoading={isLoading}
        showMore={""}
        showFilter
        filterList={[
          {
            field: "transactionId",
            type: "text",
            label: "TransactionId",
          },
          {
            field: "type",
            type: "text",
            label: "Transaction Type",
          },
        ]}
        showCheckbox
        // onCheckedChange={onCheckboxChange}
        actions={[VerifyTxn]}
        disableCheckboxCriteria={(rowData) => {
          const capture = rowData?.txnType?.props.children;
          const verified = rowData?.isVerified?.props.children;
          return !(capture.includes("CAPTURE") && verified.toLowerCase() !== "verified");
        }}

        // filterList={filterList}
      />
    </>
  );
};

export default Transactions;

import http from './http';

import * as urls from 'constants/urls';

export const postEnableMFAAPI = (userId: any, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.post(`${urls.MFA}/?status=true`);
};

export const postDisableMFAAPI = (userId: any, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.post(`${urls.MFA}/?status=false`);
};

export const getMFAInfoAPI = (userId: any, token: string) => {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return http.get(`${urls.MFA}/`);
};

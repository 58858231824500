/* eslint-disable react/display-name */
import CardsPageAdmin from "components/CardsPage/CardsPageAdmin";
import AppNavbar from "components/Navbar";
import Spinners from "components/Spinners/Spinners";
import { UserIdProvider as UserDataProvider } from "Context/AdminDataAccess";
import useIsAdmin from "hooks/useIsAdmin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RootState } from "store";
import "../Dashboard/dashboard.scss";

const AdminDashAccess = () => {
  const [setSupportModalVisibility] = useState(false);
  const history = useHistory();
  const location: any = useLocation();

  const match = useRouteMatch();
  const isAdmin = useIsAdmin();

  const userState = useSelector((state: RootState) => state.user);
  const logoutState = useSelector((state: RootState) => state.logoutloader?.logoutstate);

  const userData = location?.state?.userData;

  const onboardingLater = useSelector((state: RootState) => state.utils.onboarding_later_state);

  useEffect(() => {
    if (!userData) {
      history.goBack();
    }
  }, [history, userData]);

  if (logoutState) {
    return <Spinners fullScreen />;
  }

  if (userState.user?.onBoardingIndex < 3 && !onboardingLater && !isAdmin) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <div className="dashboard-page">
      <ToastContainer />
      <div className="main">
        <UserDataProvider value={userData}>
          <AppNavbar setSupportModalVisibility={setSupportModalVisibility} />
          <Switch>
            <Route exact path={`${match.path}`} component={CardsPageAdmin} />
          </Switch>
        </UserDataProvider>
      </div>
    </div>
  );
};

export default AdminDashAccess;

/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";

import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RootState } from "store";
import * as routes from "../constants/routes";
import About from "./AboutPage";
import AdminPortal from "./AdminPortal/AdminPortal";
import AdminDashAccess from "./AdminUserInfo/AdminUserInfo";
import Contact from "./ContactPage/Contact";
import CRS from "./CRS";
import Dashboard from "./Dashboard";
import FAQPage from "./FAQPage";
import Feedbacks from "./FeedBack/Feedback";
import Footer from "./Footer";
import Home from "./Home";
import Login from "./Login";
import OnBoarding0 from "./OnBoarding/OnBoarding0";
import Onboarding1 from "./OnBoarding/OnBoarding1";
import PrivacyPolicy from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import Signup from "./Signup";
import Spinners from "./Spinners/Spinners";
import TermsPage from "./TermsPage";

const RootRouter: React.FC = () => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const reference: any = useRef();

  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

  const onClickLoginContact = () => {
    if (!reference?.current) {
      localStorage.setItem("navigate", "logincontact");
      window.location.href = "/about";
    }
    window.scrollTo(0, reference.current?.offsetTop);
  };

  useEffect(() => {
    if (localStorage.getItem("navigate") == "logincontact" && reference?.current) {
      onClickLoginContact();
      localStorage.removeItem("navigate");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("navigate"), reference]);

  // set token in redux

  useQuery("token", getAccessTokenSilently, {
    onSuccess(token) {
      dispatch({ type: "UPDATE_TOKEN", payload: token });
    },
  });

  if (isLoading) {
    return <Spinners />;
  }

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Switch>
        <Route exact path={routes.HOME} component={Home} />
        <Route path={routes.SIGNUP} component={Signup} />
        <Route path={routes.LOGIN} component={Login} />
        <Route path={routes.ABOUT} component={() => <About reference={reference} />} />
        <Route path={routes.FAQ} component={FAQPage} />
        <Route path={routes.POLICY} component={PrivacyPolicy} />
        <Route path={routes.TERMS} component={TermsPage} />
        <Route path={routes.CONTACT} component={Contact} />
        <Route path={routes.FEEDBACK} component={Feedbacks} />

        {isAuthenticated && <Route path={routes.DASHBOARD} component={Dashboard} />}
        {isAuthenticated && isAdmin && (
          <Route path={`${routes.ADMIN_USER_INFO}`} component={AdminDashAccess} />
        )}
        {isAuthenticated && isAdmin && <Route path={"/admin"} component={AdminPortal} />}

        {isAuthenticated && <Route path={routes.ONBOARDING} component={OnBoarding0} />}
        {isAuthenticated && <Route path={routes.ONBOARDING1} component={Onboarding1} />}
        {/* {isAuthenticated && <Route path={routes.ONBORDING2} component={OnBoarding2} />}
        {isAuthenticated && <Route path={routes.ONBORDING3} component={Onbording3} />}
        {isAuthenticated && <Route path={routes.ONBORDING6} component={OnBoarding6} />} */}

        <Route path="/crs" component={CRS}></Route>

        <Route path="*" component={Home} />

        <Footer />
      </Switch>
    </Router>
  );
};

export default RootRouter;

import React from "react";
import styles from "./AdminDashboard.module.scss";
import DashboardCard, { DashboardCardProps } from "./card";
import { FiCreditCard, FiUsers } from "react-icons/fi";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";

const AdminDashboard: React.FC = () => {
  const metrics: DashboardCardProps[] = [
    {
      count: 10,
      sub: "30% more users",
      title: "Total Users",
      icon: FiUsers,
    },

    {
      count: 33,
      sub: "20% more transactions",
      title: "Transactions",
      icon: BsFillCreditCard2FrontFill,
      color: "green",
      plus: false,
    },
    {
      count: 10,
      sub: "30% less cards registered",
      title: "Subscribers",
      icon: FiUsers,
      color: "yellow",
    },

    {
      count: 20,
      sub: "30% less cards registered",
      title: "Cards",
      icon: BsFillCreditCard2FrontFill,
      color: "red",
      plus: false,
    },
  ];

  return (
    <div className={styles.dashboard_page}>
      <h4 className={styles.title}>Dashboard</h4>

      <div className={styles.metrics}>
        <h5 className={styles.header}>Metrics</h5>

        <div className={styles.dashboard}>
          {metrics.map((item, index) => (
            <DashboardCard key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

import { combineReducers } from "redux";
import cardsReducer from "./cards";
import productReducer from "./product";
import userReducer from "./users";
import transactionReducer from "./transactions";
import utilityReducer from "./utils";
import cardCarousel from "./cardCarousel";
import logoutloader from "./logoutloader";
import verticalCarousel from "./verticalCarousel";
import perCardClick from "./perCardClick";
import cardToggle from "./cardToggle";

export const appReducer = combineReducers({
  cardToggle: cardToggle,
  cards: cardsReducer,
  products: productReducer,
  user: userReducer,
  transactions: transactionReducer,
  utils: utilityReducer,
  cardCarousel: cardCarousel,
  logoutloader: logoutloader,
  verticalCarousel: verticalCarousel,
  perCardClick: perCardClick,
});

const INITIAL_STATE = appReducer(undefined, { type: "" });

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem("token");
    state.user.token = "";
    localStorage.removeItem("persist:root");
  }
  return appReducer(state, action);
};

export default rootReducer;

import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchStatesAPI } from "services/util";

const useAdministrativeAreas = () => {
  const { data: states } = useQuery("states", async () => {
    const {
      data: { data: states },
    } = await fetchStatesAPI();

    return states.map((state: { name: string; code: string }) => ({
      value: state.code,
      label: state.name,
    }));
  });

  const stateMap = useMemo(() => {
    if (!states) return [];
    return states.reduce((t: Map<string, any>, c: any) => {
      t.set(c.label, c);
      return t;
    }, new Map());
  }, [states]);

  return [states, stateMap];
};

export default useAdministrativeAreas;

/* eslint-disable react/display-name */
import { getUserCards } from "../../api/admin";
import CardDisplayAdmin from "common/CardDisplay/CardDisplayAdmin";
import Spinners from "components/Spinners/Spinners";
import TransactionSectionAdmin from "components/TransactionSection/TransactionSectionAdmin";
import UserDataCtx from "Context/AdminDataAccess";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useQuery } from "react-query";
import { ToastContainer } from "react-toastify";
import "./CardPage.scss";
import { log } from "console";

// import { permissionEnums } from '../AdminPortal/sidebaritems.config';

const CardsPageAdmin: React.FC<any> = () => {
  // const totalCards: IProduct = useSelector((state: RootState) => state.cards.cards);

  const userData = useContext(UserDataCtx);
  const userId = userData?.userId;


  //const nonFundingCards = cards;

  let userDisplayCard = new Array(3).fill(null);

  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
    return () => window.removeEventListener("resize", handleResponsive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [windowWidth, setInnerWidth] = useState(window.innerWidth);

  //initial width
  const wWidth = useMemo(() => window.innerWidth, []);

  const handleResponsive = () => {
    setInnerWidth(window.innerWidth);

    if (wWidth === window.innerWidth) return;
    if (window.innerWidth <= 500) {
      setCarouselMode(true);
    } else {
      setCarouselMode(false);
    }
  };

  const [carouselMode, setCarouselMode] = useState<boolean>(window.innerWidth <= 500);

  const { data: cardResponse, isLoading } = useQuery(["fetch user card", userId], () =>
    getUserCards(userId),
  );

  const userCards = cardResponse?.data;

  const usersName = userCards?.[0]?.name;

  if (isLoading) {
    return <Spinners />;
  }

  userCards &&
    userCards?.map((userCard: any, index: number) => {
      userDisplayCard[index] = userCard;
    });

  return (
    <>
      <section>
        <div className="view">
          <div className="card-section">
            {carouselMode ? (
              <>
                {userCards?.length == 0 ? (
                  <div className="card-display-empty">{/* <h6>No card added</h6> */}</div>
                ) : (
                  <>
                    <Carousel
                      controls={true}
                      indicators={true}
                      interval={null}
                      onSelect={(eventKey: number) => {
                        // console.log(displayCards?.[eventKey]?.cybersourceCustomerPaymentInstrumentId)
                        // const payload = {
                        //   selectedCardId: displayCards?.[eventKey]?.id,
                        //   selectedCyberSourceId:
                        //     displayCards?.[eventKey]?.cybersourceCustomerPaymentInstrumentId,
                        // };
                        // dispatch(selectCard(payload));
                      }}
                    >
                      {userDisplayCard.map((card: any, key: number) => {
                        if (card === null) {
                          return (
                            <Carousel.Item key={`${JSON.stringify(card)}_${key}`}>
                              <div className="card-display-empty">
                                {/* <h6>No card added</h6> */}
                              </div>
                            </Carousel.Item>
                          );
                        } else {
                          return (
                            <Carousel.Item key={`${JSON.stringify(card)}_${key}`}>
                              <CardDisplayAdmin
                                key={`${JSON.stringify(card)}_${key}`}
                                name={card?.name}
                                id={card?.id as string}
                                lastFour={card?.lastFour as any}
                                //   handleEditCard={handleEditCard}
                                brand={card?.brand as any}
                              />
                            </Carousel.Item>
                          );
                        }
                      })}
                    </Carousel>
                  </>
                )}
              </>
            ) : (
              <>
                {userDisplayCard?.map((card: any, key: number) => {
                  if (card === null) {
                    return <div className="card-display-empty"></div>;
                  } else {
                    return (
                      <CardDisplayAdmin
                        key={`${JSON.stringify(card)}_${key}`}
                        name={card?.name}
                        id={card?.id as string}
                        lastFour={card?.lastFour as any}
                        //   handleEditCard={handleEditCard}
                        brand={card?.brand as any}
                      />
                    );
                  }
                })}
              </>
            )}
          </div>

          <TransactionSectionAdmin
            carouselMode={carouselMode}
            windowWidth={windowWidth}
            setCarousalMode={setCarouselMode}
            userData={userData}
            userNameTrx={usersName}
          />
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default CardsPageAdmin;

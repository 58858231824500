/* eslint-disable react/display-name */
import { useAuth0 } from "@auth0/auth0-react";
import AppButton from "common/AppButton";
import AppInput from "common/AppInput";
import * as routes from "constants/routes";
import { getIn, useFormik } from "formik";
//import AppButton from 'common/AppButton';
//import { useFormik } from 'formik';
import { toggleWillDoLaterAction } from "actions/utils";
import { CardBrand, CardBrandReverse } from "constants/cards";
import useFetchUserCards from "hooks/useFetchCards";
import { useFetchUserCtx } from "hooks/useFetchUser";
import _ from "lodash";
import React, { ChangeEvent } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { setOnboardingIndexAPI } from "services/util";
import { RootState } from "store";
import { ICard } from "types";
import getCardBrand from "utils/cardType";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import * as Yup from "yup";
import { postCardQuery } from "../../api/onboarding3";
import "./OnBoarding0.scss";
import "./OnBoardingCheckout.scss";
import { handleError } from "utils/handleError";

interface ICardInputProps {
  editMode: boolean;
  cardValue?: ICard;
  isDefault?: boolean;
}

const TOTAL_ALLOWED_CARDS = 3;

const Onbording3: React.FC<any> = ({ activeStep, setActiveStep }) => {
  let history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const cardPostSuccess = useSelector((state: RootState) => state.cards.cardPostSuccess);
  const userInfo = useSelector((state: RootState) => state.user.user);
  const totalCards = useSelector((state: RootState) => state.cards.cards);
  let totalCardCount: any = totalCards.length;

  const defaultCards = totalCards
    .filter((card: any) => !card.isDefault)
    .map(({ expirationMonth, expirationYear, address, lastFour, brand }: Record<string, any>) => {
      const expiryYear = expirationYear.toString().substring(2);
      const expirationDate = `${expirationMonth}/${expiryYear}`;

      return {
        name: user?.name,
        new: false,
        expirationDate,
        lastFour,
        brand,
        address,
      };
    });

  const refetchUser = useFetchUserCtx();
  const refetchCards = useFetchUserCards();

  const formikInitialValues = {
    cards: defaultCards,
  };

  if (totalCardCount < TOTAL_ALLOWED_CARDS) {
    formikInitialValues.cards.push({
      name: user?.name || "",
      number: "",
      expirationDate: "",
      cvc: "",
      address: userInfo.address,
      brand: "",
      new: true,
    });
  }

  const { mutateAsync: postCardWithReactQuery, isLoading: isLoading } = useMutation(postCardQuery);

  const formik = useFormik({
    initialValues: formikInitialValues as any,
    enableReinitialize: true,
    validationSchema: Yup.object({
      cards: Yup.array()
        .of(
          Yup.object().shape({
            number: Yup.string().when("new", {
              is: true,
              then: Yup.string().required("Card Number is required"),
            }),
            expirationDate: Yup.string()
              .matches(/^(0[1-9]|1[0-2])\/[0-9]{2}$/, "Invalid date format")
              .test("validity", "Invalid Date", (value: any) => {
                if (!value) {
                  return false;
                }
                const month = parseInt(value.slice(0, 2), 10);
                const year = parseInt(value.slice(-2), 10) + 2000;
                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth() + 1;
                if (year < currentYear || (year === currentYear && month < currentMonth)) {
                  return false; // expiration date is in the past
                }
                if (month < 1 || month > 12) {
                  return false; // invalid month number
                }
                return true;
              })
              .required("Expiry Date is required"),
            cvc: Yup.string().when("new", {
              is: true,
              then: Yup.string().required("CVC is required"),
            }),
            brand: Yup.string().when("new", {
              is: true,
              then: Yup.string().required("Card brand in required"),
            }),
          }),
        )
        .min(1, "Atleast one card is required"),
    }),
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values: typeof formikInitialValues) => {
    if (!formik.dirty) {
      setActiveStep(2);
    }

    let token = await getAccessTokenSilently();

    const newCards = values?.cards?.filter((card: any) => card.new);

    const promiseList = newCards.map((value: any, key: number) => {
      // let expYear = value?.expirationDate?.getFullYear();
      // let expMonth = value?.expirationDate?.getMonth() + 1;

      let expYear = `20${value?.expirationDate?.split("/")[1]}`;
      let expMonth = value?.expirationDate?.split("/")[0];

      const requestBody: ICard = {
        number: value.number.replace(/\s/g, ""),
        expirationMonth: parseInt(expMonth, 10),
        expirationYear: parseInt(expYear, 10),
        cvc: parseInt(value.cvc as string, 10),
        isDefault: false,
        address: value.address,
        brand: value.brand,
      };

      if (user?.sub) {
        // dispatch(postCard(user?.sub, requestBody, token));
        return handlePostValues(user?.sub, requestBody, token);
      }
    });
    const res = await Promise.all(promiseList);

    if (user?.sub && !res.some((x) => !x)) {
      let response = await setOnboardingIndexAPI(user.sub, token, activeStep + 1);
      if (response && response.data && checkResponseStatus(response.data.httpCode)) {
        refetchCards();
        refetchUser();
        handleSuccess("Cards Added");
        setTimeout(() => {
          setActiveStep(2);
        }, 1000);
      }
    } else {
      handleError("Card Add Failed");
    }
  };
  const handlePostValues = async (userSub: any, values: any, token: any) =>
    postCardWithReactQuery({ userId: userSub, values: values, token: token });

  const ErrorMessage = ({ errors, touched, index, name }: any) =>
    !_.isEmpty(touched.cards) &&
    !_.isEmpty(errors.cards) &&
    touched.cards[index] &&
    errors.cards[index] &&
    touched.cards[index][name] &&
    errors.cards.length > 0 &&
    typeof errors?.cards[index][name] === "string" ? (
      <div className="invalid-feedback-text">
        {getIn(formik.errors, `cards[${index}][${name}]`)}
      </div>
    ) : null;
  let copiedCardsVal = [...formik?.values?.cards];

  const handleCardRemove = (index: number) => {
    copiedCardsVal.splice(index, 1);

    formik.setFieldValue("cards", [...copiedCardsVal]);
  };
  return (
    <div className="onboarding-space">
      <h3 className="onboarding-text">Add your card/s</h3>
      <div className="onboarding-text-1 onboarding-space3">Add up to 3 credit cards</div>
      <div className="box3">
        {/* <div className="card1"></div> */}
        {formik.values.cards.map((card: any, index: number) => {
          return (
            <Form className="card3-spc" key={index}>
              {card.new && (
                <div
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => {
                    handleCardRemove(index);
                  }}
                >
                  X
                </div>
              )}
              <h6>Card {`${index + 1}`}</h6>
              <Form.Group as={Row}>
                <Form.Label>Name on the card</Form.Label>
                <Col sm="12">
                  <AppInput
                    name={`cards[${index}].name`}
                    placeholder="Eg. John Doe"
                    value={formik.values.cards[index].name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!card.new}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label>Card Number</Form.Label>
                <Col sm="12">
                  <AppInput
                    placeholder={
                      card?.lastFour
                        ? `****************${card?.lastFour}`
                        : ` Your 16 digit card number`
                    }
                    name={`cards[${index}].number`}
                    value={formik.values?.cards[index]?.number}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      let value = e.target.value;
                      const brand = getCardBrand(value);
                      formik.setFieldValue(`cards[${index}].brand`, CardBrand?.[brand]);
                      value = value
                        .replace(/[^\dA-Z]/g, "")
                        .replace(/(.{4})/g, "$1 ")
                        .trim();
                      e.target.value = value;
                      return formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    pattern="[0-9\s]{13,17}"
                    maxLength={19}
                    autoComplete="cc-number"
                    inputMode="numeric"
                    type="tel"
                    // onKeyDown={(e: any) => {
                    //   const value = e.target.value
                    //     .replace(/[^\dA-Z]/g, "")
                    //     .replace(/(.{4})/g, "$1")
                    //     .trim();
                    //   formik.setFieldValue(`cards[${index}].number`, value);
                    // }}
                    adornmentText={_.startCase(CardBrandReverse?.[card?.brand])}
                    disabled={!card.new}
                  />

                  <ErrorMessage
                    errors={formik.errors}
                    touched={formik.touched}
                    index={index}
                    name="number"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="6">
                  <Form.Label>Expiry Date</Form.Label>
                  <Col sm="12" className="p-0">
                    <AppInput
                      id="valid"
                      type="text"
                      maxLength={5}
                      name={`cards[${index}].expirationDate`}
                      placeholder="MM/YY"
                      value={formik.values.cards[index].expirationDate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!card.new}
                      onKeyDown={(e: any) => {
                        if (e.key !== "Backspace" && e.key !== "/") {
                          if (e.target.value.length === 2) {
                            formik.setFieldValue(
                              `cards[${index}].expirationDate`,
                              `${e.target.value.replace(/(.{2})/g, "$1/").trim()}`,
                            );
                          }
                        }
                      }}
                    />

                    <ErrorMessage
                      errors={formik.errors}
                      touched={formik.touched}
                      index={index}
                      name="expirationDate"
                    />
                  </Col>
                </Col>
                <Col sm="6">
                  <Form.Label>CVC</Form.Label>
                  <Col sm="12" className="p-0">
                    <AppInput
                      id="cvc"
                      name={`cards[${index}].cvc`}
                      type="tel"
                      inputMode="numeric"
                      maxLength={4}
                      placeholder="_ _ _ _"
                      value={formik.values?.cards[index]?.cvc}
                      onChange={(e: any) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9/]/g, "");
                        e.target.value = value;
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      disabled={!card.new}
                    />

                    <ErrorMessage
                      errors={formik.errors}
                      touched={formik.touched}
                      index={index}
                      name="cvc"
                    />
                  </Col>
                </Col>
              </Form.Group>
            </Form>
          );
        })}
        <div className="later-btn">
          {/* if(totalCardCount<2){

            } */}
          {formik.values.cards?.length < TOTAL_ALLOWED_CARDS ? (
            <AppButton
              className="borderless secondary"
              onClick={(e: any) => {
                //setAddCardModal(true);

                formik.setFieldValue("cards", [
                  ...formik.values.cards,
                  {
                    name: user?.name || "",
                    number: "",
                    expirationDate: "",
                    cvc: "",
                    address: userInfo.address,
                    new: true,
                  },
                ]);
              }}
            >
              + Add new Card
            </AppButton>
          ) : null}
        </div>

        <div className="onboarding3-btn">
          <AppButton onClick={() => setActiveStep(0)} className="primary outlined mr-1">
            Go Back
          </AppButton>

          <AppButton
            className="primary"
            onClick={formik.handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Continue
          </AppButton>
        </div>
        <BrowserRouter>
          <div className="onboarding-text-2">
            <AppButton
              className="borderless primary"
              onClick={() => {
                dispatch(toggleWillDoLaterAction());
                history.push(routes.DASHBOARD);
              }}
            >
              I will do this later
            </AppButton>
          </div>
        </BrowserRouter>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Onbording3;

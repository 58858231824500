export interface ICard {
  number: string;
  expirationMonth: number | string;
  expirationYear: number | string;
  cvc?: number | string;
  id?: string;
  cardId?: string;
  lastFour?: string;
  brand?: string;
  isDefault?: boolean | string;
  randomBytes?: string;
  address?: {
    address1: string;
    address2: string;
    locality: string;
    administrativeArea: string;
    country: string;
    postalCode: string;
  };
}

export const FETCH_CARDS_REQUEST = "FETCH_CARDS_REQUEST";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_ERROR = "FETCH_CARDS_ERROR";
export const FETCH_CARDS_RESET = "FETCH_CARDS_RESET";

export const POST_CARDS_REQUEST = "POST_CARDS_REQUEST";
export const POST_CARDS_SUCCESS = "POST_CARDS_SUCCESS";
export const POST_CARDS_ERROR = "POST_CARDS_ERROR";
export const POST_CARDS_RESET = "POST_CARDS_RESET";

export const REMOVE_CARDS_REQUEST = "REMOVE_CARDS_REQUEST";
export const REMOVE_CARDS_SUCCESS = "REMOVE_CARDS_SUCCESS";
export const REMOVE_CARDS_ERROR = "REMOVE_CARDS_ERROR";
export const REMOVE_CARDS_RESET = "REMOVE_CARDS_RESET";

export const UPDATE_CARDS_REQUEST = "UPDATE_CARDS_REQUEST";
export const UPDATE_CARDS_SUCCESS = "UPDATE_CARDS_SUCCESS";
export const UPDATE_CARDS_ERROR = "UPDATE_CARDS_ERROR";
export const UPDATE_CARDS_RESET = "UPDATE_CARDS_RESET";

export const HOME = "/";
export const SIGNUP = "/signup";
export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";
export const ACCOUNT = "/dashboard/account";
export const SUPPORT = "/support";
export const SUPPORTMOBILE = "/supportmobile";
export const SUBSCRIPTION = "/dashboard/subscription";
export const CHECKOUT = "/dashboard/checkout";
export const ABOUT = "/about";

export const ADMIN_HOME = "/";

export const ONBOARDING = "/onboarding";
// export const ONBOARDING4='/dashboard/onboarding/onboarding4'
// export const ONBOARDING5='/dashboard/onboarding/onboarding5'
// export const ONBOARDING6='/dashboard/onboarding/onboarding6'ankur code
export const ONBOARDING1 = "/onboarding1";
export const ONBORDING2 = "/onboarding2";
export const ONBORDING3 = "/onboarding3";
export const ONBORDING4 = "/onboarding3";
export const ONBORDING6 = "/onboardingfinal";

//admin
export const ADMIN = "/admin";
export const USERS = "/admin/users";
export const CARDINFO = "/admin/cardinfo";
export const LOG = "/admin/log";
export const TRANSACTION = "/admin/transaction";
export const ADMIN_USER_INFO = "/admin/user-info";

export const FAQ = "/faq";
export const POLICY = "/policy";
export const TERMS = "/terms";

export const CONTACT = "/contact";

export const FEEDBACK = "/feedback";

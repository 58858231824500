import { LOG_OUT_LOADER } from "types/ILogOut";
import { string } from "yup/lib/locale";
// import { ILogoutLoader } from "actions/logoutloader";

type LOGOUTLOADER_STATE = {
  logoutstate: boolean;
};

const INITIAL_STATE: LOGOUTLOADER_STATE = {
  logoutstate: false,
};

export interface actionType {
  type: string;
  payload?: any;
}

export default (state: LOGOUTLOADER_STATE = INITIAL_STATE, action: actionType) => {
  switch (action.type) {
    case LOG_OUT_LOADER: {
      return { ...state, logoutstate: true };
    }
    default:
      return state;
  }
};

import { IAction } from 'types';
import { PREVENT_TOGGLE, TOGGLE_SIDEBAR } from 'types/ISidebar';
import { TOGGLE_LATER_STATE } from 'types/IWillDoLater';
import { TOGGLE_SIDEBAR_HOME } from 'types/Isidebarhome';
import { OPEN_SUPPORT_MOBILE } from 'types/ISupport';

export interface IUtitlityAction extends IAction {
  type: string;
  payload?: any;
}

export const toggleSidebarAction = (): IUtitlityAction => ({
  type: TOGGLE_SIDEBAR,
});

export const toggleWillDoLaterAction = (): IUtitlityAction => ({
  type: TOGGLE_LATER_STATE,
});

export const toggleSidebarHomeAction = (): IUtitlityAction => ({
  type: TOGGLE_SIDEBAR_HOME,
});

export const preventToggleAction = (payload: boolean): IUtitlityAction => ({
  type: PREVENT_TOGGLE,
  payload: payload,
});

export const openSupportMobile=():IUtitlityAction=>({
  type:OPEN_SUPPORT_MOBILE
})

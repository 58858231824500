import { IUserAction } from "actions/users";
import {
  FETCH_USER_ERROR,
  FETCH_USER_REQUEST,
  FETCH_USER_RESET,
  FETCH_USER_SUCCESS,
  IUser,
  UPDATE_USER_ERROR,
  UPDATE_USER_PERMISSION,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_TYPE,
} from "types/IUser";

type USER_STATE = {
  user: IUser | null;
  loading: boolean;
  error: string;
  loggedIn: boolean;
  userFetchSuccess: boolean;
  userUpdateSuccess: boolean;
  userUpdateLoading: boolean;
  isAdmin: boolean;
  permissionList: string[];
  permissions: Record<string, string>;
  token: string;
};

const INITIAL_STATE: USER_STATE = {
  user: null,
  loading: false,
  loggedIn: false,
  error: "",
  userFetchSuccess: false,
  userUpdateSuccess: false,
  userUpdateLoading: false,
  isAdmin: false,
  permissionList: [],
  permissions: {},
  token: "",
};

export default (state: USER_STATE = INITIAL_STATE, action: IUserAction) => {
  switch (action.type) {
    case FETCH_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_USER_SUCCESS: {
      return { ...state, loading: false, userFetchSuccess: true, ...action.payload };
    }
    case FETCH_USER_ERROR: {
      return { ...state, loading: false, error: action.payload, userFetchSucces: false };
    }
    case FETCH_USER_RESET: {
      return { ...state, loading: false, error: "", userFetchSucces: false };
    }
    case UPDATE_USER_REQUEST: {
      return { ...state, userUpdateLoading: true };
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        userUpdateLoading: false,
        userUpdateSuccess: false,
        error: action.payload,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return { ...state, userUpdateLoading: false, userUpdateSuccess: true };
    }
    case UPDATE_USER_RESET: {
      return { ...state, userUpdateLoading: false, userUpdateSuccess: false, error: "" };
    }
    case UPDATE_USER_TYPE: {
      return { ...state, isAdmin: action.payload };
    }
    case UPDATE_USER_PERMISSION: {
      return { ...state, ...action.payload };
    }
    case "UPDATE_TOKEN": {
      return { ...state, token: action.payload };
    }
    default:
      return state;
  }
};

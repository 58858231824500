/* eslint-disable react/display-name */
import { useAuth0 } from "@auth0/auth0-react";
import { fetchCards } from "actions";
import { selectCard } from "actions/cardCarousel";
import { fetchUser } from "actions/users";
import adCard from "assets/img/Icon - Default.png";
import AppButton from "common/AppButton";
import CardDisplay from "common/CardDisplay";
import CardInput from "common/CardInput";
import Spinners from "components/Spinners/Spinners";
import TransactionSection from "components/TransactionSection";
import useFetchUser from "hooks/useFetchUser";
import React, { useEffect, useMemo, useState } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RootState } from "store";
import { ICard } from "types";
import "./CardPage.scss";

// import { permissionEnums } from '../AdminPortal/sidebaritems.config';

const CardsPage: React.FC<any> = () => {
  const [addCardModal, setAddCardModal] = useState(false);
  const [editCardModal, setEditCardModal] = useState(false);
  const [filterDetails, setFilterDetails] = useState({});
  // const totalCards: IProduct = useSelector((state: RootState) => state.cards.cards);

  // console.log("context", userData);
  const { user, getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState("");

  const cards = useSelector((state: RootState) => state.cards.cards);

  const toggle = useSelector((state: RootState) => state?.cardToggle?.isOn);

  const cardFetchSuccess = useSelector((state: RootState) => state.cards.cardFetchSuccess);
  const cardRemoveSuccess = useSelector((state: RootState) => state.cards.cardRemoveSuccess);
  const cardUpdateSuccess = useSelector((state: RootState) => state.cards.cardUpdateSuccess);
  const cardPostSuccess = useSelector((state: RootState) => state.cards.cardPostSuccess);
  const cardPostLoading = useSelector((state: RootState) => state.cards.loading);
  // const cardPostError = useSelector((state: RootState) => state.cards.error);

  let displayCards = !toggle ? new Array(3).fill(null) : new Array(4).fill(null);
  const [show, setShow] = useState(false);
  useEffect(() => {}, []);
  const nonFundingCards = !toggle
    ? cards?.filter((card: ICard) => {
        return card.isDefault === false;
      })
    : cards;

  //const nonFundingCards = cards;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  nonFundingCards?.map((card: any, index: number) => {
    displayCards[index] = nonFundingCards[index];
  });

  useEffect(() => {
    window.addEventListener("resize", handleResponsive);
    return () => window.removeEventListener("resize", handleResponsive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [windowWidth, setInnerWidth] = useState(window.innerWidth);

  //window initial width
  const wWidth = useMemo(() => window.innerWidth, []);

  const handleResponsive = () => {
    setInnerWidth(window.innerWidth);

    if (wWidth === window.innerWidth) return;

    if (window.innerWidth <= 500) {
      setCarouselMode(true);
    } else {
      setCarouselMode(false);
    }
  };

  useFetchUser();

  useEffect(() => {
    getAccessTokenSilently()
      .then((token: any) => {
        if (token) {
          //console.log(user, token);
          localStorage.setItem("token", token);
          if (user && user?.sub) {
            dispatch(fetchCards(user?.sub));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, getAccessTokenSilently, user]);

  useEffect(() => {
    if (cardPostSuccess || cardUpdateSuccess || cardRemoveSuccess) {
      setAddCardModal(false);
      setEditCardModal(false);
    }
  }, [cardPostSuccess, cardUpdateSuccess, cardRemoveSuccess]);

  // useEffect(() => {
  //   dispatch(fetchCards(user?.sub));
  // }, [user, dispatch]);

  useEffect(() => {
    if (cardFetchSuccess) setAddCardModal(false);
  }, [cardFetchSuccess]);

  useEffect(() => {
    if (cardRemoveSuccess || cardUpdateSuccess) setEditCardModal(false);
  }, [cardRemoveSuccess, cardUpdateSuccess]);

  // useEffect(() => {
  //   if (window.innerWidth <= 420) {
  //     console.log('code runs ', cards?.[0]?.cybersourceCustomerPaymentInstrumentId);
  //     dispatch(filterByCard(cards?.[0]?.cybersourceCustomerPaymentInstrumentId));
  //   }
  // }, [cards, dispatch]);

  const [carouselMode, setCarouselMode] = useState<boolean>(window.innerWidth <= 500);

  const [declineBanner, setDeclineBanner] = useState(false);

  const handleEditCard = (id: string) => {
    setSelectedCard(id);
    setEditCardModal(true);
  };

  if (cardPostLoading) {
    return <Spinners fullScreen />;
  }

  const renderAddCardModal = () => (
    <Modal
      backdrop="static"
      show={addCardModal}
      onHide={() => setAddCardModal(false)}
      onBackdropClick={() => setAddCardModal(false)}
      animation={false}
      border
    >
      <Modal.Body id="theIdHere">
        <div className="d-flex flex-row justify-content-between">
          <h5>Add your Credit Card</h5>
          <AppButton className="borderless primary" onClick={() => setAddCardModal(false)}>
            <span className="iconify" data-icon="uil-multiply" data-inline="false"></span>
          </AppButton>
        </div>

        <CardInput
          editMode={false}
          onClose={() => {
            setAddCardModal(false);
          }}
        />
      </Modal.Body>
    </Modal>
  );

  const renderEditCardModal = () => (
    <Modal
      backdrop="static"
      show={editCardModal}
      onHide={() => setEditCardModal(false)}
      animation={false}
    >
      <Modal.Body>
        <div className="d-flex flex-row justify-content-between">
          <h5>Edit Credit Card</h5>
          <AppButton className="borderless primary" onClick={() => setEditCardModal(false)}>
            <span className="iconify" data-icon="uil-multiply" data-inline="false"></span>
          </AppButton>
        </div>

        <CardInput
          editMode={true}
          cardValue={cards?.find((card: ICard) => card.id === selectedCard)}
        />
      </Modal.Body>
    </Modal>
  );

  return (
    <div>
      <ToastContainer />

      {renderAddCardModal()}
      {renderEditCardModal()}

      {carouselMode
        ? nonFundingCards?.length === 0 && (
            <div className="banner">
              <div className="left">
                Welcome to Pria. Add your first<br></br> card to get started.
              </div>
              <div className="right">
                {/* <AppButton className="outlined secondary" onClick={() => setAddCardModal(true)}>
              Add Card
            </AppButton>  */}
                <img
                  src={adCard}
                  style={{ width: 70, height: 70 }}
                  alt="add card"
                  onClick={() => setAddCardModal(true)}
                />
              </div>
            </div>
          )
        : show
        ? nonFundingCards?.length === 0 && (
            <div className="banner">
              <div className="left">Welcome to Pria. Add your first card to get started.</div>
              <div className="right">
                <AppButton className="outlined secondary" onClick={() => setAddCardModal(true)}>
                  Add Card
                </AppButton>
                {/* <img src={adCard} style={{width:70,height:70}} alt="add card" onClick={() => setAddCardModal(true)}/> */}
              </div>
            </div>
          )
        : null}
      <section>
        <div className="view">
          <div className="card-section">
            <div className="heading">
              <h5>Cards</h5>

              {nonFundingCards?.length < 3 && (
                <div className="card-mar">
                  <AppButton className="borderless secondary" onClick={() => setAddCardModal(true)}>
                    + Add new Card
                  </AppButton>
                </div>
              )}
            </div>

            {carouselMode ? (
              <>
                <Carousel
                  controls={true}
                  indicators={true}
                  interval={null}
                  onSelect={(eventKey: number) => {
                    const payload = {
                      selectedCardId: displayCards?.[eventKey]?.id,
                      selectedCyberSourceId:
                        displayCards?.[eventKey]?.cybersourceCustomerPaymentInstrumentId,
                    };

                    dispatch(selectCard(payload));
                  }}
                >
                  {displayCards.map((card: any, key: number) => {
                    if (card === null) {
                      return (
                        <Carousel.Item key={`${JSON.stringify(card)}_${key}`}>
                          <div className="card-display-empty" onClick={() => setAddCardModal(true)}>
                            {/* <h6>No card added</h6> */}
                          </div>
                        </Carousel.Item>
                      );
                    } else {
                      return (
                        <Carousel.Item key={`${JSON.stringify(card)}_${key}`}>
                          <CardDisplay
                            key={key}
                            id={card?.id as string}
                            lastFour={card?.lastFour as any}
                            handleEditCard={handleEditCard}
                            brand={card?.brand as any}
                          />
                        </Carousel.Item>
                      );
                    }
                  })}
                </Carousel>
              </>
            ) : (
              <>
                {displayCards?.map((card: any, key: number) => {
                  if (card === null) {
                    return (
                      <div
                        className="card-display-empty"
                        key={`${JSON.stringify(card)}_${key}`}
                        onClick={() => setAddCardModal(true)}
                      ></div>
                    );
                  } else {
                    return (
                      <CardDisplay
                        key={`${JSON.stringify(card)}_${key}`}
                        id={card?.id as string}
                        lastFour={card?.lastFour as any}
                        handleEditCard={handleEditCard}
                        brand={card?.brand as any}
                      />
                    );
                  }
                })}
              </>
            )}
          </div>
          <TransactionSection
            carouselMode={carouselMode}
            windowWidth={windowWidth}
            setDeclineBanner={setDeclineBanner}
            filterDetails={filterDetails}
            setCarousalMode={setCarouselMode}
          />
        </div>
      </section>
    </div>
  );
};

export default CardsPage;

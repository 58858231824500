import { IAction } from "types";
import {
  ALL_CARDS_DATA,
  ALL_TRANSACTIONS,
  CARD_SELECTED,
  FILTER_ALL_CARDS,
  FILTER_CARDS,
} from "types/ICardCarousel";

export interface ICardCarousel extends IAction {
  type: string;
  payload?: any;
}

export const setSelectedCardId = (cardId: string) => {
  return (dispatch: any) => {
    // console.log(cardId, "action creator");
    dispatch({
      type: "CARD_SELECTED",
      payload: cardId,
    });
  };
};

export interface CarouselCard {
  id: string;
  name: string;
  lastFour: string;
  expirationMonth: string;
  expirationYear: number;
  brand: string;
  isDefault: string;
  cybersourceCustomerPaymentInstrumentId: string;
  address: Address;
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
}
export const setAllCardsData = (payload: CarouselCard[]) => {
  return (dispatch: any) => {
    dispatch({
      type: ALL_CARDS_DATA,
      payload: payload,
    });
  };
};

export interface Transactions {
  paymentIntent: PaymentIntent;
}

export interface PaymentIntent {
  paymentIntentId: string;
  amount: string;
  cardId: string;
  created: number;
  messages: string;
  status: string;
  cardDetails: string;
  cancellationReason: null;
  name: string;
  remarks: string;
}

export const setAllTransactions = (payload: Transactions[]) => {
  return (dispatch: any) => {
    dispatch({
      type: ALL_TRANSACTIONS,
      payload,
    });
  };
};

export const filterByCard = (payload: {
  selectedCardId: string;
  selectedCyberSourceId: string;
}) => {
  return (dispatch: any) =>
    dispatch({
      type: FILTER_CARDS,
      payload,
    });
};

export const filterAllCards = (payload: any) => {
  return (dispatch: any) => {
    dispatch({
      type: FILTER_ALL_CARDS,
      payload,
    });
  };
};

export const selectCard = (payload: { selectedCardId: string; selectedCyberSourceId: string }) => {
  return (dispatch: any) =>
    dispatch({
      type: CARD_SELECTED,
      payload,
    });
};

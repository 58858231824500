import {
  ALL_CARDS_DATA,
  ALL_TRANSACTIONS,
  CARD_SELECTED,
  FILTERED_VIEW,
  FILTER_ALL_CARDS,
  FILTER_CARDS,
} from "types/ICardCarousel";
import { CarouselCard, ICardCarousel, Transactions } from "../actions/cardCarousel";

type CARDCAROUSEL_STATE = {
  selectedCardId: string;
  selectedCyberSourceId: string;
  allCardsData: Record<string, any>;
  allTransactions: Array<Transactions>;
  filteredView: Array<any>;
  selectedFilter: string;
};

const INITIAL_STATE: CARDCAROUSEL_STATE = {
  selectedCardId: "",
  selectedCyberSourceId: "",
  allCardsData: {},
  allTransactions: [],
  filteredView: [],
  selectedFilter: "All Time",
};

const filterTransactions = (
  transactions: Transactions[],
  cardIds: { selectedCardId?: string; selectedCyberSourceId?: string },
  selectedFilter: string,
) => {
  switch (selectedFilter) {
    default:
      return transactions.filter(
        (txn) =>
          txn?.paymentIntent?.cardId === cardIds.selectedCardId ||
          txn?.paymentIntent?.cardId === cardIds.selectedCyberSourceId,
      );
  }
};

export default (state: CARDCAROUSEL_STATE = INITIAL_STATE, action: ICardCarousel) => {
  const tempState = { ...state };

  switch (action.type) {
    case CARD_SELECTED: {
      // console.log(action.payload)
      const { selectedCardId, selectedCyberSourceId } = action.payload;
      return { ...state, selectedCardId, selectedCyberSourceId };
    }
    case ALL_CARDS_DATA: {
      //convert card array to card map

      //temporary card object
      let cardMap: Record<string, CarouselCard> = {};
      //get card array from payload
      const cardList = action.payload as CarouselCard[];
      //iterate card list and update entries in card object
      cardList.forEach((card) => {
        if (card.cybersourceCustomerPaymentInstrumentId)
          cardMap[card.cybersourceCustomerPaymentInstrumentId] = card;
      });

      const cardListWithId = cardList?.filter((i) => i.cybersourceCustomerPaymentInstrumentId);

      //update state
      return {
        ...state,
        allCardsData: cardMap,
        selectedCardId: cardListWithId?.[0]?.id,
        selectedCyberSourceId: cardListWithId?.[0]?.cybersourceCustomerPaymentInstrumentId,
      };
    }
    case ALL_TRANSACTIONS: {
      const selectedCardId = state.selectedCardId;
      const selectedCyberSourceId = state.selectedCyberSourceId;

      if (selectedCardId) {
        const filteredView = filterTransactions(
          action.payload || [],
          { selectedCardId, selectedCyberSourceId },
          "",
        );

        return { ...state, allTransactions: action.payload, filteredView };
      }

      return { ...state, allTransactions: action.payload, filteredView: action.payload };
    }
    case FILTERED_VIEW: {
      return { ...state, filteredView: action.payload };
    }

    case FILTER_CARDS: {
      const selectedCardId = action.payload?.selectedCardId;
      const selectedFilter = state.selectedFilter;

      const filteredTransactions = filterTransactions(
        state.allTransactions || [],
        { selectedCardId },
        selectedFilter,
      );

      return {
        ...state,
        filteredView: filteredTransactions,
        selectedCardId: action.payload.selectedCardId,
        selectedCyberSourceId: action.payload.selectedCyberSourceId,
      };
    }
    case FILTER_ALL_CARDS: {
      // console.log(action.payload);

      return { ...state, filteredView: action.payload };
    }
    default:
      return state;
  }
};

import * as urls from "constants/urls";
import http from "./http";

export const fetchProductsAPI = (userId: string, token: string) => {
  return http.get(`${urls.PLANS}`);
};

export const subscribeProductAPI = (
  subscriptionId: string,
  planId: number,
  cardId: number,
  isEnabled = true,
) => {
  // http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.put(`${urls.SUBSCRIPTIONS}/${subscriptionId}`, {
    plan: planId,
    card: cardId,
    isEnabled,
  });
};
export const subscribeProductRemoveAPI = (
  subscriptionId: string,
  planId: number,
  cardId: null,
  isEnabled = true,
) => {
  // http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http.put(`${urls.SUBSCRIPTIONS}/${subscriptionId}`, {
    plan: planId,
    card: cardId,
    isEnabled,
  });
};

export const fetchSubscribedProductAPI = () => {
  return http.get(`${urls.SUBSCRIPTIONS}`);
};

export const pauseSubscriptionAPI = (
  subscriptionId: string,
  planId: number,
  cardId: number,
  isEnabled: boolean,
) => {
  return http.put(`${urls.SUBSCRIPTIONS}/${subscriptionId}`, {
    plan: planId,
    card: cardId,
    isEnabled,
  });
};

import AppButton from "common/AppButton";
import React from "react";
import { FormControl, FormControlProps, InputGroup } from "react-bootstrap";

interface IAppInputProps
  extends FormControlProps,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onChange?: any;
  value?: any;
  size?: any;
  type?: any;
  fullwidth?: boolean;
  placeholder?: string;
  adornmentLabel?: string;
  adornmentText?: string;
  onButtonClick?: any;
  buttonClassName?: string;
  children?: any;
}

const AppInput: React.FC<IAppInputProps> = ({
  adornmentText,
  adornmentLabel,
  buttonClassName,
  onButtonClick,
  ...props
}: IAppInputProps) => {
  return (
    <InputGroup className="mb-3">
      {adornmentText && <InputGroup.Text>{adornmentText}</InputGroup.Text>}
      <FormControl {...props} />

      {adornmentLabel && (
        <InputGroup.Append>
          <AppButton
            className={buttonClassName || "primary"}
            style={{ margin: "0", borderRadius: "0px 8px 8px 0px", padding: "0 1rem" }}
            onClick={(e: any) => {
              e.preventDefault();
              onButtonClick?.();
            }}
          >
            {adornmentLabel}
          </AppButton>
          {/* <Button variant="primary">Button</Button> */}
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};

export default AppInput;

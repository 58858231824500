export interface IProduct {
  taxPercentage?: any;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt: string | null;
  version: number;
  name: string;
  description: string;
  price: number;
  interval: string;
  intervalCount: number;
  currency: string;
}

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR";
export const FETCH_PRODUCT_RESET = "FETCH_PRODUCT_RESET";

export const SUBSCRIBE_PRODUCT_REQUEST = "SUBSCRIBE_PRODUCT_REQUEST";
export const SUBSCRIBE_PRODUCT_SUCCESS = "SUBSCRIBE_PRODUCT_SUCCESS";
export const SUBSCRIBE_PRODUCT_ERROR = "SUBSCRIBE_PRODUCT_ERROR";
export const SUBSCRIBE_PRODUCT_RESET = "SUBSCRIBE_PRODUCT_RESET";

export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const SELECT_PRODUCT_RESET = "SELECT_PRODUCT_RESET";

export const FETCH_SUBSCRIBED_REQUEST = "FETCH_SUBSCRIBED_REQUEST";
export const FETCH_SUBSCRIBED_SUCCESS = "FETCH_SUBSCRIBED_SUCCESS";
export const FETCH_SUBSCRIBED_ERROR = "FETCH_SUBSCRIBED_ERROR";
export const FETCH_SUBSCRIBED_RESET = "FETCH_SUBSCRIBED_RESET";

export const PAUSE_SUBSCRIPTION_REQUEST = "PAUSE_SUBSCRIPTION_REQUEST";
export const PAUSE_SUBSCRIPTION_SUCCESS = "PAUSE_SUBSCRIPTION_SUCCESS";
export const PAUSE_SUBSCRIPTION_ERROR = "PAUSE_SUBSCRIPTION_ERROR";
export const PAUSE_SUBSCRIPTION_RESET = "PAUSE_SUBSCRIPTION_RESET";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import AppInput from "common/AppInput";
import { FormikProvider, useFormik } from "formik";
import { useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API_URL } from "services/http";
import { RootState } from "store";
import * as Yup from "yup";
import "./Support.scss";

function Support({ onSubmit }: any) {
  const { user, isAuthenticated } = useAuth0();
  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const sidebar = useSelector((state: RootState) => state.utils.sidebar_state);
  const [topic, setTopic] = useState("");
  let first_name = user?.name;
  let f_name = first_name?.split(" ")[0];
  // console.log(user?.email);
  const reference = useRef<HTMLInputElement>(null);

  function handleType(e: any) {
    setTopics(e.target.value);
  }

  const [topics, setTopics] = useState("");

  const addingAndRemovingCards = () => {
    setTopics("Adding or Removing Cards");
  };

  const BillingAndPlans = () => {
    setTopics("Billing and Plans");
  };
  const security = () => {
    setTopics("Security");
  };
  const Notifications = () => {
    setTopics("Notifications");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email,
      type: topics || "",
      message: "",
      // topic: topics || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      // type: Yup.string().required("Required"),
      message: Yup.string().required("Message is required"),
      type: Yup.string().required("Topic is required"),
    }),
    onSubmit: async (values: any) => {
      handleSupport(values);
    },
  });

  const handleSupport = async (values: any) => {
    await axios
      .post(`${API_URL}/support/contact-us`, { ...values })
      .then((response) => {
        toast("Message Sent Successfully");
        onSubmit?.();
      })
      .catch((error) => {
        toast("Sorry Something Went Wrong");
        console.log(error);
      });
  };

  return (
    <div className="page">
      <div className="page-header">
        <div className="mainn">
          <div className="user_greetings">
            <h4>Support</h4>
            <h6>Hi {f_name}! How can we help you?</h6>
          </div>

          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              Select a topic
              <div className="topics__list">
                <div
                  className={
                    topics === "Adding or Removing Cards" ? "md-text-bolds" : "md-text-bold"
                  }
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={addingAndRemovingCards}
                >
                  {" "}
                  Adding or Removing cards
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Billing and Plans" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={BillingAndPlans}
                >
                  {" "}
                  Billing and Plans
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Security" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={security}
                >
                  {" "}
                  Security
                </div>
                <div
                  tabIndex={0}
                  className={topics === "Notifications" ? "md-text-bolds" : "md-text-bold"}
                  style={{
                    paddingTop: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    border: "2px solid #081149",
                    borderRadius: "16px",
                  }}
                  onClick={Notifications}
                >
                  {" "}
                  Notifications
                </div>
              </div>
              <Form.Group as={Col} className="contact-mar">
                <Form.Label className="topic-label">Or tell us what you need help with</Form.Label>
                <Col className="contact-mar">
                  <Row>
                    <Col sm={12}>
                      <AppInput
                        placeholder="Enter a topic, like “notifications”"
                        name="type"
                        onChange={handleType}
                        value={formik.values.type}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.type && formik.touched.type && (
                        <div className="invalid-feedback-text">{formik.errors.type}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Col} className="contact-mar">
                <Form.Label>Message</Form.Label>
                <Col className="contact-mar">
                  <Row>
                    <Col sm={12}>
                      <textarea
                        name="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        onBlur={formik.handleBlur}
                        className="form-control"
                      />
                      {formik.errors.message && formik.touched.message && (
                        <div className="invalid-feedback-text">{formik.errors.message}</div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <div style={{ color: "#fe424d" }} className="contact-notice">
                Notice: Pria will never ask for, nor should you provide, your account number, social
                security number, name, address or password in an email or text message.
              </div>
              <button className="primary-button" style={{ width: "100%", marginTop: 20 }}>
                Submit
              </button>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}

export default Support;

import { IUtitlityAction } from 'actions/utils';
import { PREVENT_TOGGLE, TOGGLE_SIDEBAR } from 'types/ISidebar';
import { TOGGLE_LATER_STATE } from 'types/IWillDoLater';
import { TOGGLE_SIDEBAR_HOME } from 'types/Isidebarhome';

type UTILITY_STATE = {
  sidebar_state: boolean;
  onboarding_later_state: boolean;
  sidebar_state_home: boolean;
  prevent_toggle: boolean;
};

const INITIAL_STATE: UTILITY_STATE = {
  sidebar_state: false,
  onboarding_later_state: false,
  sidebar_state_home: false,
  prevent_toggle: false,
};

export default (state: UTILITY_STATE = INITIAL_STATE, action: IUtitlityAction) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return { ...state, sidebar_state: !state.sidebar_state };
    }
    case TOGGLE_LATER_STATE: {
      return { ...state, onboarding_later_state: true };
    }
    case TOGGLE_SIDEBAR_HOME: {
      return { ...state, sidebar_state_home: !state.sidebar_state_home };
    }
    case PREVENT_TOGGLE: {
      return { ...state, prevent_toggle: action.payload };
    }
    default:
      return state;
  }
};

export const CardBrand: Record<string, string> = {
  visa: "001",
  mastercard: "002",
  discover: "004",
  jcb: "007",
  "american express": "003",
};

export const CardBrandReverse = Object.entries(CardBrand).reduce((t, [key, value]) => {
  t[value] = key;
  return t;
}, {} as Record<string, string>);

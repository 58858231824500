import { SupportModal } from "components/Navbar/AppNavbar";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

declare const SupportComponent: unknown;
declare const setModalVisible: unknown;

export function useSidebar(
  init: boolean,
  toggle: Function,
  sidebar: boolean,
  preventToggle: boolean,
) {
  const [isVisible, setVisible] = useState(init);
  const ref: any = useRef(null);
  const dispatch = useDispatch();

  ///var toggle: Function = dispatch(toggle);
  const handleClickOutside = useCallback(
    (e: any) => {
      if (ref.current && !ref.current.contains(e.target) && sidebar && !preventToggle) {
        setVisible(true);
        dispatch(toggle());
      }
    },
    [dispatch, toggle, sidebar, preventToggle],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { ref, isVisible, setVisible };
}

//hook to use support modal in component
export const useSupportModal = (): [typeof setModalVisible, typeof SupportComponent] => {
  const [openModal, setModalVisible] = useState(false);

  const SupportComponent = () => (
    <SupportModal setSupportModalVisibility={setModalVisible} supportModalVisibility={openModal} />
  );

  return [setModalVisible, SupportComponent];
};

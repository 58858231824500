import { Privilege } from "components/AdminPortal/sidebaritems.config";
import { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const IsAdminContext = createContext<any>({});

export const useAdminCtx = () => useContext(IsAdminContext);

export const usePermission = () => {
  const permissions = useSelector((state: RootState) => state.user.permissions);
  const permissionList = useSelector((state: RootState) => state.user.permissionList);

  const hasPrivilege = (privilege?: Privilege) => {
    return permissionList.some((permission: string) => privilege && permission.includes(privilege));
  };

  return {
    hasPermission: (permission: string) => Boolean(permissions?.[permission]),
    hasPrivilege,
  };
};

import React from 'react';
import './appDropdown.scss';

interface IAppDropdownProps {
  children: any;
  placeholder?: string;
  style?: any;
  labelStyle?: any;
  isActive?: boolean;
  menuStyle?: any;
}

const AppDropdown: React.FC<IAppDropdownProps> = (props: IAppDropdownProps) => {
  return (
    <li className="nav-item dropdown" style={props.style}>
      <a
        href="#"
        className={`nav-link dropdown-toggle ${props.isActive ? `active` : ``}`}
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="navbarDropdown"
        style={props.labelStyle}
      >
        {props.placeholder}
        <span className="iconify" data-icon="uil:angle-down"></span>
      </a>
      <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={props.menuStyle}>
        {props.children}
      </div>
    </li>
  );
};

export default AppDropdown;

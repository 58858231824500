import React from "react";
import master_logo from "assets/img/master-logo.png";
import visa_logo from "assets/img/visa-logo.png";
import "./cardDisplay.scss";

interface CardDisplayAdminProps {
  brand: string;
  name: string;
  lastFour: any;
  id: string
}

const CardDisplayAdmin = ({ brand, name, lastFour,id, }: CardDisplayAdminProps) => {
  // const { user } = useAuth0();

  return (
    <>
      <div
        // className={`card-display${brand === "MasterCard" ? ` master` : ``} `}
        className="card-display"
        // onClick={() => handleEditCard(id)}
      >
        <div className="card-display-row">
          <div className="sm-text-reg"> Credit</div>
          <img
            src={brand === "MasterCard" ? master_logo : visa_logo}
            alt="Visa"
            style={{ height: brand === "MasterCard" ? "36px" : "14px" }}
          />
        </div>
        <div className="card-display-row">
          <div className="col p-0">
            <div className="sm-text-reg">{name}</div>
            <div className="sm-text-reg"> {`****${lastFour}`} </div>
          </div>
          {/* <div onClick={() => handleEditCard(id)}>
            <span className="iconify" data-icon="uil-edit" data-inline="false"></span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CardDisplayAdmin;

import http, { API_URL } from "../services/http";

export const postPayIn = async (token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http({
    baseURL: API_URL,
    method: "POST",
    url: `payin-payout/payin`,
  });
};

export const postPayOut = async (token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http({
    baseURL: API_URL,
    method: "POST",
    url: `payin-payout/payout`,
  });
};
export const postSubscriptionPayIn = async (token: string) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return http({
    baseURL: API_URL,
    method: "POST",
    url: `/subscriptions/authorize-and-capture-payments`,
  });
};

import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

const auth0Config: Auth0ClientOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  redirectUri: window.location.origin.toString(),
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
  scope: "openid profile email",
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

export const auth0 = new Auth0Client(auth0Config);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Auth0Provider {...auth0Config} clientId={auth0Config.client_id}>
        <App />
      </Auth0Provider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

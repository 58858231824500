import React from 'react';
import * as routes from 'constants/routes';
import { INavbarItems } from './INavbarItems';



export const navbarItems: INavbarItems[] = [
  {
    key: 'home',
    // eslint-disable-next-line react/display-name
    label: () => {
      return <span>Account</span>;
    },
    path: routes.DASHBOARD,
  },
  // {
  //   key:'Support',
  //   label:()=>{
  //     return <span>Support</span>
  //   }
  // },
  // {
  //   key:'account',
  //   label:()=>{
  //     return <span>Personal Information</span>
  //   },
  //   path:routes.ACCOUNT
  // }
  
  
  // {
  //   key: 'support',
  //   // eslint-disable-next-line react/display-name
  //   label: () => {
  //     return <span>Support</span>;
  //   },
  //   path: routes.SUPPORT,
  // },
];

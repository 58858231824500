import { applyMiddleware, compose, createStore } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import rootReducer from "reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
const enhancers: any = [applyMiddleware(thunk, promise)];
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cards", "cardToggle", "products"],
};

if (process.env.NODE_ENV === "development" && (window as any)["__REDUX_DEVTOOLS_EXTENSION__"]) {
  enhancers.push((window as any)["__REDUX_DEVTOOLS_EXTENSION__"]());
}

const presistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(presistedReducer, compose(applyMiddleware(thunk, promise)));
export const persistore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

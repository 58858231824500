import { IPerCardClick } from "actions/verticalCarousel";
import { PER_CARD_CLICK } from "types/IVerticalCardCarousel";

type PER_CARD_CLICK_STATE = {
  percardClick: boolean;
};

const INITIAL_STATE: PER_CARD_CLICK_STATE = {
  percardClick: true,
};

export default (state: PER_CARD_CLICK_STATE = INITIAL_STATE, action: IPerCardClick) => {
  switch (action.type) {
    case PER_CARD_CLICK: {
      return { ...state, percardClick: true };
    }
    default:
      return state;
  }
};

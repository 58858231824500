import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const GENERIC_ERROR_MESSAGE = "Ooops! Something went wrong";

/**
 *
 * @param event Error Object or any event that triggered the error
 * @param message Optional text to display as error message
 */
export function handleError(message?: string) {
  const text = message || GENERIC_ERROR_MESSAGE;

  return toast.error(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

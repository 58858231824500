import { fetchCardsAPI, postCardAPI, putCardAPI, removeCardAPI } from "services/cards";
import {
  FETCH_CARDS_ERROR,
  FETCH_CARDS_REQUEST,
  FETCH_CARDS_RESET,
  FETCH_CARDS_SUCCESS,
  IAction,
  ICard,
  POST_CARDS_ERROR,
  POST_CARDS_REQUEST,
  POST_CARDS_RESET,
  POST_CARDS_SUCCESS,
  REMOVE_CARDS_ERROR,
  REMOVE_CARDS_REQUEST,
  REMOVE_CARDS_RESET,
  REMOVE_CARDS_SUCCESS,
  UPDATE_CARDS_ERROR,
  UPDATE_CARDS_REQUEST,
  UPDATE_CARDS_RESET,
  UPDATE_CARDS_SUCCESS,
} from "types";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
export const FETCH_CARDS = "FETCH_CARDS";
export const POST_CARD = "POST_CARD";

export interface ICardAction extends IAction {
  type: string;
  payload?: any;
}

export const fetchCardsRequestAction = (): ICardAction => ({
  type: FETCH_CARDS_REQUEST,
});
export const fetchCardsSuccessAction = (cards: Array<ICard>): ICardAction => ({
  type: FETCH_CARDS_SUCCESS,
  payload: cards,
});
export const fetchCardsErrorAction = (error: string): ICardAction => ({
  type: FETCH_CARDS_ERROR,
  payload: error,
});
export const fetchCardsResetAction = (): ICardAction => ({
  type: FETCH_CARDS_RESET,
});

export const postCardsRequestAction = (): ICardAction => ({
  type: POST_CARDS_REQUEST,
});
export const postCardsSuccessAction = (response: any): ICardAction => ({
  type: POST_CARDS_SUCCESS,
  payload: response,
});
export const postCardsErrorAction = (error: string): ICardAction => ({
  type: POST_CARDS_ERROR,
  payload: error,
});
export const postCardsResetAction = (): ICardAction => ({
  type: POST_CARDS_RESET,
});

export const removeCardsRequestAction = (): ICardAction => ({
  type: REMOVE_CARDS_REQUEST,
});
export const removeCardsSuccessAction = (): ICardAction => ({
  type: REMOVE_CARDS_SUCCESS,
});
export const removeCardsErrorAction = (error: string): ICardAction => ({
  type: REMOVE_CARDS_ERROR,
  payload: error,
});
export const removeCardsResetAction = (): ICardAction => ({
  type: REMOVE_CARDS_RESET,
});

export const updateCardsRequestAction = (): ICardAction => ({
  type: UPDATE_CARDS_REQUEST,
});
export const updateCardsSuccessAction = (): ICardAction => ({
  type: UPDATE_CARDS_SUCCESS,
});
export const updateCardsErrorAction = (error: string): ICardAction => ({
  type: UPDATE_CARDS_ERROR,
  payload: error,
});
export const updateCardsResetAction = (): ICardAction => ({
  type: UPDATE_CARDS_RESET,
});

export const fetchCards = (userId: string) => {
  return (dispatch: any) => {
    dispatch(fetchCardsRequestAction());

    fetchCardsAPI(userId)
      .then((response) => {
        const cards = response.data.data;

        const cardIds = cards;

        dispatch(fetchCardsSuccessAction(cards));
      })
      .catch((err) => {
        const errorMsg = err?.message;
        dispatch(fetchCardsErrorAction(errorMsg));
      })
      .finally(() => {
        dispatch(fetchCardsResetAction());
      });
  };
};

export const postCard = (userId: string, card: ICard, token: string) => {
  return (dispatch: any) => {
    dispatch(postCardsRequestAction());
    postCardAPI(userId, card, token)
      .then((response) => {
        const responseCode = response.data?.httpCode;
        if (responseCode >= 200 && responseCode <= 205) {
          dispatch(postCardsSuccessAction(response.data.data));
          dispatch(fetchCards(userId));
          handleSuccess("Card added successfully");
        } else {
          dispatch(postCardsErrorAction(response.data?.message));
          handleError(response.data?.message);
        }
      })
      .catch((err) => {
        const errorMsg = err?.response?.data?.message;
        dispatch(postCardsErrorAction(errorMsg));
        handleError(errorMsg);
      })
      .finally(() => {
        dispatch(postCardsResetAction());
      });
  };
};

export const removeCard = (userId: string, cardId: string, token: string) => {
  return (dispatch: any) => {
    dispatch(removeCardsRequestAction());
    removeCardAPI(cardId, token)
      .then((response) => {
        const responseCode = response.data?.httpCode;
        if (checkResponseStatus(responseCode)) {
          dispatch(removeCardsSuccessAction());
          handleSuccess("Card removed successfully");
          dispatch(fetchCards(userId));
        } else {
          dispatch(removeCardsErrorAction(response.data?.message));
          handleError(response.data?.message);
        }
      })
      .catch((err) => {
        const errorMsg = err?.response?.data?.message;
        dispatch(removeCardsErrorAction(errorMsg));
        handleError(errorMsg);
      })
      .finally(() => {
        dispatch(removeCardsResetAction());
      });
  };
};

export const updateCard = (userId: string, card: any, token: string) => {
  return (dispatch: any) => {
    dispatch(updateCardsRequestAction());
    putCardAPI(userId, card, token)
      .then((response) => {
        const responseCode = response.data?.httpCode;
        if (responseCode >= 200 && responseCode <= 205) {
          dispatch(updateCardsSuccessAction());
          dispatch(fetchCards(userId));
          handleSuccess("Card updated successfully");
        } else {
          dispatch(updateCardsErrorAction(response.data?.message));
        }
      })
      .catch((err) => {
        const errorMsg = err?.message;
        handleError(errorMsg);
        dispatch(updateCardsErrorAction(errorMsg));
      })
      .finally(() => {
        dispatch(updateCardsResetAction());
      });
  };
};

export const postCardAction = (card: ICard): ICardAction => ({
  type: POST_CARD,
  payload: card,
});

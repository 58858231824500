import React from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import "./appSelect.scss";

interface IAppSelectProps
  extends FormControlProps,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  options: Array<any>;
  onChange?: any;
  value?: any;
  type?: any;
  size?: any;
  fullwidth?: boolean;
  placeholder?: string;
  adornmentLabel?: string;
  onButtonClick?: any;
}

interface IOptions {
  value: any;
  label: string;
}

const AppSelect: React.FC<IAppSelectProps> = (props: IAppSelectProps) => {
  return (
    <Form.Group>
      <Form.Control as="select" {...props}>
        {props.options?.map((opt: IOptions, index: number) => (
          <option key={`select_${name}_${index}`} value={opt.value} style={{ padding: "15px 0px" }}>
            {opt.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default AppSelect;

import Spinners from "components/Spinners/Spinners";
import React from "react";
import { Spinner, Table } from "react-bootstrap";
import "./AppTable.scss";

export interface IAppTableProps {
  className: string;
  columns: Array<ITableConstants>;
  data: Array<any>;
  isLoading?: boolean;
  dataCount?: number;
}

export interface ITableConstants {
  title: string;
  name: string;
  render: Function;
  sortable?: boolean;
  align?: string;
}

const AppTable: React.FC<IAppTableProps> = (props: IAppTableProps) => {
  return (
    <>
      {props.isLoading && (
        <Spinners
        // animation="border"
        //  style={{ position: 'relative', alignSelf: 'center', margin: '100px' }}
        />
      )}
      <Table responsive borderless>
        <thead>
          <tr>
            {props.columns?.map((headerItem: ITableConstants, index: number) => {
              return (
                <th key={index}>
                  <span>{headerItem.title}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!props.isLoading &&
            Array.isArray(props.data) &&
            props.data?.length > 0 &&
            props.data?.map((row: any, index: number) => {
              return (
                <tr key={index}>
                  {props.columns?.map((col: ITableConstants, key: number) => {
                    return <td key={key}>{col.render(row)}</td>;
                  })}
                </tr>
              );
            })}
        </tbody>
        <tfoot></tfoot>
      </Table>
    </>
  );
};

export default AppTable;

import { NumberLiteralType } from 'typescript';

export interface ITransaction {
  paymentIntent: IPaymentIntent;
  //charged: Array<ICharge>;
}

export interface IPaymentIntent {
  amount: number;
  paymentIntentId: string;
  cancellationReason?: string;
  messages: string;
  cardId: string;
  cardDetails: string;
  created?: number;
  status?: string;
  cancelledAt?: string;
}

export interface ICharge {
  chargeId: string;
  amount: number;
  amountCapture: number;
  amountRefunded: NumberLiteralType;
  balanceTransactionId: string;
  created: number;
  customerId: string;
  description: string;
  paid: boolean;
  paymentMedhodId: string;
  currency: string;
  dispute: any;
  disputed: boolean;
  failureCode: any;
  failureMessage: any;
  invoiceId: string;
  refunded: boolean;
  sourceId: string;
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  lastFour: string;
}

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'FETCH_TRANSACTIONS_ERROR';
export const FETCH_TRANSACTIONS_RESET = 'FETCH_TRANSACTIONS_RESET';

import React from 'react';
import * as routes from 'constants/routes';
import { INavMenuItems } from './INavMenuItems';
import { useAuth0 } from '@auth0/auth0-react';

export const navbarItems: INavMenuItems[] = [
 
  // {
  //   key: 'about',
  //   // eslint-disable-next-line react/display-name
  //   label: () => {
  //     return <span>About</span>;
  //   },
  //   path: routes.ABOUT,
  // },
  // {
  //   key: 'FAQ',
  //   // eslint-disable-next-line react/display-name
  //   label: () => {
  //     return <span>FAQ</span>;
  //   },
  //   path: routes.FAQ,
  // },
  // {
  //   key: 'contact',
  //   // eslint-disable-next-line react/display-name
  //   label: () => {
  //     return <span>Contact</span>;
  //   },
  //   path:routes.CONTACT
  
  // },
  
  
  // {
  //   key:'login',
  //   // eslint-disable-next-line react/display-name
  //   label:()=>{
  //     return<span>LOG IN</span>;
  //   },
  // },
  // {
  //   key:'signup',
  //   // eslint-disable-next-line react/display-name
  //   label:()=>{
  //     return<span>SIGN UP</span>;
  //   },
  // },
  
  // {
  //   key: 'support',
  //   // eslint-disable-next-line react/display-name
  //   label: () => {
  //     return <span>Support</span>;
  //   },
  //   path: routes.SUPPORT,
  // },
];

import { useAuth0 } from "@auth0/auth0-react";
import { fetchCards } from "actions";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 * Hook to fetch user data and store them to redux store
 */
const useFetchUserCards = (run = true) => {
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const fetchUserCards = useCallback(async () => {
    user?.sub && dispatch(fetchCards(user?.sub));
  }, [dispatch, user?.sub]);

  useEffect(() => {
    run && fetchUserCards();
  }, [fetchUserCards, run]);

  return fetchUserCards;
};

export default useFetchUserCards;

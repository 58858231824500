import { useAuth0 } from "@auth0/auth0-react";
import { verticalOrCarousel } from "actions/verticalCarousel";
import { getAllTransactions, getLogsById } from "api/admin";
import trxarrow from "assets/img/trxarrow.svg";
import AppButton from "common/AppButton";
import AppDropdown from "common/AppDropdown";
import AppTable, { ITableConstants } from "common/AppTable/AppTable";
import DynamicTable from "components/DynamicTable";
import { useFilter } from "components/DynamicTable/FilterOptions";
import { FilterList } from "components/DynamicTable/TableFilter";
import useGetAccessToken from "hooks/useGetAccessToken";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmPaymentAPI } from "services/transactions";
import { handleError } from "utils/handleError";
import UserDataCtx from "Context/AdminDataAccess";
import "./TransactionSection.scss";
interface Ilogs {
  userId?: string;
  ip?: string;
  username?: string;
  date?: string | Date;
  useragent?: string;
  address?: string;
  geolocation?: string | number;
}

const initDate = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  },
];

// const tFetchTransactionsAPI = throttle(fetchTransactionsAPI, 1000);

const TransactionSection: React.FC<any> = ({
  setDeclineBanner,
  windowWidth,
  setCarousalMode,
  carouselMode,
  userData,
}) => {
  const dispatch = useDispatch();

  const { user, getAccessTokenSilently } = useAuth0();

  const { userId } = useContext(UserDataCtx);

  const { filterOptions, setFilter } = useFilter();

  const [limit, setLimit] = useState(10);
  const [showLogs, setShowLogs] = useState<boolean>(false);

  const initialDate = {
    start: new Date(new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000).getTime(),
    end: new Date().getTime(),
  };

  const [queryFilter, setQueryFilter] = useState<any>(initialDate);
  const [queryLabel, setQueryLabel] = useState(window.innerWidth < 500 ? "Per Card" : "All Cards");

  const [dateRangeModal, setDateRangeModal] = useState(false);
  const [dateState, setDateState] = useState(initDate);

  let pay_amount;

  const history = useHistory();

  useEffect(() => {
    if (queryLabel === "All Cards") {
      setQueryLabel("All Cards");
    }
  }, [queryLabel]);

  const token = useGetAccessToken();

  const { data: logsData, isLoading } = useQuery(
    ["alllogs", filterOptions, token],
    () => token && getLogsById(token, filterOptions, userData as any),
  );

  const { data: tabledata }: any = useQuery(
    ["fetch transactions", limit, queryFilter, dateState, token],
    async () => {
      return (
        token &&
        getAllTransactions(token, {
          limit,
          ...filterOptions,
          filter: {
            userId: userId,
          },
        })
      );
    },
  );

  const activityTableColumns: Array<ITableConstants> = [
    // {
    //   title: "",
    //   name: "transaction_type",
    //   // eslint-disable-next-line react/display-name
    //   render: (rowData: any) => {
    //     //console.log('rowData3434', rowData);
    //     return rowData && rowData?.type === "PAYIN" ? (
    //       <FiArrowUpRight color="green" />
    //     ) : (
    //       <FiArrowDownRight color="red" />
    //     );
    //   },
    // },
    {
      title: "",
      name: "date",
      render(rowData: any) {
        if (!rowData?.createdAt) return <></>;
        const date = moment(rowData.createdAt).format("YYYY-MM-DD");
        return <span>{date}</span>;
      },
    },
    {
      title: "",
      name: "transaction_type",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        return rowData && <span> {rowData?.description}</span>;
      },
    },
    {
      title: "",
      name: "date",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        // let date = rowData?.createdAt ? new Date(rowData?.createdAt) : "";
        // let formattedDate = moment(date).format("MMMM DD, YYYY hh:mm A");
        return (
          rowData?.transactionStatus && (
            <span
              className={` ${
                rowData?.transactionStatus.toLowerCase() === "success" ? "success" : "failure"
              } `}
            >
              {rowData?.type}
            </span>
          )
        );
      },
    },

    // {
    //   title: "",
    //   name: "card",
    //   // eslint-disable-next-line react/display-name
    //   render: (rowData: any) => {
    //     if (rowData?.status === "requires_action" || rowData?.status === "requires_confirmation") {
    //       return (
    //         <AppButton
    //           className="primary outlined"
    //           onClick={() => handleConfirmPayment(rowData.transactionId)}
    //         >
    //           Confirm Payment
    //         </AppButton>
    //       );
    //     }
    //     return rowData && <span>{rowData?.cardDetails}</span>;
    //   },
    // },
    {
      title: "",
      name: "remarks",
      render: (rowData: any) => {
        return <span>{rowData?.remarks}</span>;
      },
    },
    {
      title: "",
      name: "returned",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        console.log(rowData?.isReturned);

        return rowData?.isReturned ? <span>RETURNED</span> : <span>NOT RETURNED</span>;
      },
    },
    {
      title: "",
      name: "amount",
      // eslint-disable-next-line react/display-name
      render: (rowData: any) => {
        // if (rowData?.status === "succeeded" || rowData?.status === "ACCEPTED") {
        //   pay_amount = `$${rowData?.amount}`;
        // } else {
        //   pay_amount = <span style={{ color: "Red" }}>{rowData?.remarks}</span>;
        // }
        return rowData && <span>${rowData?.amount}</span>;
      },
    },
  ];

  const handleConfirmPayment = async (id: any) => {
    const token = await getAccessTokenSilently();
    try {
      if (user && user.sub) {
        let response = await confirmPaymentAPI(user.sub, id, token);
      }
    } catch (error: any) {
      handleError(error?.response?.data?.message);
    }
  };

  const date = useMemo(() => {
    return new Date();
  }, []);

  const dateOptions = [
    {
      value: "AllCards",
      label: "All Cards",
      onClick: () => {
        dispatch(verticalOrCarousel());
        // dispatch(filterAllCards(cybersource?.data?.rows));
        setQueryLabel("All Cards");
        setCarousalMode(false);

        //reset all filters
        setLimit(10);
        setQueryFilter(initialDate);
        setDateState(initDate);
      },
    },
    {
      value: "verticalDisplay",
      label: "Per Card",
      onClick: () => {
        // dispatch(filterAllCards(tabledata));
        setQueryLabel("Per Card");

        setCarousalMode(true);
      },
    },
    {
      value: "custom",
      label:
        dateState[0].startDate?.toDateString() !== date.toDateString() ||
        dateState[0].endDate?.toDateString() !== date.toDateString()
          ? `${dateState[0].startDate?.toLocaleDateString()} - ${dateState[0]?.endDate?.toLocaleDateString()}`
          : "Custom Range",

      onClick: () => {
        setQueryLabel("Custom");
        setQueryFilter("Custom");
        setDateRangeModal(true);
      },
    },
  ];
  useEffect(() => {
    if (carouselMode) {
      setQueryLabel("Per Card");
    } else {
      setQueryLabel("All Cards");
    }
  }, [carouselMode]);

  useEffect(() => {
    // if (queryLabel === 'custom') {
    if (
      dateState[0].startDate?.toDateString() !== date.toDateString() ||
      dateState[0].endDate?.toDateString() !== date.toDateString()
    ) {
      setQueryLabel(
        `${dateState[0].startDate?.toLocaleDateString()} - ${dateState[0]?.endDate?.toLocaleDateString()}`,
      );
    }
    //}
  }, [dateState, date]);

  useEffect(() => {
    if (queryFilter === "custom") {
      setDateRangeModal(true);
    }
  }, [queryFilter]);

  const renderDateRangeModal = () => {
    return (
      <Modal show={dateRangeModal} onHide={() => setDateRangeModal(false)} animation={false}>
        <Modal.Body style={{ padding: "12px 8px", alignSelf: "center" }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              setDateState([item.selection as any]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={dateState}
          />
        </Modal.Body>
        <AppButton
          className="primary"
          onClick={() => setDateRangeModal(false)}
          style={{ borderRadius: "0" }}
        >
          Apply
        </AppButton>
      </Modal>
    );
  };

  const diff = 10;
  const showMore = tabledata?.totalCount >= limit;

  const filterList: FilterList = [
    {
      type: "date",
      field: "dateRange",
    },
    {
      type: "number",
      label: "Limit",
      field: "limit",
      value: 10,
    },
  ];

  const logsTable = useMemo<Ilogs[]>(() => {
    try {
      return logsData?.data.logs.map((singleData: any, index: any) => ({
        userId: singleData?.user_id,
        ip: singleData?.ip,
        username: singleData?.user_name,
        date: singleData?.date.replace("T", "").replace("Z", "").substring(0, 10),
        useragent: singleData?.user_agent,
        address:
          `${singleData?.location_info.city_name}` +
          ", " +
          `${singleData?.location_info.country_name}`,
        geolocation:
          `${singleData?.location_info.latitude}` + ", " + `${singleData?.location_info.longitude}`,
      }));
    } catch (err) {
      return [];
    }
  }, [logsData]);
  // console.log(logsData?.data);

  return (
    <div className="activity-section">
      <div className="trx-head">
        <h5
          style={{ fontWeight: 700, paddingBottom: "3%", paddingRight: "3rem", cursor: "pointer" }}
          onClick={() => {
            history.push("/admin/users");
          }}
        >
          Client Details
        </h5>
        <img style={{ paddingRight: "3rem" }} src={trxarrow} alt="trxarrow" />
        <div className="userName-Trx">
          {userData?.firstName}&nbsp;
          {userData?.middleName && userData?.middleName} {userData?.lastName}
        </div>
      </div>
      {renderDateRangeModal()}
      <div className="heading">
        <h5
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowLogs(!showLogs);
          }}
        >
          Activity
        </h5>

        {/* {!cybersource?.isLoading ? (
          cards?.length === 0 ? (
            <AppButton
              className="appButton primary outlined "
              onClick={() => history.push(routes.ONBORDING1)}
            >
              Complete account setup
            </AppButton>
          ) : null
        ) : (
          <></>
        )} */}

        {!showLogs && (
          <AppDropdown
            placeholder={queryLabel}
            menuStyle={{ margin: "0.5rem -0.5rem" }}
            style={{ width: "fit-content", padding: "4px" }}
          >
            {dateOptions.map((opt, index) => {
              if (opt.value === "verticalDisplay" && windowWidth >= 500) {
                return;
              }

              return (
                <a
                  className={queryLabel === opt.label ? "dropdown-item active" : "dropdown-item"}
                  key={`${JSON.stringify(opt)}_${index}`}
                  onClick={opt.onClick}
                  style={{ height: "2rem" }}
                >
                  {opt.label}
                </a>
              );
            })}
          </AppDropdown>
        )}
      </div>
      {showLogs && (
        <>
          <DynamicTable<Ilogs>
            title="Logs"
            td={logsTable}
            th={[
              { accessor: "userId", h: "User Id", isSortable: false },
              { accessor: "ip", h: "IP Address", isSortable: false },
              { accessor: "username", h: "User Name", isSortable: false },
              { accessor: "date", h: "Date", isSortable: true },
              { accessor: "useragent", h: "User Agent", isSortable: false },
              { accessor: "address", h: "Address", isSortable: false },
              { accessor: "geolocation", h: "Geo Location", isSortable: false },
            ]}
            // filterList={filterList}
            showPagination={true}
            totalPages={logsData?.data?.total / 10}
            totalResults={logsData?.data?.total}
            isLoading={isLoading}
          />
        </>
      )}

      {!showLogs && (
        <>
          <AppTable
            className="table"
            columns={activityTableColumns}
            data={tabledata?.rows?.length ? tabledata?.rows : []}
          />
          {showMore ? (
            <AppButton
              className="secondary borderless"
              onClick={() => {
                if (showMore) setLimit((page) => page + diff);
                else {
                  setLimit((page) => page - diff);
                }
              }}
            >
              {showMore ? `Show More` : `Show Less`}
            </AppButton>
          ) : null}
        </>
      )}
    </div>
  );
};

export default TransactionSection;

import { useAuth0 } from "@auth0/auth0-react";
import { fetchUser } from "actions/users";
import AppButton from "common/AppButton";
import { BellIcon } from "common/AppIcons";
import { useFetchUserCtx } from "hooks/useFetchUser";
import { clone } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAPI, postNotificationsAPI } from "services/users";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import { checkResponseStatus, handleSuccess } from "utils/handleSuccess";
import checkedbox from "../../assets/img/checkedbox.png";
import uncheckedbox from "../../assets/img/uncheckedbox.png";
import "./notification.scss";

interface ISettings {
  forEmail: Array<string>;
  forSms: Array<string>;
  [key: string]: Array<string>;
}

interface IsettingsProps {
  reference3: any;
}

const NotificationSettings: React.FC<any> = ({ reference3 }: IsettingsProps) => {
  const userInfo = useSelector((state: RootState) => state.user.user);
  const [settings, setSettings] = useState<ISettings>(
    userInfo?.notification || { forEmail: [], forSms: [] },
  );
  const [email, setEmail] = useState(userInfo?.notification?.forEmail.length > 0);
  const [sms, setSms] = useState(userInfo?.notification?.forSms.length > 0);
  const [loading, setLoading] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const refetch = useFetchUserCtx();

  const handleSettingsChange = (setting: string, feature: string) => {
    let newSettings: ISettings = clone(settings);

    if (newSettings[setting]?.includes(feature)) {
      newSettings[setting].splice(newSettings[setting].indexOf(feature), 1);
    } else {
      newSettings[setting] = [...newSettings[setting], feature];
    }
    setSettings(newSettings);
    if (newSettings[setting].length === 0) {
      switch (setting) {
        case "forEmail":
          setEmail((email) => false);
          break;
        case "forSms":
          setSms((sms) => false);
          break;
      }
    }
    if (newSettings[setting].length === 3) {
      switch (setting) {
        case "forEmail":
          setEmail((email) => true);
          break;
        case "forSms":
          setSms((sms) => true);
          break;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      if (user && user?.sub) {
        let response = await postNotificationsAPI(user?.sub, token, settings);
        if (response && response.data && checkResponseStatus(response.data.httpCode)) {
          handleSuccess("Updated Successfully");
          refetch();
        }
        // console.log(response);
      }
    } catch (error) {
      console.log(error);
      handleError();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="notification-section" ref={reference3}>
        <h5>
          <BellIcon />
          Notifications
        </h5>

        <form>
          <ul>
            <li>
              <span
                onClick={() => {
                  setEmail((prev) => !prev);
                  setSettings((prev) => {
                    if (!email) {
                      return { ...prev, forEmail: ["payin", "payout", "subscription"] };
                    } else {
                      return { ...prev, forEmail: [] };
                    }
                    // if (prev.forEmail.length > 0 && !email) {
                    //   return { ...prev, forEmail: [] };
                    // } else {
                    //   return { ...prev, forEmail: ['payin', 'payout', 'subscription'] };
                    // }
                  });
                }}
              >
                <img src={email ? checkedbox : uncheckedbox} alt="check" />
              </span>
              <label className={email ? "active" : ""} htmlFor="email">
                Email Notifications
              </label>

              <ul>
                <li
                  onClick={() => {
                    handleSettingsChange("forEmail", "payin");
                  }}
                >
                  <span>
                    <img
                      src={settings?.forEmail.includes("payin") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={email ? "active" : ""} htmlFor="email">
                    Charges
                  </label>
                </li>
                <li
                  onClick={() => {
                    handleSettingsChange("forEmail", "payout");
                  }}
                >
                  <span>
                    <img
                      src={settings?.forEmail.includes("payout") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={email ? "active" : ""} htmlFor="email">
                    Payments
                  </label>
                </li>
                <li
                  onClick={() => {
                    handleSettingsChange("forEmail", "subscription");
                  }}
                >
                  <span>
                    <img
                      src={settings?.forEmail.includes("subscription") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={email ? "active" : ""} htmlFor="email">
                    Subscription Updates
                  </label>
                </li>
              </ul>
            </li>

            <li>
              <span
                onClick={() => {
                  setSms((prev) => !prev);
                  setSettings((prev) => {
                    if (!sms) {
                      return { ...prev, forSms: ["payin", "payout", "subscription"] };
                    } else {
                      return { ...prev, forSms: [] };
                    }
                    // if (prev.forSms.length > 0) {
                    //   return { ...prev, forSms: [] };
                    // } else {
                    //   return { ...prev, forSms: ['payin', 'payout', 'subscription'] };
                    // }
                  });
                }}
              >
                <img src={sms ? checkedbox : uncheckedbox} alt="check" />
              </span>
              <label className={sms ? "active" : ""} htmlFor="email">
                SMS Notifications
              </label>
              <ul>
                <li
                  onClick={() => {
                    handleSettingsChange("forSms", "payin");
                  }}
                >
                  <span>
                    <img
                      src={settings.forSms.includes("payin") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={sms ? "active" : ""} htmlFor="email">
                    Charges
                  </label>
                </li>
                <li
                  onClick={() => {
                    handleSettingsChange("forSms", "payout");
                  }}
                >
                  <span>
                    <img
                      src={settings.forSms.includes("payout") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={sms ? "active" : ""} htmlFor="email">
                    Payments
                  </label>
                </li>
                <li
                  onClick={() => {
                    handleSettingsChange("forSms", "subscription");
                  }}
                >
                  <span>
                    <img
                      src={settings.forSms.includes("subscription") ? checkedbox : uncheckedbox}
                      alt="check"
                    />
                  </span>
                  <label className={sms ? "active" : ""} htmlFor="email">
                    Subscription Updates
                  </label>
                </li>
              </ul>
            </li>
          </ul>

          <div className="action-box">
            <AppButton
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit();
              }}
              type="submit"
              className="primary"
              isLoading={loading}
              disabled={loading}
            >
              Save Changes
            </AppButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default NotificationSettings;

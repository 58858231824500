import React from "react";
import spinnerpria from "assets/img/spinner-pria.svg";
import styles from "./Spinners.module.scss";

interface SpinnerProps {
  sm?: boolean;
  fullScreen?: boolean;
}

function Spinners(props: SpinnerProps) {
  const { sm, fullScreen } = props;

  return (
    <div
      className={`${styles["spinner-position"]} ${fullScreen && styles["full-screen"]} ${
        sm && styles["small-spinner"]
      } `}
    >
      <img src={spinnerpria} alt="Loading" />
    </div>
  );
}

export default Spinners;

import React, { useContext, useState } from "react";
import { slide as Menu, State } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { sidebarItemsAdmin } from "../AdminPortal/sidebaritems.config";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import "./Hamburger.scss";
import { LogoutIcon } from "common/AppIcons";
import { IsAdminContext, usePermission } from "Context/AdminContext";
import { useHistory } from "react-router-dom";
import { logoutloader } from "actions/logoutloader";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { useMutation } from "react-query";
import { postPayIn, postPayOut } from "../../api/ForcePayInPayOut";
import useGetAccessToken from "hooks/useGetAccessToken";

// interface HamburgerProps {
//   key: string;
//   path: string;
//   title: string;
// }

// const Hamburger: React.FC<HamburgerProps> = ({ key, path, title }) => {
function Hamburger() {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const history = useHistory();
  // const [isOpend, setIsOpend] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { hasPrivilege } = usePermission();

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleStateChange = (state: State) => {
    setIsMenuOpen(state.isOpen);
  };
  const { mutate: payInWithQuery } = useMutation(postPayIn, {
    onSuccess: async () => {
      alert("System will forcefully try to payin");
    },
  });
  const { mutate: payOutWithQuery } = useMutation(postPayOut, {
    onSuccess: async () => {
      alert("System will forcefully try to payout");
    },
  });

  const token = useGetAccessToken();
  const handlePayin = () => {
    token && payInWithQuery(token);
  };

  const handlePayOut = () => {
    token && payOutWithQuery(token);
  };

  return (
    <>
      <div className="hamburger-container">
        <Menu right isOpen={isMenuOpen} onStateChange={handleStateChange}>
          <nav>
            <ul className="ul-hamburger">
              {sidebarItemsAdmin.map((item) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  // console.log("privilage",item),
                  <li key={item.key} className="nav-link-separator">
                    {hasPrivilege(item.privilege) && (
                      <NavLink
                        style={{ textDecoration: "none", color: "#081149" }}
                        activeClassName="active-mob"
                        className="mobile-deactive"
                        to={item.path}
                        onClick={handleCloseMenu}
                      >
                        {item.title}
                      </NavLink>
                    )}
                  </li>
                );
              })}
              <li
                className="nav-link-separator bm-burger-padding"
                onClick={() => {
                  sessionStorage.setItem("backDashboard", "true");
                  const query = new URLSearchParams();
                  query.set("from", "admin-dashboard");

                  history.push({
                    pathname: "/dashboard",
                    search: query.toString(),
                  });
                }}
                style={{ color: "#081149", marginTop: "32px" }}
              >
                Back To Dashboard
              </li>

              <li
                className="nav-link-separator bm-burger-padding"
                style={{ color: "#081149", marginTop: "32px", marginBottom: "32px" }}
                onClick={() => handlePayin()}
              >
                <FiArrowUpRight color="green" />
                PayIn
              </li>
              <li
                className="nav-link-separator bm-burger-padding"
                style={{ color: "#081149", marginBottom: "32px" }}
                onClick={() => handlePayOut()}
              >
                <FiArrowDownRight color="red" />
                Payout
              </li>
              <li
                className="nav-link-separator primary borderless grey bm-burger-padding"
                onClick={() => {
                  dispatch({ type: "LOG_OUT" });
                  dispatch(logoutloader());
                  logout({ returnTo: window.location.origin });
                }}
                style={{ color: "red" }}
              >
                Logout <LogoutIcon style={{ color: "red" }} />
              </li>
            </ul>
          </nav>
        </Menu>
      </div>
    </>
  );
}

export default Hamburger;

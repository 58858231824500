import { ICardAction } from "actions";
import {
  FETCH_PRODUCT_ERROR,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_RESET,
  FETCH_PRODUCT_SUCCESS,
  FETCH_SUBSCRIBED_ERROR,
  FETCH_SUBSCRIBED_REQUEST,
  FETCH_SUBSCRIBED_SUCCESS,
  IProduct,
  SELECT_PRODUCT,
  SELECT_PRODUCT_RESET,
  SUBSCRIBE_PRODUCT_ERROR,
  SUBSCRIBE_PRODUCT_REQUEST,
  SUBSCRIBE_PRODUCT_RESET,
  SUBSCRIBE_PRODUCT_SUCCESS,
} from "types/IProduct";

type PRODUCT_STATE = {
  products: Array<any>;
  subscribedProduct?: IProduct[] | null;
  loading: boolean;
  error: string;
  productFetchSuccess: boolean;
  productSubscribeSuccess: boolean;
  subscribedProductFetchSuccess: boolean;
  selectedProduct: any;
  responseData?: any;
};

const INITIAL_STATE: PRODUCT_STATE = {
  products: [],
  loading: false,
  error: "",
  productFetchSuccess: false,
  productSubscribeSuccess: false,
  subscribedProductFetchSuccess: false,
  selectedProduct: {},
  subscribedProduct: null,
  responseData: null,
};

export default (state: PRODUCT_STATE = INITIAL_STATE, action: ICardAction): PRODUCT_STATE => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_PRODUCT_SUCCESS: {
      return { ...state, loading: false, productFetchSuccess: true, products: action.payload };
    }
    case FETCH_PRODUCT_ERROR: {
      return { ...state, error: action.payload, productFetchSuccess: false };
    }
    case FETCH_PRODUCT_RESET: {
      return { ...state, productFetchSuccess: false, loading: false, error: "" };
    }
    case SUBSCRIBE_PRODUCT_REQUEST: {
      return { ...state, loading: true };
    }
    case SUBSCRIBE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        productSubscribeSuccess: true,
        responseData: action.payload,
      };
    }
    case SUBSCRIBE_PRODUCT_ERROR: {
      return { ...state, error: action.payload, productSubscribeSuccess: false };
    }
    case SUBSCRIBE_PRODUCT_RESET: {
      return {
        ...state,
        productSubscribeSuccess: false,
        loading: false,
        error: "",
        responseData: null,
      };
    }
    case SELECT_PRODUCT: {
      return { ...state, selectedProduct: action.payload };
    }
    case SELECT_PRODUCT_RESET: {
      return { ...state, selectedProduct: {} };
    }
    case FETCH_SUBSCRIBED_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_SUBSCRIBED_SUCCESS: {
      let subscribedProducts: Array<IProduct> = action.payload;
      let subscribedProduct = subscribedProducts;
      return {
        ...state,
        loading: false,
        subscribedProduct: subscribedProduct,
        selectedProduct: subscribedProduct,
      };
    }
    case FETCH_SUBSCRIBED_ERROR: {
      return { ...state, error: action.payload };
    }

    default:
      return state;
  }
};

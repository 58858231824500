import axios from "axios";
import http, { API_URL } from "../services/http";

export const updateUserQuery = async (args: { userId: string; data: any }) => {
  const { userId, data } = args;
  http.defaults.headers.common["Authorization"] = `Bearer ${data?.token}`;

  delete data["token"];
  return http({
    baseURL: API_URL,
    method: "PUT",
    url: `/customers/${userId}`,
    data: data,
  });
};

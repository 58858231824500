import http from "services/http";
import * as urls from "constants/urls";
import { FilterOptions } from "components/DynamicTable/FilterOptions";

export const getAllCustomers = async (token: string, filterOptions: FilterOptions) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await http.get(`${urls.USER}/`, {
    params: {
      sort: "id",
      ...filterOptions,
      order: filterOptions?.order?.toUpperCase(),
    },
  });
  return response?.data;
};

export const getAllTransactions = async (
  token: string,
  { filter, ...filterOptions }: FilterOptions,
) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await http.get(`${urls.TRANSACTION}/`, {
    params: {
      sort: "id",
      ...filterOptions,
      ...filter,
      order: filterOptions?.order?.toUpperCase(),
    },
  });
  return response?.data?.data;
};

export const verifyTransactions = async (ids: string[]) => {
  const response = await http.put(`${urls.TRANSACTION}`, {
    ids,
    isVerified: true,
  });

  if (!response?.data) return undefined;

  return response?.data;
};

export const getAllLogs = async (token: string, filterOptions: FilterOptions) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // console.log(filterOptions);

  const filter = filterOptions?.filter;
  const response = await http.get(`logs/`, {
    params: {
      filterPattern: filterOptions?.search || undefined,
      limit: filter?.limit || 10,
      startTime: filter?.dateRange?.start,
      endTime: filter?.dateRange?.end,
      nextToken: filter?.nextToken,
    },
  });
  // console.log(response?.data?.data?.events);
  return response?.data;
};

export const getLogsById = async (
  token: string,
  filterOptions: FilterOptions,
  userData: string,
) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const filter = filterOptions?.filter;
  const userId = userData;

  // const userId = "auth0|630dd77dbc8cee033b8feb8b";

  const response = await http.get(`logs/${userId}`, {
    params: {
      filterPattern: filterOptions?.search || undefined,
      limit: filter?.limit || 10,
      startTime: filter?.dateRange?.start,
      endTime: filter?.dateRange?.end,
    },
  });
  return response?.data;
};

export const getUserCards = async (userId: string) => {
  const response = await http.get(`cards/user/${userId}`);

  return response?.data;
};

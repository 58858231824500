import { toggleSidebarHomeAction } from "actions/utils";
import cross from "assets/img/cross.png";
import { INavMenuItems } from "components/NavMenu/INavMenuItems";
import { navbarItems } from "components/NavMenu/navmenu.config";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import * as routes from "constants/routes";
import { useSidebar } from "hooks/useSidebar";
import { useHistory } from "react-router";
import { RootState } from "store";
import { NavLink } from "react-router-dom";
import "./sidebarhome.scss";

const Sidebarhome: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { logout, user, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState<any>("home");

  const onNavItemClick = (navItem: INavMenuItems) => {
    dispatch(toggleSidebarHomeAction());
    const { path } = navItem;
    if (navItem.key === "signup") {
      loginWithRedirect({
        screen_hint: "signup",
      });
    }

    if (navItem.key === "login") {
      loginWithRedirect();
    }

    if (!path) return;
    setActiveMenu(navItem.key);
    history.push(path);
  };

  const sidebarhome = useSelector((state: RootState) => state.utils.sidebar_state_home);
  const preventToggle = useSelector((state: RootState) => state.utils.prevent_toggle);
  const { ref, isVisible, setVisible } = useSidebar(
    true,
    toggleSidebarHomeAction,
    sidebarhome,
    preventToggle,
  );

  const hideSidebar = () => dispatch(toggleSidebarHomeAction());

  return (
    <>
      {isVisible && sidebarhome && (
        <div
          role="dialog"
          aria-modal={true}
          className="offcanvas offcanvas-end show"
          tabIndex={-1}
          style={{ visibility: "visible" }}
          ref={sidebarhome ? ref : null}
        >
          <div className="offcanvas-header" style={{ flexDirection: "row-reverse" }}>
            <img src={cross} onClick={() => dispatch(toggleSidebarHomeAction())}></img>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              {navbarItems.map((nav) => (
                <li
                  className={`${activeMenu === nav.key ? `nav-item active` : `nav-item`} `}
                  key={nav.key}
                  onClick={() => onNavItemClick(nav)}
                  style={{ fontSize: 20, fontWeight: 600, marginLeft: 8, color: "#4F4F4F" }}
                >
                  {nav.label()}
                </li>
              ))}

              {/* <AppButton
                  className="primary outlined"
                  style={{ width: '100%', height: '55px', border:'0.1px solid gray',borderLeft:'0px',borderRadius:'0px',}}
                  onClick={() => loginWithRedirect()}
                >
                  Log In
                </AppButton> */}
              {/* <AppButton
                  className="primary"
                  style={{ width: '100%', height: '55px',borderRadius:'0px' }}
                  onClick={() =>
                    loginWithRedirect({
                      screen_hint: 'signup',
                    })
                  }
                >
                 Sign Up
                </AppButton> */}
            </ul>
            <ul className="ul-sidebarhome">
              <NavLink
                exact
                to="/"
                activeClassName="active-mob"
                className="mobile-deactive"
                onClick={hideSidebar}
              >
                <li>Pria</li>
              </NavLink>
              <NavLink
                exact
                to="/about"
                activeClassName="active-mob"
                className="mobile-deactive"
                onClick={hideSidebar}
              >
                <li>About</li>
              </NavLink>
              <NavLink
                exact
                to="/faq"
                activeClassName="active-mob"
                className="mobile-deactive"
                onClick={hideSidebar}
              >
                <li>FAQ</li>
              </NavLink>
              <NavLink
                exact
                to="/contact"
                activeClassName="active-mob"
                className="mobile-deactive"
                onClick={hideSidebar}
              >
                <li>Contact</li>
              </NavLink>

              <li
                style={{
                  marginTop: "0.5rem",
                  marginLeft: "-1rem",
                  paddingLeft: "2.9rem",
                  paddingTop: 8,
                  paddingBottom: 8,
                  fontWeight: 600,
                }}
                onClick={() => loginWithRedirect()}
              >
                Log In
              </li>
              <li
                style={{
                  marginTop: "0.5rem",
                  marginLeft: "-1rem",
                  paddingLeft: "2.9rem",
                  paddingTop: 8,
                  paddingBottom: 8,
                  fontWeight: 600,
                }}
                onClick={() =>
                  loginWithRedirect({
                    screen_hint: "signup",
                  })
                }
              >
                Sign Up
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebarhome;

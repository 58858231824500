import { makeStyles } from "@material-ui/styles";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import OnBoarding4 from "components/OnBoarding/OnBoarding4";
import OnBoarding5 from "components/OnBoarding/OnBoarding5";
import { FetchUserProvider } from "hooks/useFetchUser";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "store";
import "./OnBoarding1.scss";
import OnBoarding2 from "./OnBoarding2";
import Onboarding3 from "./OnBoarding3";

const steps = ["", "", "", ""];
export default function Onboarding1() {
  const userState = useSelector((state: RootState) => state.user);
  const [activeStep, setActiveStep] = React.useState(userState.user.onBoardingIndex || 0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  function getStepContent(activeStep: number) {
    switch (activeStep) {
      case 0: //Profile
        return <OnBoarding2 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 1: //Card
        return <Onboarding3 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2: //Subscription
        return <OnBoarding4 activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 3: //Chekcout
        return <OnBoarding5 activeStep={activeStep} setActiveStep={setActiveStep} />;
      default:
        return <Redirect to="/dashboard" />;
    }
  }

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // }; thi is changed
  // eslint-disable-next-line no-undef
  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "" },
      "& .MuiStepIcon-completed": { color: "black" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "lightgray", opacity: 0.2 },
      "& .MuiSvgIcon-root circle": { color: "#081149" },
      "& svg.Mui-completed": { color: "#081149" },
    },
  }));

  const c = useStyles();

  return (
    <FetchUserProvider>
      <Box sx={{ width: "100%" }}>
        <Stepper className={c.root} activeStep={activeStep} connector={null}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              // labelProps.optional = (
              //    <Typography variant="caption">Optional</Typography>
              // );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label + index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>{getStepContent(activeStep)}</div>
        {activeStep === steps.length ? (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
              {/* <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button> */}
              <Box sx={{ flex: "1 1 auto" }} />
              {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
              {/* <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Complete' : 'Continue'}
            </Button> */}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </FetchUserProvider>
  );
}

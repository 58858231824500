import React from "react";
import { FormControlProps } from "react-bootstrap";
import "./appSwitch.scss";

interface IAppSwitchProps
  extends FormControlProps,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checked: boolean;
  size?: any;
  type?: any;
  value?: any;
  onChange?: any;
  label?: any;
  subLabel?: any;
}

const AppSwitch: React.FC<IAppSwitchProps> = (props: IAppSwitchProps) => {
  return (
    <div className="custom-control custom-switch mb-4">
      <input
        type="checkbox"
        className="custom-control-input"
        name={props.name}
        id="customSwitchesChecked"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label className="custom-control-label" htmlFor="customSwitchesChecked">
        {props.label}
      </label>
      <div className="sm-text-reg">{props.subLabel}</div>
    </div>
  );
};

export default AppSwitch;

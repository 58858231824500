import React, { ComponentType, ReactNode } from "react";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";

import styles from "./AdminDashboard.module.scss";

export interface DashboardCardProps {
  count: ReactNode;
  title: string;
  sub: ReactNode;
  icon?: ComponentType;
  plus?: boolean;
  color?: "red" | "blue" | "green" | "yellow";
}

const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  const { count, title, sub, icon: Icon, color = "blue", plus = true } = props;

  return (
    <div className={`${styles.dashboard_card} ${styles[color]}`}>
      <div className={styles.icon_container}>
        <div className={styles.icon}>{Icon && <Icon />}</div>
      </div>
      <span className={styles.title}>{title}</span>
      <div className={styles.count}> {count}</div>
      <div className={styles.sub}>
        {plus ? (
          <FaChevronCircleUp className={`${styles.plus} ${styles.up}`} />
        ) : (
          <FaChevronCircleDown className={`${styles.plus} ${styles.down}`} />
        )}
        {sub}
      </div>
    </div>
  );
};

export default DashboardCard;

import { useEffect, useRef } from "react";

import "./Vidplayer.scss";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const videoUrl =
  "https://pria-dev-526485.s3.us-west-1.amazonaws.com/illustration_iphoneMockup_1.mp4";

function Vidplayer() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />

    if (isSafari() && containerRef.current) {
      // obtain reference to the video element
      const player = containerRef.current.querySelector("video");

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = true;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch((err) => {
                // if promise fails, hide the video and fallback to <img> tag
                // videoParentRef.current.style.display = "none";
                // setShouldUseImage(true);
                console.log(err);
              });
          }
        }, 0);
      }
    }
  }, []);

  return (
    <div className="player" ref={containerRef}>
      <div
        className="video-container"
        dangerouslySetInnerHTML={{
          __html: `<video
              preload
              autoplay
              loop
              playsinline
              class="video_player"
              controls
              controlsList="nodownload"
              muted
            >
              <source src="${videoUrl}" />
            </video>`,
        }}
      >
        {/* {carousalMode ? (

          <ReactPlayer
            playing={true}
            loop={true}
            volume={1}
            muted={true}
            width="65vw"
            height="auto"
            url="https://pria-dev-526485.s3.us-west-1.amazonaws.com/illustration_iphoneMockup_1.mp4"
          />
        ) : (
          <ReactPlayer
            playing={true}
            loop={true}
            volume={1}
            muted={true}
            width="20vw"
            height="auto"
            url="https://pria-dev-526485.s3.us-west-1.amazonaws.com/illustration_iphoneMockup_1.mp4"
          />
        )} */}
      </div>
    </div>
  );
}

export default Vidplayer;

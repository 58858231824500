import { GenericFn } from "common";
import { ComponentType, ReactNode, useEffect, useMemo, useState } from "react";

import {
  Box,
  CSSObject,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import Spinners from "components/Spinners/Spinners";
import styles from "./dTable.module.scss";
import { useFilter } from "./FilterOptions";
import TableFilter, { FilterList } from "./TableFilter";
import TablePagination from "./TablePagination";

export interface THead<T> {
  accessor: keyof T;
  h: ReactNode;
  isSortable: boolean;
}
export type TData<T> = {
  [k in keyof T]: T[k];
};

interface DynamicTableProps<T> {
  th: THead<T>[];
  td: TData<T>[];
  title?: string;
  onSorting?: GenericFn;
  onPageChange?: GenericFn;
  onSizeChange?: GenericFn;
  onFilterChange?: GenericFn;
  onShowMoreClick?: GenericFn;
  onSearchChange?: GenericFn;
  totalPages?: number;
  totalResults?: number;
  hasPermission?: boolean;
  filterList?: FilterList;
  showFilter?: boolean;
  showPagination?: boolean;
  showSearch?: boolean;
  isLoading?: boolean;
  showMore?: string;
  showCheckbox?: boolean;
  onCheckedChange?: GenericFn;
  actions?: ComponentType<any>[];
  disableCheckboxCriteria?: GenericFn;
}

const cellStyles: CSSObject = {
  borderBottom: 0,
  padding: 1.2,
  verticalAlign: "baseline",
};

const useCheckbox = <T,>(
  td: TData<T>[],
  disableCheckboxCriteria?: GenericFn,
  onCheckboxChange?: GenericFn,
) => {
  const [selectedCheckboxIndexes, setSelectedCheckboxIndexes] = useState<(number | null)[]>([]);

  useEffect(() => {
    if (td) setSelectedCheckboxIndexes(new Array(td?.length).fill(null));
  }, [td, td?.length]);

  const checkboxSelectAll = (e: any) => {
    e.target.checked ? checkboxSelectAllData() : checkboxUnselectAllData();
  };

  const checkboxSelectAllData = () => {
    let allSelected = td?.map((res, index) => {
      if (disableCheckboxCriteria?.(res)) return null;
      return index;
    });
    setSelectedCheckboxIndexes(allSelected);
    onCheckboxChange?.(allSelected);
  };
  const checkboxUnselectAllData = () => {
    const allUnselected = td?.map(() => null);

    setSelectedCheckboxIndexes(allUnselected);
    onCheckboxChange?.(allUnselected);
  };

  const checkboxSingleChange = (e: any, index: number) => {
    e.target.checked ? checkboxSelect(index) : checkboxUnselect(index);
  };

  const checkboxSelect = (index: number) => {
    const selectedCbk = (s: typeof selectedCheckboxIndexes) => {
      const temp = [...s];
      temp[index] = index;
      return temp;
    };

    setSelectedCheckboxIndexes(selectedCbk);
    onCheckboxChange?.(selectedCbk(selectedCheckboxIndexes));
  };

  const checkboxUnselect = (index: number) => {
    const unselectedCbk = (s: typeof selectedCheckboxIndexes) => {
      const temp = [...s];
      temp[index] = null;
      return temp;
    };
    setSelectedCheckboxIndexes(unselectedCbk);
    onCheckboxChange?.(unselectedCbk(selectedCheckboxIndexes));
  };

  return {
    selectedCheckboxIndexes,
    setSelectedCheckboxIndexes,
    checkboxSelectAll,
    checkboxSingleChange,
    checkboxSelect,
    checkboxUnselect,
    rowCount: selectedCheckboxIndexes?.length,
    disabledRowCount: selectedCheckboxIndexes?.filter((x) => x === null)?.length,
  };
};

function DynamicTable<K extends Record<string, any>>(props: DynamicTableProps<K>) {
  const {
    td,
    th,
    title,
    totalPages,
    totalResults,
    hasPermission = true,
    filterList = [],
    onFilterChange,
    onShowMoreClick,
    onSearchChange,
    showFilter = true,
    showPagination = true,
    showSearch,
    isLoading,
    showMore,
    showCheckbox,
    onCheckedChange,
    actions,
    disableCheckboxCriteria,
  } = props;

  const { filterOptions, setSort } = useFilter();
  /**
   * Function to handle sorting
   * @param key
   */
  const handleSorting = (key: keyof K, sortable: boolean) => {
    if (sortable) {
      setSort(key as string);
    }
  };

  const {
    selectedCheckboxIndexes,
    checkboxSingleChange,
    checkboxSelectAll,
    disabledRowCount,
  } = useCheckbox<K>(td, disableCheckboxCriteria, (selected) => {
    onCheckedChange?.(selected.filter((x: number) => x !== null).map((x: number) => td[x]));
  });

  const tableHeaders = useMemo(() => {
    if (showCheckbox) {
      const isChecked =
        selectedCheckboxIndexes.filter((x) => x !== null).length ===
        (!disableCheckboxCriteria ? td?.length : disabledRowCount);

      const checkboxHeader = {
        accessor: "checkbox",
        h: <input type="checkbox" checked={isChecked} onChange={checkboxSelectAll} />,
        isSortable: false,
      };
      return [checkboxHeader, ...th];
    }

    return th;
  }, [
    checkboxSelectAll,
    disableCheckboxCriteria,
    disabledRowCount,
    selectedCheckboxIndexes,
    showCheckbox,
    td?.length,
    th,
  ]);

  /**
   * render table data
   * @returns
   */
  const renderTableData = () => {
    if (!td) return;

    let tableRows = [...td];

    if (showCheckbox) {
      tableRows = td.map((row, index) => {
        const checkbox = (
          <input
            type="checkbox"
            checked={Boolean(selectedCheckboxIndexes[index] !== null)}
            onChange={(e) => checkboxSingleChange(e, index)}
          />
        );

        return {
          ...row,
          ...(disableCheckboxCriteria?.(row) ? {} : { checkbox }),
        };
      });
    }

    if (tableRows?.length === 0) {
      return (
        <TableRow>
          <TableCell sx={cellStyles} colSpan={th.length}>
            No Results
          </TableCell>
        </TableRow>
      );
    }

    return tableRows?.map((row, rowIndex) => (
      <TableRow key={rowIndex}>
        {tableHeaders?.map(({ accessor: h }, index) => {
          return (
            <TableCell
              sx={cellStyles}
              key={`${rowIndex}_${index}`}
              style={{ borderLeft: "1px solid #08114966", borderRight: "1px solid #08114966" }}
            >
              {/* style for border in table body*/}
              {row[h] || "-"}
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  if (!hasPermission) return <></>;

  return (
    <Box mb={10} width="100%" maxWidth={"100vw"}>
      {showFilter && (
        <TableFilter
          showSearch={showSearch}
          title={title}
          filterList={filterList}
          onChange={onFilterChange}
          onSearchChange={onSearchChange}
          actions={actions}
          rows={td}
          selectedCheckboxIndexes={selectedCheckboxIndexes}
        />
      )}
      <Box className={styles.table_container}>
        <Table className={styles.table}>
          <TableHead sx={{ width: "100%" }} style={{ backgroundColor: "#081149" }}>
            {/* style for header Primary color in Table*/}
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell sx={cellStyles} component="th" key={header.accessor as string}>
                  <div className={styles.tableHeader}>
                    <Box onClick={() => handleSorting(header.accessor, header.isSortable)}>
                      {header.h}
                      {header.isSortable ? (
                        <TableSortLabel
                          active={
                            filterOptions.sort === header.accessor && Boolean(filterOptions.order)
                          }
                          direction={filterOptions.order}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              renderTableData()
            ) : (
              <TableRow>
                <TableCell colSpan={th.length}>
                  <Box position={"relative"} minHeight="200px">
                    <Spinners sm />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {showMore && (
          <Box sx={{ textAlign: "center", width: "100%", py: 2 }}>
            <Typography sx={{ cursor: "pointer", textAlign: "center" }} onClick={onShowMoreClick}>
              {showMore}
            </Typography>
          </Box>
        )}
      </Box>
      <Box width="100%" maxWidth={"100vw"} px={1}>
        {showPagination && <TablePagination totalResults={totalResults} totalPages={totalPages} />}
      </Box>
    </Box>
  );
}

export default DynamicTable;

import { ITransactionAction } from 'actions/transactions';
import {
  FETCH_TRANSACTIONS_ERROR,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_RESET,
  FETCH_TRANSACTIONS_SUCCESS,
} from 'types/ITransaction';

type TRANSACTION_STATE = {
  transactions: Array<ITransactionAction | null>;
  loading: boolean;
  error: string;
  transactionFetchSuccess: boolean;
  transactionFetchError: string;
};

const INITIAL_STATE: TRANSACTION_STATE = {
  transactions: [],
  loading: false,
  error: '',
  transactionFetchError: '',
  transactionFetchSuccess: false,
};

export default (state: TRANSACTION_STATE = INITIAL_STATE, action: ITransactionAction) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        transactionFetchSuccess: true,
      };
    }
    case FETCH_TRANSACTIONS_ERROR: {
      return {
        ...state,
        loading: false,
        transactionFetchError: action.payload,
        transactionFetchSuccess: false,
      };
    }
    case FETCH_TRANSACTIONS_RESET: {
      return {
        ...state,
        loading: false,
        transactionFetchError: '',
        transactionFetchSuccess: false,
      };
    }
    default:
      return state;
  }
};

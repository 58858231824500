import { IToggleAction } from "actions/cardToggle";
import { TOGGLE_OFF, TOGGLE_ON } from "types";

type TOGGLE_STATE = {
  isOn: boolean;
};

const INITIAL_STATE: TOGGLE_STATE = {
  isOn: false,
};

export default (state: TOGGLE_STATE = INITIAL_STATE, action: IToggleAction) => {
  switch (action.type) {
    case TOGGLE_ON:
      return {
        ...state,
        isOn: true,
      };
    case TOGGLE_OFF:
      return {
        ...state,
        isOn: false,
      };
    default:
      return state;
  }
};

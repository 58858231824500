import { useAuth0 } from "@auth0/auth0-react";
import { updateUser, updateUserResetAction } from "actions/users";
import AppButton from "common/AppButton";
import { UserIcon } from "common/AppIcons";
import AppInput from "common/AppInput";
import AppSelect from "common/AppSelect";
import { FormikProvider, useFormik } from "formik";
import useAdministrativeAreas from "hooks/useAdministrativeAreas";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmailAPI } from "services/users";
import { RootState } from "store";
import { handleError } from "utils/handleError";
import { handleSuccess } from "utils/handleSuccess";
import { usePostalCodeState } from "utils/zipCode";

import * as Yup from "yup";
import "./Account.scss";

const PersonalInfo: React.FC<any> = (reference0: any) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.user);

  const userUpdateSuccess = useSelector((state: RootState) => state.user.userUpdateSuccess);
  const userUpdateLoading = useSelector((state: RootState) => state.user.userUpdateLoading);
  const userUpdateError = useSelector((state: RootState) => state.user.error);

  const [states, stateMap] = useAdministrativeAreas();

  useEffect(() => {
    if (userUpdateSuccess) {
      handleSuccess("Updated successfully");

      dispatch(updateUserResetAction());
    }
  }, [userUpdateSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userUpdateError?.length > 0) {
      handleError(userUpdateError);
      dispatch(updateUserResetAction());
    }
  }, [userUpdateError]); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    // enableReinitialize: false,
    initialValues: {
      //name: `${userInfo.firstName} ${userInfo.middleName} ${userInfo.lastName}` || '',
      firstName: userInfo?.firstName || "",
      middleName: userInfo?.middleName || "",
      lastName: userInfo?.lastName || "",
      email: user?.email || "",
      phone: userInfo?.phone || "",
      address: {
        address1: userInfo?.address?.address1 || "",
        address2: userInfo?.address?.address2 || "",
        locality: userInfo?.address?.locality || "",
        administrativeArea: userInfo?.address?.administrativeArea || states?.[0]?.value,
        country: userInfo?.address?.country || "US",
        postalCode: userInfo?.address?.postalCode || "",
      },
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Invalid Phone Number. Valid number format is 2025550104")
        .required("Phone number is required"),
      address: Yup.object().shape({
        address1: Yup.string().nullable(true),
        address2: Yup.string().nullable(true),
        locality: Yup.string().nullable(true),
        administrativeArea: Yup.string(),
        country: Yup.string().nullable(true),
        postalCode: Yup.string()
          .matches(/^\d{5}([-]\d{4})?$/, "Invalid postal code")
          .required("Postal Code is required"),
      }),
    }),
    onSubmit: async (values: any) => {
      const token = await getAccessTokenSilently();
      let { address } = values;

      // let [firstName, middleName, lastName] = name?.split(' ');
      // if (!lastName) {
      //   lastName = middleName;
      //   middleName = null;
      // }
      // delete values['name'];
      delete values["email"];
      let requestValues = {
        ...values,
        address: { ...address },
      };
      user && user.sub && dispatch(updateUser(user?.sub, requestValues, token));
    },
  });

  const verifyEmail = async () => {
    const token = await getAccessTokenSilently();
    try {
      if (user && user?.sub && !user?.email_verified) {
        const response = await verifyEmailAPI(user?.sub, token);
        // console.log(response);
        handleSuccess();
      }
    } catch (error: any) {
      console.log(error?.message);
      handleError(error?.message);
    }
  };

  const {
    handleAdministrativeAreaChange: handleStateChange,
    handlePostalCodeChange,
  } = usePostalCodeState(formik);

  return (
    <div>
      <h5 className='className="form-row align-items-center mt-2 mb-2"'>
        <UserIcon ref={reference0} />
        Personal Information
      </h5>

      <FormikProvider value={formik}>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="2" htmlFor="fullnme">
              Full Name
            </Form.Label>
            <Col sm="4">
              <AppInput
                placeholder="First Name"
                name="firstName"
                value={formik.values?.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.firstName ? true : false}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <div className="invalid-feedback-text">{formik.errors.firstName}</div>
              )}
            </Col>
            <Col sm="3">
              <AppInput
                placeholder="Middle Name"
                name="middleName"
                value={formik.values?.middleName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Col>
            <Col sm="3">
              <AppInput
                placeholder="Last Name"
                name="lastName"
                value={formik.values?.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.lastName ? true : false}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <div className="invalid-feedback-text">{formik.errors.lastName}</div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" htmlFor="fullnme">
              Email
            </Form.Label>
            <Col sm="10">
              <AppInput
                placeholder="janedoe@gmail.com"
                name="email"
                value={formik.values?.email}
                disabled
                adornmentLabel={user?.email_verified ? `Email verified` : `Verify Email`}
                onButtonClick={verifyEmail}
                buttonClassName={user?.email_verified ? `success` : `primary`}
                //  style={{ borderRadius: '4px 0px 0px 4px' }}
              />
            </Col>
            {/* <Col sm="2">
              <AppButton
                className={user?.email_verified ? `success` : `primary`}
                onClick={verifyEmail}
              >
                {user?.email_verified ? `Email verified` : `Verify Email`}
              </AppButton>
            </Col> */}
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" htmlFor="phone">
              Phone
            </Form.Label>
            <Col sm="10">
              <AppInput
                type="text"
                name="phone"
                value={formik.values?.phone}
                maxLength={10}
                onChange={(e: any) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                adornmentText="+1"
                isInvalid={formik.errors.phone ? true : false}
              />
              {formik.errors.phone && formik.touched.phone && (
                <div className="invalid-feedback-text">{formik.errors.phone}</div>
              )}
            </Col>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  Street 1
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="text"
                    name="address.address1"
                    value={formik.values?.address?.address1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  Street 2
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="text"
                    name="address.address2"
                    value={formik.values?.address?.address2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  City
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="text"
                    name="address.locality"
                    value={formik.values?.address?.locality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  State
                </Form.Label>
                <Col sm="8">
                  <AppSelect
                    options={states}
                    size="sm"
                    style={{ WebkitAppearance: "menulist", maxWidth: "100%" }}
                    name="address.administrativeArea"
                    value={formik.values?.address?.administrativeArea}
                    onChange={handleStateChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  Country
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="text"
                    name="address.country"
                    value={formik.values.address?.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm="4" htmlFor="fullnme">
                  Zip Code
                </Form.Label>
                <Col sm="8">
                  <AppInput
                    type="text"
                    name="address.postalCode"
                    maxLength={5}
                    value={formik.values?.address?.postalCode}
                    autoComplete="off"
                    onChange={handlePostalCodeChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.errors.address?.postalCode ? true : false}
                  />
                  {formik.errors.address?.postalCode && formik.touched.address?.postalCode && (
                    <div className="invalid-feedback-text">{formik.errors.address?.postalCode}</div>
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <div className="action-box">
            <AppButton
              type="submit"
              onClick={formik.handleSubmit}
              className="primary"
              isLoading={userUpdateLoading}
              disabled={userUpdateLoading}
            >
              Save Changes
            </AppButton>
            &nbsp; &nbsp;
            <AppButton
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                formik.resetForm();
              }}
              className="primary outlined"
            >
              Cancel
            </AppButton>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PersonalInfo;
